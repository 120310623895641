//
// DISCLAIMER
//
// Copyright 2022-2024 ArangoDB GmbH, Cologne, Germany
//

import { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Grid, Menu, Loader, Button, Form, Modal, Divider, Header } from "semantic-ui-react";
import Auth from "../../auth/Auth";
import { IWithRefreshProps } from "../../util/WithRefresh";
import AuditLogList from "../auditlog/AuditLogList";
import ChangelogList from "../changelog/ChangelogList";
import EmailMessageList from "../email/EmailMessageList";
import EventList from "../event/EventList";
import ExampleInstallationList from "../exampleinstallation/ExampleInstallationList";
import JobList from "../job/JobList";
import LoadBalancerAssignmentList from "../network/LoadBalancerAssignmentList";
import PrivateEndpointServiceDetails from "../network/PrivateEndpointServiceDetails";
import UsageItemList from "../organization/UsageItemList";
import { ServersStatusView } from "../status/ServersStatusView";
import { ICreateBackupViewArgs, IEditBackupViewArgs, BackupsView } from "./BackupViews";
import { ExpirationView } from "./ExpirationView";
import { GeneralView } from "./GeneralView";
import { PrepaidView } from "./PrepaidView";
import { SpecialModesView } from "./SpecialModesView";
import { SupportView } from "./SupportView";
import apiClients from "../../api/apiclients";
import LoadBalancerList from "../network/LoadBalancerList";
import { Confirm, ConfirmInfo, ContentSegment, ErrorMessage, FieldSet, LoaderBox, MenuActionBack, Processing, SecondaryMenu } from "../../ui/lib";
import MLStatusView from "./notebook/MLStatusView";

import {
  KeyValuePair as ApiKeyValuePair,
  BackupInfoList as ApiBackupInfoList,
  BackupPolicyInfoList as ApiBackupPolicyInfoList,
  DataClusterAssignment as ApiDataClusterAssignment,
  Deployment as ApiDeployment,
  DeploymentInfo as ApiDeploymentInfo,
  Deployment_DiskAutoSizeSettings as ApiDiskAutoSizeSettings,
  Organization as ApiOrganization,
  Project as ApiProject,
  RegionDetails as ApiRegionDetails,
  NodeSizeDetails as ApiNodeSizeDetails,
  ListLoadBalancerAssignmentsRequest as ApiListLoadBalancerAssignmentsRequest,
  ListLoadBalancerTypesRequest as ApiListLoadBalancerTypesRequest,
  UpdateLoadBalancerTypeForDeploymentRequest as ApiUpdateLoadBalancerTypeForDeploymentRequest,
  ServerNodeAssignment as ApiServerNodeAssignment,
  DeploymentEmailAddresses as ApiDeploymentEmailAddresses,
  MemoryReserve as ApiMemoryReserve,
  UpdateDeploymentMemoryReservesRequest as ApiUpdateDeploymentMemoryReservesRequest,
  UpdateDeploymentTopologyAwarenessRequest as ApiUpdateDeploymentTopologyAwarenessRequest,
  Version as ApiVersion,
  VolumeInfo as ApiVolumeInfo,
  UpdateKubernetesResourcesPatchesRequest as ApiUpdateKubernetesResourcesPatchesRequest,
  JSONPatch,
  CopyBackupRequest as ApiCopyBackupRequest,
  Backup as ApiBackup,
} from "../../api/lib";
import DeploymentDetailsTabContent from "./DeploymentDetailsTabContent";
import TabLayoutWithRouter from "../../components/Tab/TabWithRouter";
import { ITabConfig } from "../../components/Tab/TabTypes";
import MetricsTokens from "./MetricsTokens";
import { Token as ApiToken } from "../../api/metrics/v1/metrics";
import { ICommentArgsForIntegration } from "../comments/CommentTypes";
import CommentsSectionInline from "../comments/CommentSectionInline";
import { AsyncResult } from "../../util/Types";
import CommentsPrompt from "../comments/CommentsPrompt";
import KubernetesResourceModal from "../monitoring/KubernetesResourceModal";
import MigrationDeploymentReplication from "./DeploymentReplication";
import DeploymentDangerZone from "./DeploymentDangerZone";
import { NotebookPermissions } from "./notebook/types";
import NotebookView from "./notebook/NotebookView";
import CoreDumpList from "../coredump/CoreDumpList";
import IConfigurationViewItemProps from "./ConfigurationView/IConigurationViewItemProps";
import { DeploymentNotificationsList } from "./notifications/DeploymentNotificationsList";
import HelmChartsTab from "./HelmChartsTab";

interface IRootPasswordRequestModalArgs {
  showRootPasswordRequestModal: boolean;
  rootPasswordRequestReason: string;
  onCloseRootPasswordRequestModal: () => void;
  onCopyPassword: () => void;
  onRootPasswordRequestReasonChange: (reason: string) => void;
}

interface IDeploymentDetailsViewArgs
  extends IWithRefreshProps,
    RouteComponentProps,
    IRootPasswordRequestModalArgs,
    ICreateBackupViewArgs,
    IEditBackupViewArgs,
    ICommentArgsForIntegration,
    NotebookPermissions,
    IConfigurationViewItemProps {
  deployment: ApiDeployment;
  deploymentInfo: ApiDeploymentInfo;
  nodeSizeDetails?: ApiNodeSizeDetails;
  diskAutoSizeSettings?: ApiDiskAutoSizeSettings;
  versions: ApiVersion[];
  finalizers: string[];
  regionDetails: ApiRegionDetails;
  assignment?: ApiDataClusterAssignment;
  annotations?: ApiKeyValuePair[];
  inHibernationMode: boolean;
  isHibernated: boolean;
  inMaintenanceMode: boolean;
  blockAllIncomingTraffic: boolean;
  agencyInMaintenanceMode: boolean;
  operatorInMaintenanceMode: boolean;
  isUpToDate: boolean;
  revision: number;
  revisionUpdatedAt?: Date;
  lastReportedRevision: number;
  lastReportedRevisionUpdatedAt?: Date;
  serverNodeAssignments?: ApiServerNodeAssignment[];
  allowed_endpoints: string[];
  chaosLevel: string;
  schedulingPoliciesEnabled: boolean;
  project: ApiProject;
  organization: ApiOrganization;
  copiedRootPassword: boolean;
  loadingRootPassword: boolean;
  can_copy_root_password: boolean;
  onCopyRootPassword: () => void;
  onExpirationAddWeeks: (weeks: number) => void;
  onExpirationOptOut: () => void;
  canOptOutExpiration: boolean;
  processingSetExpirationDate: boolean;
  processingUpdateHibernationMode: boolean;
  processingUpdateMaintenanceMode: boolean;
  processingUpdateAllowTraffic: boolean;
  processingAllowlistedEndpoints: boolean;
  processingUpdateVersion: boolean;
  processingUpdateSupportPlan: boolean;
  processingUpdateChaosLevel: boolean;
  processingUpdateKubeArangodbImage: boolean;
  processingUpdateNotebookImage: boolean;
  processingUpdateLoadBalancer: boolean;
  processingBackup: boolean;
  processingBackupPolicy: boolean;
  processingResumeDeployment: boolean;
  processingUpdateSuppressAlerts: boolean;
  processingUpdateUseShardRebalancer: boolean;
  errorMessage?: string;
  handleDismissError: () => void;
  onProjectSelected: () => void;
  onOrganizationSelected: () => void;
  can_edit_hibernation_mode: boolean;
  onClickUpdateHibernationMode: (newMode: boolean) => void;
  can_edit_maintenance_mode: boolean;
  onClickUpdateMaintenanceMode: (newMode: boolean) => void;
  can_edit_allow_traffic: boolean;
  onClickUpdateAllowTraffic: (newMode: boolean) => void;
  can_edit_agency_maintenance_mode: boolean;
  onClickUpdateAgencyMaintenanceMode: (newMode: boolean) => void;
  can_edit_operator_maintenance_mode: boolean;
  onClickUpdateOperatorMaintenanceMode: (newMode: boolean) => void;
  can_edit_dcupdate_mode: boolean;
  onClickDCUpdateMode: (newValue: string) => void;
  can_edit_allowlisted_endpoints: boolean;
  onSaveAllowlistedEndpoints: (endpoints: string[]) => Promise<AsyncResult<void>>;
  can_edit_version: boolean;
  onUpdateVersion: (newVersion: string) => void;
  backups?: ApiBackupInfoList;
  backupsInDataClusterBucket: boolean;
  backupsPage: number;
  backupsPageSize: number;
  onNextBackupPage: () => void;
  onPreviousBackupPage: () => void;
  lastGoodBackups?: ApiBackupInfoList;
  backupPolicies?: ApiBackupPolicyInfoList;
  can_view_backups: boolean;
  can_view_email_messages: boolean;
  can_view_example_installations: boolean;
  can_edit_custom_command_line_args: boolean;
  can_edit_support_plan: boolean;
  onSaveCustomCommandLineArguments: (agentArgs: string[], dbserverArgs: string[], coordinatorArgs: string[]) => Promise<AsyncResult<void>>;
  agents_custom_command_line_args?: string[];
  dbservers_custom_command_line_args?: string[];
  coordinators_custom_command_line_args?: string[];
  onDataClusterSelected: (id: string) => void;
  canUpdateProvisionHash: boolean;
  onClickUpdateProvisionHash: () => void;
  onClickUpdateSupportPlan: (newSupportPlanID: string, newSupportPlanName: string) => void;
  confirmSetSupportPlan?: ConfirmInfo;
  auth: Auth;
  can_rotate_server: boolean;
  onClickRotateServer: (serverID: string) => void;
  can_rotate_server_with_force: boolean;
  onClickRotateServerWithForce: (serverID: string) => void;
  isRotatingServer: (serverID: string) => boolean;
  can_redelete_deployment: boolean;
  onClickRedeleteDeployment: () => void;
  processingDeploymentRedelete: boolean;
  confirmRedeleteDeployment?: ConfirmInfo;
  onClickUpdateChaosLevel: (newLevel: string) => void;
  can_edit_chaos_level: boolean;
  confirmUpdateChaosLevel?: ConfirmInfo;
  customKubeArangodbImage?: string;
  customNotebookImage?: string;
  canEditArangodbImage: boolean;
  onEditArangodbImage: () => void;
  editArangodbImage: boolean;
  onCancelEditArangodbImage: () => void;
  onSaveEditArangodbImage: (newImage: string) => Promise<AsyncResult<void>>;
  processingUpdateArangodbImage: boolean;
  can_edit_kube_arangodb_image: boolean;
  can_edit_notebook_image: boolean;
  onClickEditKubeArangodbImage: () => void;
  onClickEditNotebookImage: () => void;
  editKubeArangodbImage: boolean;
  editNotebookImage: boolean;
  onChangeKubeArangodbImage: (newImage: string) => Promise<AsyncResult<void>>;
  onChangeNotebookImage: (newImage: string) => Promise<AsyncResult<void>>;
  onClickCancelEditKubeArangodbImage: () => void;
  onClickCancelEditNotebookImage: () => void;
  onClickSaveEditKubeArangodbImage: () => void;
  onClickSaveEditNotebookImage: () => void;
  useSharedLoadbalancer: boolean;
  useInternalLoadbalancer: boolean;
  onClickUpdateLoadBalancer: (useSharedLoadbalancer: boolean, useInternalLoadbalancer: boolean) => void;
  can_edit_loadbalancer: boolean;
  internal_loadbalancer_allowed: boolean;
  can_view_auditlog_archives: boolean;
  onAuditLogSelected: (id: string) => void;
  onAuditLogArchiveSelected: (id: string) => void;
  onDeploymentSelected: (id: string) => void;
  can_rotate_credentials: boolean;
  onRotateCredentials: () => void;
  processingRotateCredentials: boolean;
  confirmRotateCredentials?: ConfirmInfo;
  onUpdateCPURatio: (newCPURatio: string) => Promise<AsyncResult<void>>;
  processingUpdateCPURatio: boolean;
  cpuCoordinatorPercent: number;
  cpuDbServerPercent: number;
  can_edit_cpu_ratio: boolean;
  onUpdateMemoryRatio: (newMemoryRatio: string) => Promise<AsyncResult<void>>;
  processingUpdateMemoryRatio: boolean;
  memoryCoordinatorPercent: number;
  memoryDbServerPercent: number;
  can_edit_memory_ratio: boolean;
  processingUpdateReplication: boolean;
  agencyResourceFactor: number;
  can_edit_agency_resource_factor: boolean;
  onUpdateAgencyResourceFactor: (newFactor: string) => Promise<AsyncResult<void>>;
  communicationMethod: string;
  can_edit_communication_method: boolean;
  onUpdateCommunicationMethod: (newMethod: string) => void;
  processingUpdateFoxxAuthentication: boolean;
  can_edit_foxx_authentication: boolean;
  onUpdateFoxxAuthentication: (disable: boolean) => void;
  can_view_changelog: boolean;
  can_view_coredumps: boolean;
  deploymentEmails: ApiDeploymentEmailAddresses;
  agentsMemoryReserve?: ApiMemoryReserve;
  coordinatorsMemoryReserve?: ApiMemoryReserve;
  dbserversMemoryReserve?: ApiMemoryReserve;
  canEditMemoryReserves: boolean;
  onUpdateMemoryReserves: (req: ApiUpdateDeploymentMemoryReservesRequest) => any;
  onResumeDeployment: () => void;
  onClickCreateBackup: () => void;
  onClickBackupRestore: (id: string) => void;
  onClickBackupEdit: (id: string) => void;
  onClickBackupDownload: (id: string) => void;
  onClickBackupDelete: (id: string) => void;
  onClickCloneDeploymentFromBackup: (id: string) => void;
  confirmBackup?: ConfirmInfo;
  isTopologyAware: boolean;
  numberOfZonesOverride: number;
  isEvenlyDistributed: boolean;
  canUpdateTopologyAwareness: boolean;
  onUpdateTopologyAwareness: (req: ApiUpdateDeploymentTopologyAwarenessRequest) => any;
  canSetSchedulingPoliciesEnabled: boolean;
  onSetSchedulingPolciiesEnabled: (enable: boolean) => void;
  canSetAllowGPUWorkloads: boolean;
  canSetMLJobsResourceLimits: boolean;

  canEditVolumeInfos: boolean;
  onSaveVolumeInfos: (newAgentVolumeInfo?: ApiVolumeInfo, newDbserverVolumeInfo?: ApiVolumeInfo) => Promise<{ errorMessage: string }>;
  onEditAgentsVolumeInfos: () => void;
  onCancelEditAgentsVolumeInfos: () => void;
  editAgentsVolumeInfos: boolean;
  onEditDBServersVolumeInfos: () => void;
  onCancelEditDBServersVolumeInfos: () => void;
  editDBServersVolumeInfos: boolean;
  processingUpdateVolumeInfos: boolean;
  canEditDiskPerformanceLocked: boolean;
  processingDiskPerformanceLocked: boolean;
  onUpdateDiskPerformanceLocked: (locked: boolean, useRegularVolumeIinfo: boolean) => void;
  processingDiskPerformance: boolean;
  canEditDiskPerformance: boolean;
  editDiskPerformance: boolean;
  onEditDiskPerformance: () => void;
  onCancelEditDiskPerformance: () => void;
  onDiskPerformanceChange: (id: string) => void;
  onUpdateDiskSize: (newValue: number, ignoreMax: boolean) => Promise<AsyncResult<void>>;
  canEditDiskSize: boolean;
  canEditDiskSizeIgnoreMax: boolean;

  canRemoveFinalizer: boolean;
  onRemoveFinalizer: (finalizer: string) => void;
  canRemoveBackupFinalizer: boolean;
  onRemoveBackupFinalizer: (id: string, finalizer: string) => void;
  canRemoveBackupPolicyFinalizer: boolean;
  onRemoveBackupPolicyFinalizer: (id: string, finalizer: string) => void;
  processingRemoveFinalizer: boolean;
  canUpdateBackupPolicyAllowInconsistent: boolean;
  onUpdateBackupPolicyAllowInconsistent: (id: string, newValue: boolean) => void;
  canUpdateBackupPolicyUploadIncremental: boolean;
  onUpdateBackupPolicyUploadIncremental: (id: string, newValue: boolean) => void;

  canListMetricsTokens: boolean;
  metricsTokens?: ApiToken[];

  suppressAlerts: boolean;
  canEditSuppressedAlerts: boolean;
  onClickUpdateSuppressedAlerts: (newValue: boolean) => void;

  useShardRebalancer: boolean;
  canUpdateUseShardRebalacner: boolean;
  onUpdateDeploymentUseShardRebalancer: (newValue: boolean) => void;
  canUpdateKubernetesResourcesPatches: boolean;
  arangoDeploymentPatches?: JSONPatch[];
  cloudDeploymentPatches?: JSONPatch[];
  onUpdateKubernetesResourcesPatches: (data: ApiUpdateKubernetesResourcesPatchesRequest) => Promise<AsyncResult<void>>;

  canGetDeploymentReplications: boolean;
  canDeleteExistingDeployment: boolean;

  onCopyBackup: (data: ApiCopyBackupRequest) => Promise<ApiBackup>;
  canSetKubeArangodbAdditionalCommandLineArgs: boolean;
  onSaveKubeArangodbAdditionalCommandLineArguments: (kubeArangoArgs: string[]) => Promise<AsyncResult<void>>;
  onUpdateCPUFactors: (newFactor: string) => Promise<AsyncResult<void>>;
  onUpdateMemoryFactors: (newFactor: string) => Promise<AsyncResult<void>>;

  canUpdateMemoryFactors?: boolean;
  canUpdateCPUFactors?: boolean;

  saveSchedulingPolicies: (policies: string[]) => void;
  canSetSchedulingPolicies?: boolean;

  canListMLServices: boolean;
  canEnableMLService: boolean;

  disabledBackups: boolean;
  canSetPublicBackupsDisabled: boolean;
  onClickSetPublicBackupsDisabled: (enable: boolean) => void;

  usesLocalSSDs: boolean;

  readOnly: boolean;
  useJetStreamForAuditlogs: boolean;
  canEditJetStreamForAuditlogs: boolean;
  onUpdateDeploymentToUseJetStreamForAuditLogs: (newValue: boolean) => void;
  reloadDeploymentInfo: () => void;
}

const RootPasswordRequestModal = ({ ...args }: IRootPasswordRequestModalArgs) => (
  <Modal open={args.showRootPasswordRequestModal} onClose={args.onCloseRootPasswordRequestModal}>
    <Modal.Header>Copy deployment root password</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <p>
          <strong>Accessing the data of a deployment is only allowed with explicit consent of the customer!</strong>
        </p>
        <p>
          Explain why you need access to the deployment.
          <br />
          Refer to an issue number when possible.
        </p>
        <Form>
          <Form.Input
            autoFocus
            label="Reason"
            value={args.rootPasswordRequestReason}
            width="16"
            onChange={(e, d) => args.onRootPasswordRequestReasonChange(d.value)}
          />
        </Form>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button icon="cancel" content="Cancel" labelPosition="right" onClick={args.onCloseRootPasswordRequestModal} />
      <Button
        icon="exclamation"
        content="Copy password"
        labelPosition="right"
        onClick={args.onCopyPassword}
        color="orange"
        disabled={!args.rootPasswordRequestReason}
      />
    </Modal.Actions>
  </Modal>
);

const DeploymentDetailsTabView = ({ ...args }: IDeploymentDetailsViewArgs) => {
  const [loadBalancers, setLoadBalancers] = useState<Array<string>>([]);
  const [selectedLoadBalancer, setSelectedLoadBalancer] = useState<string>("");
  const [commentPrompt, setCommentPrompt] = useState<any>({
    showCommentsPrompt: false,
    defaultCommentOnEvent: "",
    onCommentConfirmation: async () => {},
    onCancellingCommentAddition: () => {},
    loadingMessage: "",
  });

  useEffect(() => {
    resetCommentPrompt();
  }, []);

  const resetCommentPrompt = () => {
    setCommentPrompt({
      showCommentsPrompt: false,
      defaultCommentOnEvent: "",
      onCommentConfirmation: async () => {},
      onCancellingCommentAddition: () => {},
      loadingMessage: "",
    });
  };

  const getTabConfig = (): ITabConfig[] => {
    const panes: ITabConfig[] = [
      {
        menuItem: { children: "Details", id: "detailsTab", route: "deploymentDetails" },
        content: () => (
          <DeploymentDetailsTabContent
            {...args}
            customKubeArangodbImage={args.customKubeArangodbImage || ""}
            customArangodbImage={args.deployment.custom_image || ""}
            customNotebookImage={args.deploymentInfo.custom_notebook_image || ""}
            deploymentEmails={args.deploymentEmails}
            organizationId={args.organization.id || ""}
            organization={args.organization}
            regionId={args.deployment.region_id || ""}
            onUpdateMemoryReserve={args.onUpdateMemoryReserves}
            disable_foxx_authentication={false}
            createComment={args.createComment}
            nodeSizeDetails={args.nodeSizeDetails || {}}
          />
        ),
      },
      {
        menuItem: { children: "Servers", id: "serversTab", route: "servers" },
        content: () => (
          <ServersStatusView
            {...args}
            reloadDeploymentInfo={args.reloadDeploymentInfo}
            deployment={args.deploymentInfo}
            depl_size={args.deployment.size}
            servers={args.deployment.status && args.deployment.status.servers}
            deploymentId={args.deployment.id || ""}
            is_paused={!!args.deployment.is_paused}
            serverNodeAssignments={args.serverNodeAssignments}
          />
        ),
      },
      {
        menuItem: { children: "Data Science", id: "dataScience", route: "dataScience" },
        content: () => (
          <>
            {args.canListNotebook && (
              <NotebookView
                deploymentID={args.deployment.id || ""}
                {...args}
                hideDeploymentID
                canUpdateNotebookMaintenanceMode={args.canUpdateNotebookMaintenanceMode}
                canUpdateNotebookImage={args.canUpdateNotebookImage}
              />
            )}
            {args.canListMLServices && (
              <MLStatusView canSetJobsResourceLimits={args.canSetMLJobsResourceLimits} deploymentID={args.deployment.id || ""} {...args} />
            )}
          </>
        ),
      },

      {
        menuItem: { children: "Load balancers", id: "lbAssignmentTab", route: "loadBalancers" },
        content: () => (
          <div>
            <Header sub>Load balancer assigments</Header>
            <LoadBalancerAssignmentList {...args} deployment={args.deployment} />
            <Header sub>Load balancers</Header>
            <LoadBalancerList {...args} deployment={args.deployment} />
          </div>
        ),
      },
      {
        menuItem: { children: "Private Endpoint", id: "privateEndpointTab", route: "privateEndpoint" },
        content: () => <PrivateEndpointServiceDetails {...args} deployment={args.deployment} />,
      },
      {
        menuItem: { children: "Helm Charts", id: "helmChartTab", route: "helmcharts" },
        content: () => <HelmChartsTab {...args} />,
      },
      {
        menuItem: { children: "Jobs", id: "jobsTab", route: "jobs" },
        content: () => <JobList {...args} deployment={args.deployment} showHeader={false} />,
      },
      {
        menuItem: { children: "Usage", id: "usageTab", route: "usage" },
        content: () => (
          <UsageItemList {...args} organization_id={args.organization.id} deployment_id={args.deployment.id} onInvoiceSelected={(id: string) => {}} />
        ),
      },
      {
        menuItem: { children: "Events", id: "eventsTab", route: "events" },
        content: () => <EventList {...args} subject_id={args.deployment.id} showHeader={false} />,
      },
    ];

    if (args.canGetDeploymentReplications) {
      panes.push({
        menuItem: { children: "Deployment Replication", id: "replicationTab", route: "deploymentReplication" },
        content: () => <MigrationDeploymentReplication id={args.deployment.id || ""} />,
      });
    }
    if (args.canListMetricsTokens) {
      panes.push({
        menuItem: { children: "Metrics Token", id: "metricsTokenTab", route: "metricsToken" },
        content: () => <MetricsTokens tokens={args.metricsTokens} />,
      });
    }
    if (args.can_view_backups) {
      panes.push({
        menuItem: { children: "Backups", id: "backupsTab", route: "backups" },
        content: () => (
          <BackupsView
            {...args}
            page={args.backupsPage}
            pageSize={args.backupsPageSize}
            onNextPage={args.onNextBackupPage}
            onPreviousPage={args.onPreviousBackupPage}
          />
        ),
      });
    }
    if (args.can_view_email_messages) {
      panes.push({
        menuItem: { children: "Emails", id: "emailsTab", route: "emails" },
        content: () => <EmailMessageList {...args} deployment_id={args.deployment.id} />,
      });
    }
    if (args.can_view_example_installations) {
      panes.push({
        menuItem: { children: "Example installations", id: "exampleTabs", route: "examples" },
        content: () => <ExampleInstallationList {...args} deployment_id={args.deployment.id || ""} />,
      });
    }
    if (args.can_view_auditlog_archives) {
      panes.push({
        menuItem: { children: "AuditLog", id: "auditLogTab", route: "auditLog" },
        content: () => <AuditLogList {...args} deployment_id={args.deployment.id} project_id={args.deployment.project_id} />,
      });
    }
    if (args.can_view_changelog) {
      panes.push({
        menuItem: { children: "History", id: "historyTab", route: "history" },
        content: () => <ChangelogList {...args} url={args.deployment.url || ""} />,
      });
    }
    if (args.can_view_coredumps) {
      panes.push({
        menuItem: { children: "Core dumps", id: "coreDumpsTab", route: "coreDumps" },
        content: () => <CoreDumpList {...args} deployment_id={args.deployment.id} showHeader={false} />,
      });
    }
    if (args.canDeleteExistingDeployment) {
      panes.push({
        menuItem: { children: "Danger zone", id: "dangerZoneTab", route: "dangerZone" },
        content: () => (
          <DeploymentDangerZone
            canDeleteExistingDeployment={args.canDeleteExistingDeployment}
            id={args.deployment.id || ""}
            name={args.deployment.name || ""}
            deletedAt={args.deployment.deleted_at}
          />
        ),
      });
    }

    panes.push({
      menuItem: { children: "Notifications", id: "notificationsTab", route: "notifications" },
      content: () => <DeploymentNotificationsList reloadDeploymentInfo={args.reloadDeploymentInfo} deployment={args.deployment} />,
    });

    return panes;
  };

  useEffect(() => {
    getDefaultLoadBalancer();
  }, []);

  const getDefaultLoadBalancer = async () => {
    const req: ApiListLoadBalancerAssignmentsRequest = {
      deployment_id: args.deployment.id,
    };

    const response = await apiClients.idashboardClient.ListLoadBalancerAssignments(req);
    const { items = [] } = response;
    const defaultLoadBalancer = (items.filter((item) => item.is_default)[0] || {}).load_balancer;
    const { type = "-" } = defaultLoadBalancer || {};
    setSelectedLoadBalancer(type);
  };

  const requestLoadBalancerTypes = async () => {
    const req: ApiListLoadBalancerTypesRequest = {
      deployment_id: args.deployment.id,
    };

    const response = await apiClients.idashboardClient.ListLoadBalancerTypes(req);
    const { type = [] } = response;
    setLoadBalancers(type);
  };

  const updateLoadBalancer = async (type: string) => {
    setCommentPrompt({
      showCommentsPrompt: true,
      defaultCommentOnEvent: `Update load balancer to ${type}`,
      onCancellingCommentAddition: resetCommentPrompt,
      loadingMessage: "Updating load balancer, please wait..",
      onCommentConfirmation: async () => {
        const req: ApiUpdateLoadBalancerTypeForDeploymentRequest = {
          deployment_id: args.deployment.id,
          type,
        };
        const previousType = selectedLoadBalancer;
        try {
          await apiClients.idashboardClient.UpdateLoadBalancerTypeForDeployment(req);
          setSelectedLoadBalancer(type);
        } catch (e) {
          setSelectedLoadBalancer(previousType);
        }
      },
    });
  };

  return (
    <ContentSegment>
      <Confirm confirmInfo={args.confirmSetSupportPlan} />
      <Confirm confirmInfo={args.confirmRedeleteDeployment} />
      <Confirm confirmInfo={args.confirmUpdateChaosLevel} />
      <Confirm confirmInfo={args.confirmRotateCredentials} />
      <Confirm confirmInfo={args.confirmBackup} />
      {!!commentPrompt.showCommentsPrompt && (
        <CommentsPrompt commentPrompt={{ ...commentPrompt, handleAddComment: args.createComment, onClose: resetCommentPrompt }} />
      )}
      <SecondaryMenu>
        <MenuActionBack />
        <Menu.Item header>Deployment Details</Menu.Item>
        <LoaderBox>
          <Loader size="mini" active={args.loading} inline />
        </LoaderBox>
      </SecondaryMenu>
      <Processing active={args.processingUpdateSupportPlan} message="Changing support plan, please wait..." />{" "}
      <Processing active={args.processingUpdateReplication} message="Changing replication settings, please wait..." />
      <Processing active={args.processingRotateCredentials} message="Rotating deployment credentials, please wait..." />
      <Processing active={args.processingBackup} message="Processing backup, please wait..." />
      <Processing active={args.processingBackupPolicy} message="Updating backup policy, please wait..." />
      <Processing active={args.processingResumeDeployment} message="Processing resume deployment, please wait..." />
      <Processing active={args.processingDeploymentRedelete} message="Deleting deployment (again), please wait..." />
      <Processing active={args.processingRemoveFinalizer} message="Removing finalizer, please wait..." />
      <Processing active={args.processingUpdateSuppressAlerts} message="Updating suppress alert setting, please wait..." />
      <Processing active={args.processingUpdateUseShardRebalancer} message="Updating shard rebalancer config, please wait..." />
      <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
      <RootPasswordRequestModal {...args} />
      <FieldSet>
        <Grid columns={3} doubling>
          <Grid.Column>
            <GeneralView
              {...args}
              createdWith={args.deploymentInfo.created_with || "?"}
              loadBalancers={loadBalancers}
              updateLoadBalancer={updateLoadBalancer}
              selectedLoadBalancer={selectedLoadBalancer}
              requestLoadBalancerTypes={requestLoadBalancerTypes}
              getDefaultLoadBalancer={getDefaultLoadBalancer}
            />
            <Divider hidden />
            {args.deployment.id && <KubernetesResourceModal {...args} auth={args.auth} deploymentId={args.deployment.id}></KubernetesResourceModal>}
            <Divider hidden />
            <SupportView {...args} />
          </Grid.Column>
          <Grid.Column>
            <SpecialModesView {...args} />
            <br />
            <PrepaidView {...args} organizationID={args.organization.id || ""} />
            <Divider hidden />
            <ExpirationView {...args} expiration={args.deployment.expiration || {}} />
          </Grid.Column>
          <Grid.Column>
            <CommentsSectionInline
              onCommentsPageChange={args.onCommentsPageChange}
              canCreate={args.canCreateComment}
              canViewComments={args.canViewComments}
              canCreateComment={args.canCreateComment}
              createComment={args.createComment}
              commentList={args.commentList}
              latestComments={args.latestComments}
              commentsLoading={args.commentsLoading}
              commentCreationInProcess={args.commentCreationInProcess}
              lastestCommentsTriggered={args.lastestCommentsTriggered}
            />
          </Grid.Column>
        </Grid>
      </FieldSet>
      <TabLayoutWithRouter tabConfig={getTabConfig()} allowOverflow id="deploymentsTabs" />
    </ContentSegment>
  );
};

export default DeploymentDetailsTabView;
