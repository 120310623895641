// DISCLAIMER
// Copyright 2025 ArangoDB GmbH, Cologne, Germany

import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Form, Grid } from "semantic-ui-react";
import { EditIcon, Section, SectionContent } from "../../ui/lib";
import { FieldSet } from "../../ui/_style";
import { Chart, ChartInstallation } from "../../api/charts/v1/icharts";
import apiClients from "../../api/apiclients";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";

const { ListAllCharts, CreateChartInstallation, DeleteChartInstallation, UpdateChartInstallation, ListChartInstallations } = apiClients.idashboardClient;

type ChartOrInstallation = Chart & Partial<ChartInstallation>;
type ValuesModalProps = {
  chartOrInstallation: ChartInstallation;
  open: boolean;
  onUpdateValues: (installation: ChartInstallation) => Promise<void>;
  onCloseValuesEditor: () => void;
};

const decodeValues = (values: string): string => {
  if (!values.trim()) return "";
  try {
    const decodedBase64 = atob(values);
    const parsedJson = JSON.parse(decodedBase64);
    return JSON.stringify(parsedJson, null, 4);
  } catch (e) {
    console.error("Failed to decode values:", e);
    return values;
  }
};

const encodeValues = (values: string): string => {
  try {
    return btoa(JSON.stringify(JSON.parse(values)));
  } catch (e) {
    console.error("Failed to encode values:", e);
    return "";
  }
};

const RowView = ({
  chartOrInstallation,
  onInstall,
  onUninstall,
  openValuesEditor,
}: {
  chartOrInstallation: ChartOrInstallation;
  onInstall: (chart: Chart) => Promise<void>;
  onUninstall: (installation: ChartInstallation) => Promise<void>;
  openValuesEditor: (installation: ChartInstallation) => void;
}) => {
  const { status, deployment_id, values, name, version } = chartOrInstallation;
  const statusString = status && deployment_id ? status.Status || "Install prep" : "";
  const isInstalled = !!deployment_id;
  const decodedValues = values ? decodeValues(values) : "";

  return (
    <Table.Row>
      <Table.Cell>{name}</Table.Cell>
      <Table.Cell>{version}</Table.Cell>
      <Table.Cell>
        <Button size="mini" basic onClick={isInstalled ? undefined : () => onInstall(chartOrInstallation)} disabled={isInstalled}>
          Install
        </Button>
        <Button size="mini" basic onClick={isInstalled ? () => onUninstall(chartOrInstallation) : undefined} disabled={!isInstalled}>
          Uninstall
        </Button>
      </Table.Cell>
      <Table.Cell>{statusString}</Table.Cell>
      <Table.Cell>
        {deployment_id && (
          <>
            {decodedValues.substring(0, 30)}
            <EditIcon icon="pencil" onClick={() => openValuesEditor(chartOrInstallation)} />
          </>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

const ValuesModal = ({ onUpdateValues, onCloseValuesEditor, chartOrInstallation, open }: ValuesModalProps) => {
  const { deployment_id, name, values: currentValues } = chartOrInstallation;
  const [values, setValues] = useState<string>(decodeValues(deployment_id && currentValues ? currentValues : ""));

  useEffect(() => {
    const initial = deployment_id && currentValues ? currentValues : "";
    setValues(decodeValues(initial));
  }, [deployment_id]);

  const isValuesValid = values ? encodeValues(values) !== "" : false;

  const handleSubmit = async () => {
    const valueToEncode = !values || values === "" ? "{}" : values;
    await onUpdateValues({ ...chartOrInstallation, values: encodeValues(valueToEncode) });
    onCloseValuesEditor();
  };

  return (
    <Modal open={open} onClose={onCloseValuesEditor}>
      <Modal.Header>{name} Values</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <Form>
            <Grid columns="1" stackable>
              <Grid.Column>
                <Section>
                  <SectionContent>
                    <Form.Field error={!isValuesValid}>
                      <Form.TextArea label="Values" required value={values} onChange={(_, { value }) => setValues(`${value || ""}`)} rows="16" />
                    </Form.Field>
                  </SectionContent>
                </Section>
              </Grid.Column>
            </Grid>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCloseValuesEditor}>Close</Button>
        <Button primary icon="check" content="Submit" onClick={handleSubmit} disabled={!isValuesValid} />
      </Modal.Actions>
    </Modal>
  );
};

const HelmChartsTab = (_: IWithRefreshProps) => {
  const { deployment } = useDeploymentStore();
  const [charts, setCharts] = useState<Chart[]>([]);
  const [installations, setInstallations] = useState<ChartInstallation[]>([]);
  const [chartOrInstallationForEdit, setChartOrInstallationForEdit] = useState<ChartInstallation>();
  const [error, setError] = useState<any>();

  const chartsOrInstallations: ChartOrInstallation[] = charts.map((chart) =>
    installations.find((i) => i.name === chart.name && i.version === chart.version)
      ? { ...chart, ...installations.find((i) => i.name === chart.name && i.version === chart.version) }
      : chart
  );

  const handleInstall = async (chart: Chart) => {
    try {
      await CreateChartInstallation({ ...chart, deployment_id: deployment.id });
      await refreshInstallations();
    } catch (error) {
      setError(error);
    }
  };

  const handleUninstall = async (installation: ChartInstallation) => {
    try {
      await DeleteChartInstallation(installation);
      await refreshInstallations();
    } catch (error) {
      setError(error);
    }
  };

  const handleUpdateInstallationValues = async (installation: ChartInstallation) => {
    try {
      await UpdateChartInstallation(installation);
      setChartOrInstallationForEdit(undefined);
      await refreshInstallations();
    } catch (error) {
      setError(error);
    }
  };

  const handleOpenValueEditor = (installation: ChartInstallation) => {
    setChartOrInstallationForEdit(installation);
  };

  const handleCancelValueEditor = () => {
    setChartOrInstallationForEdit(undefined);
  };

  const refreshInstallations = async () => {
    try {
      const installationsList = await ListChartInstallations({
        deployment_id: deployment.id,
      });
      setInstallations(installationsList.items || []);
    } catch (error) {
      setError(error);
    }
  };

  const refreshCharts = async () => {
    try {
      const chartsList = await ListAllCharts();
      setCharts(chartsList.charts || []);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    refreshCharts();
    refreshInstallations();
  }, []);

  return (
    <>
      {chartOrInstallationForEdit && (
        <ValuesModal
          open={!!chartOrInstallationForEdit}
          chartOrInstallation={chartOrInstallationForEdit}
          onUpdateValues={handleUpdateInstallationValues}
          onCloseValuesEditor={handleCancelValueEditor}
        />
      )}
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Chart</Table.HeaderCell>
            <Table.HeaderCell>Version</Table.HeaderCell>
            <Table.HeaderCell>Installation Intent</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Values</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {chartsOrInstallations.map((chartOrInstallation) => (
            <RowView
              key={chartOrInstallation.name}
              chartOrInstallation={chartOrInstallation}
              onInstall={handleInstall}
              onUninstall={handleUninstall}
              openValuesEditor={handleOpenValueEditor}
            />
          ))}
        </Table.Body>
      </Table>

      <FieldSet>
        <pre>{error ? JSON.stringify(error, null, 2) : ""}</pre>
      </FieldSet>
    </>
  );
};

export default withRefresh()(HelmChartsTab);
