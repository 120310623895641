//
// This file is AUTO-GENERATED by protoc-gen-ts.
// Do not modify it manually.
///
import api, { IStreamMessage, IServerStream } from '../../api'
import * as googleTypes from '../../googleTypes'
import { AuditLog as arangodb_cloud_audit_v1_AuditLog } from '../../audit/v1/audit'
import { AuditLogArchive as arangodb_cloud_audit_v1_AuditLogArchive } from '../../audit/v1/audit'
import { AuditLogEventList as arangodb_cloud_audit_v1_AuditLogEventList } from '../../audit/v1/audit'
import { AuditLogList as arangodb_cloud_audit_v1_AuditLogList } from '../../audit/v1/audit'
import { GetAuditLogEventsRequest as arangodb_cloud_audit_v1_GetAuditLogEventsRequest } from '../../audit/v1/audit'
import { ListAuditLogsRequest as arangodb_cloud_audit_v1_ListAuditLogsRequest } from '../../audit/v1/audit'
import { Backup as arangodb_cloud_backup_v1_Backup } from '../../backup/v1/backup'
import { CopyBackupRequest as arangodb_cloud_backup_v1_CopyBackupRequest } from '../../backup/v1/backup'
import { ListBackupPoliciesRequest as arangodb_cloud_backup_v1_ListBackupPoliciesRequest } from '../../backup/v1/backup'
import { Address as arangodb_cloud_billing_v1_Address } from '../../billing/v1/billing'
import { BillingConfig as arangodb_cloud_billing_v1_BillingConfig } from '../../billing/v1/billing'
import { GetPreliminaryInvoiceRequest as arangodb_cloud_billing_v1_GetPreliminaryInvoiceRequest } from '../../billing/v1/billing'
import { Invoice as arangodb_cloud_billing_v1_Invoice } from '../../billing/v1/billing'
import { InvoiceList as arangodb_cloud_billing_v1_InvoiceList } from '../../billing/v1/billing'
import { ListPaymentMethodsRequest as arangodb_cloud_billing_v1_ListPaymentMethodsRequest } from '../../billing/v1/billing'
import { PaymentMethodList as arangodb_cloud_billing_v1_PaymentMethodList } from '../../billing/v1/billing'
import { Empty as arangodb_cloud_common_v1_Empty } from '../../common/v1/common'
import { IDOptions as arangodb_cloud_common_v1_IDOptions } from '../../common/v1/common'
import { ListOptions as arangodb_cloud_common_v1_ListOptions } from '../../common/v1/common'
import { URLOptions as arangodb_cloud_common_v1_URLOptions } from '../../common/v1/common'
import { Version as arangodb_cloud_common_v1_Version } from '../../common/v1/common'
import { YesOrNo as arangodb_cloud_common_v1_YesOrNo } from '../../common/v1/common'
import { CreditBundle as arangodb_cloud_credits_v1_CreditBundle } from '../../credits/v1/credits'
import { CACertificate as arangodb_cloud_crypto_v1_CACertificate } from '../../crypto/v1/crypto'
import { Currency as arangodb_cloud_currency_v1_Currency } from '../../currency/v1/currency'
import { CurrencyList as arangodb_cloud_currency_v1_CurrencyList } from '../../currency/v1/currency'
import { GetDefaultCurrencyRequest as arangodb_cloud_currency_v1_GetDefaultCurrencyRequest } from '../../currency/v1/currency'
import { DataVolumeInfo as arangodb_cloud_data_v1_DataVolumeInfo } from '../../data/v1/data'
import { Deployment as arangodb_cloud_data_v1_Deployment } from '../../data/v1/data'
import { Deployment_Status as arangodb_cloud_data_v1_Deployment_Status } from '../../data/v1/data'
import { DeploymentCredentials as arangodb_cloud_data_v1_DeploymentCredentials } from '../../data/v1/data'
import { DeploymentCredentialsRequest as arangodb_cloud_data_v1_DeploymentCredentialsRequest } from '../../data/v1/data'
import { DeploymentModelList as arangodb_cloud_data_v1_DeploymentModelList } from '../../data/v1/data'
import { DiskPerformanceList as arangodb_cloud_data_v1_DiskPerformanceList } from '../../data/v1/data'
import { NodeSizeList as arangodb_cloud_data_v1_NodeSizeList } from '../../data/v1/data'
import { NodeSizesRequest as arangodb_cloud_data_v1_NodeSizesRequest } from '../../data/v1/data'
import { RebalanceDeploymentShardsRequest as arangodb_cloud_data_v1_RebalanceDeploymentShardsRequest } from '../../data/v1/data'
import { RotateDeploymentServerRequest as arangodb_cloud_data_v1_RotateDeploymentServerRequest } from '../../data/v1/data'
import { VersionList as arangodb_cloud_data_v1_VersionList } from '../../data/v1/data'
import { ExampleDatasetInstallationList as arangodb_cloud_example_v1_ExampleDatasetInstallationList } from '../../example/v1/example'
import { ExampleDatasetList as arangodb_cloud_example_v1_ExampleDatasetList } from '../../example/v1/example'
import { ListExampleDatasetInstallationsRequest as arangodb_cloud_example_v1_ListExampleDatasetInstallationsRequest } from '../../example/v1/example'
import { ListExampleDatasetsRequest as arangodb_cloud_example_v1_ListExampleDatasetsRequest } from '../../example/v1/example'
import { GetMultipleEffectivePermissionsRequest as arangodb_cloud_iam_v1_GetMultipleEffectivePermissionsRequest } from '../../iam/v1/iam'
import { MultiplePermissionLists as arangodb_cloud_iam_v1_MultiplePermissionLists } from '../../iam/v1/iam'
import { PermissionList as arangodb_cloud_iam_v1_PermissionList } from '../../iam/v1/iam'
import { User as arangodb_cloud_iam_v1_User } from '../../iam/v1/iam'
import { APICompatibility as arangodb_cloud_internal_apicompat_v1_APICompatibility } from '../../api-compat/v1/api_compat'
import { AllServiceDescriptors as arangodb_cloud_internal_apicompat_v1_AllServiceDescriptors } from '../../api-compat/v1/api_compat'
import { AllServiceDescriptorsRequest as arangodb_cloud_internal_apicompat_v1_AllServiceDescriptorsRequest } from '../../api-compat/v1/api_compat'
import { CompatibilityRequest as arangodb_cloud_internal_apicompat_v1_CompatibilityRequest } from '../../api-compat/v1/api_compat'
import { AuditLogArchiveChunk as arangodb_cloud_internal_audit_v1_AuditLogArchiveChunk } from '../../audit/v1/iaudit'
import { AuditLogArchiveChunkList as arangodb_cloud_internal_audit_v1_AuditLogArchiveChunkList } from '../../audit/v1/iaudit'
import { AuditLogArchiveInfo as arangodb_cloud_internal_audit_v1_AuditLogArchiveInfo } from '../../audit/v1/iaudit'
import { AuditLogArchiveInfoList as arangodb_cloud_internal_audit_v1_AuditLogArchiveInfoList } from '../../audit/v1/iaudit'
import { ListAuditLogArchiveChunksRequest as arangodb_cloud_internal_audit_v1_ListAuditLogArchiveChunksRequest } from '../../audit/v1/iaudit'
import { ListAuditLogArchiveInfosRequest as arangodb_cloud_internal_audit_v1_ListAuditLogArchiveInfosRequest } from '../../audit/v1/iaudit'
import { BackupInfo as arangodb_cloud_internal_backup_v1_BackupInfo } from '../../backup/v1/ibackup'
import { BackupInfoList as arangodb_cloud_internal_backup_v1_BackupInfoList } from '../../backup/v1/ibackup'
import { BackupPolicyInfoList as arangodb_cloud_internal_backup_v1_BackupPolicyInfoList } from '../../backup/v1/ibackup'
import { ListBackupInfosRequest as arangodb_cloud_internal_backup_v1_ListBackupInfosRequest } from '../../backup/v1/ibackup'
import { UpdateBackupPolicyAllowInconsistentRequest as arangodb_cloud_internal_backup_v1_UpdateBackupPolicyAllowInconsistentRequest } from '../../backup/v1/ibackup'
import { UpdateBackupPolicyUploadIncrementalRequest as arangodb_cloud_internal_backup_v1_UpdateBackupPolicyUploadIncrementalRequest } from '../../backup/v1/ibackup'
import { CancelInvoiceRequest as arangodb_cloud_internal_billing_v1_CancelInvoiceRequest } from '../../billing/v1/ibilling'
import { CompleteInvoiceRequest as arangodb_cloud_internal_billing_v1_CompleteInvoiceRequest } from '../../billing/v1/ibilling'
import { Credit as arangodb_cloud_internal_billing_v1_Credit } from '../../billing/v1/ibilling'
import { CreditList as arangodb_cloud_internal_billing_v1_CreditList } from '../../billing/v1/ibilling'
import { CreditUsageList as arangodb_cloud_internal_billing_v1_CreditUsageList } from '../../billing/v1/ibilling'
import { DiscardUncommitedCreditUsageRequest as arangodb_cloud_internal_billing_v1_DiscardUncommitedCreditUsageRequest } from '../../billing/v1/ibilling'
import { InvoiceInfo as arangodb_cloud_internal_billing_v1_InvoiceInfo } from '../../billing/v1/ibilling'
import { InvoiceInfoList as arangodb_cloud_internal_billing_v1_InvoiceInfoList } from '../../billing/v1/ibilling'
import { InvoiceStatistics as arangodb_cloud_internal_billing_v1_InvoiceStatistics } from '../../billing/v1/ibilling'
import { ListAllInvoicesRequest as arangodb_cloud_internal_billing_v1_ListAllInvoicesRequest } from '../../billing/v1/ibilling'
import { ListCreditRequest as arangodb_cloud_internal_billing_v1_ListCreditRequest } from '../../billing/v1/ibilling'
import { ListCreditUsageRequest as arangodb_cloud_internal_billing_v1_ListCreditUsageRequest } from '../../billing/v1/ibilling'
import { SetCreditExhaustedRequest as arangodb_cloud_internal_billing_v1_SetCreditExhaustedRequest } from '../../billing/v1/ibilling'
import { SetInvoiceNonCollectibleRequest as arangodb_cloud_internal_billing_v1_SetInvoiceNonCollectibleRequest } from '../../billing/v1/ibilling'
import { SetOrganizationExcludedFromBillingRequest as arangodb_cloud_internal_billing_v1_SetOrganizationExcludedFromBillingRequest } from '../../billing/v1/ibilling'
import { SetOrganizationUsesPurchaseOrdersRequest as arangodb_cloud_internal_billing_v1_SetOrganizationUsesPurchaseOrdersRequest } from '../../billing/v1/ibilling'
import { ChangeList as arangodb_cloud_internal_changelog_v1_ChangeList } from '../../changelog/v1/ichangelog'
import { ListChangesRequest as arangodb_cloud_internal_changelog_v1_ListChangesRequest } from '../../changelog/v1/ichangelog'
import { ChartInstallation as arangodb_cloud_internal_charts_v1_ChartInstallation } from '../../charts/v1/icharts'
import { ChartInstallationList as arangodb_cloud_internal_charts_v1_ChartInstallationList } from '../../charts/v1/icharts'
import { ChartList as arangodb_cloud_internal_charts_v1_ChartList } from '../../charts/v1/icharts'
import { ListChartInstallationsRequest as arangodb_cloud_internal_charts_v1_ListChartInstallationsRequest } from '../../charts/v1/icharts'
import { Comment as arangodb_cloud_internal_comment_v1_Comment } from '../../comment/v1/icomment'
import { CommentList as arangodb_cloud_internal_comment_v1_CommentList } from '../../comment/v1/icomment'
import { CreateCommentRequest as arangodb_cloud_internal_comment_v1_CreateCommentRequest } from '../../comment/v1/icomment'
import { ListCommentsRequest as arangodb_cloud_internal_comment_v1_ListCommentsRequest } from '../../comment/v1/icomment'
import { FinalizerRequest as arangodb_cloud_internal_common_v1_FinalizerRequest } from '../../common/v1/icommon'
import { Chunk as arangodb_cloud_internal_coredump_v1_Chunk } from '../../core-dump/v1/icoredump'
import { CoreDump as arangodb_cloud_internal_coredump_v1_CoreDump } from '../../core-dump/v1/icoredump'
import { CoreDumpList as arangodb_cloud_internal_coredump_v1_CoreDumpList } from '../../core-dump/v1/icoredump'
import { ListCoreDumpsRequest as arangodb_cloud_internal_coredump_v1_ListCoreDumpsRequest } from '../../core-dump/v1/icoredump'
import { CreditBundleInfo as arangodb_cloud_internal_credits_v1_CreditBundleInfo } from '../../credits/v1/icredits'
import { CreditBundleInfoList as arangodb_cloud_internal_credits_v1_CreditBundleInfoList } from '../../credits/v1/icredits'
import { CreditDebt as arangodb_cloud_internal_credits_v1_CreditDebt } from '../../credits/v1/icredits'
import { ListAllCreditBundlesRequest as arangodb_cloud_internal_credits_v1_ListAllCreditBundlesRequest } from '../../credits/v1/icredits'
import { ConversionRate as arangodb_cloud_internal_currency_v1_ConversionRate } from '../../currency/v1/icurrency'
import { ConversionRateList as arangodb_cloud_internal_currency_v1_ConversionRateList } from '../../currency/v1/icurrency'
import { ConvertRequest as arangodb_cloud_internal_currency_v1_ConvertRequest } from '../../currency/v1/icurrency'
import { ConvertResponse as arangodb_cloud_internal_currency_v1_ConvertResponse } from '../../currency/v1/icurrency'
import { URL as arangodb_cloud_internal_dashboardurl_v1_URL } from '../../dashboardurl/v1/idashboardurl'
import { DataCluster as arangodb_cloud_internal_data_v1_DataCluster } from '../../data/v1/idata'
import { DataClusterCostBenefits as arangodb_cloud_internal_data_v1_DataClusterCostBenefits } from '../../data/v1/idata'
import { DataClusterKubeConfig as arangodb_cloud_internal_data_v1_DataClusterKubeConfig } from '../../data/v1/idata'
import { DataClusterList as arangodb_cloud_internal_data_v1_DataClusterList } from '../../data/v1/idata'
import { DeploymentCount as arangodb_cloud_internal_data_v1_DeploymentCount } from '../../data/v1/idata'
import { DeploymentEmailAddresses as arangodb_cloud_internal_data_v1_DeploymentEmailAddresses } from '../../data/v1/idata'
import { DeploymentInfo as arangodb_cloud_internal_data_v1_DeploymentInfo } from '../../data/v1/idata'
import { DeploymentInfoList as arangodb_cloud_internal_data_v1_DeploymentInfoList } from '../../data/v1/idata'
import { DeploymentNetworkTransfer as arangodb_cloud_internal_data_v1_DeploymentNetworkTransfer } from '../../data/v1/idata'
import { DeploymentStatistics as arangodb_cloud_internal_data_v1_DeploymentStatistics } from '../../data/v1/idata'
import { GetDeploymentEmailAddressesRequest as arangodb_cloud_internal_data_v1_GetDeploymentEmailAddressesRequest } from '../../data/v1/idata'
import { JetStreamDataVolumeInfo as arangodb_cloud_internal_data_v1_JetStreamDataVolumeInfo } from '../../data/v1/idata'
import { ListAllDeploymentsRequest as arangodb_cloud_internal_data_v1_ListAllDeploymentsRequest } from '../../data/v1/idata'
import { ListAllDiskPerformancesRequest as arangodb_cloud_internal_data_v1_ListAllDiskPerformancesRequest } from '../../data/v1/idata'
import { ListDataClusterOptions as arangodb_cloud_internal_data_v1_ListDataClusterOptions } from '../../data/v1/idata'
import { ListNodePoolsRequest as arangodb_cloud_internal_data_v1_ListNodePoolsRequest } from '../../data/v1/idata'
import { LogLevelList as arangodb_cloud_internal_data_v1_LogLevelList } from '../../data/v1/idata'
import { MetricsInfo as arangodb_cloud_internal_data_v1_MetricsInfo } from '../../data/v1/idata'
import { NodeList as arangodb_cloud_internal_data_v1_NodeList } from '../../data/v1/idata'
import { NodePoolList as arangodb_cloud_internal_data_v1_NodePoolList } from '../../data/v1/idata'
import { NodeSizeDetails as arangodb_cloud_internal_data_v1_NodeSizeDetails } from '../../data/v1/idata'
import { PodStatistics as arangodb_cloud_internal_data_v1_PodStatistics } from '../../data/v1/idata'
import { RemoveDeploymentNotificationRequest as arangodb_cloud_internal_data_v1_RemoveDeploymentNotificationRequest } from '../../data/v1/idata'
import { RotateDeploymentCredentialsRequest as arangodb_cloud_internal_data_v1_RotateDeploymentCredentialsRequest } from '../../data/v1/idata'
import { ScaleNodePoolRequest as arangodb_cloud_internal_data_v1_ScaleNodePoolRequest } from '../../data/v1/idata'
import { SetCustomNotebookImageRequest as arangodb_cloud_internal_data_v1_SetCustomNotebookImageRequest } from '../../data/v1/idata'
import { SetDeploymentPublicBackupsDisabledRequest as arangodb_cloud_internal_data_v1_SetDeploymentPublicBackupsDisabledRequest } from '../../data/v1/idata'
import { SetDeploymentSchedulingPoliciesEnabledRequest as arangodb_cloud_internal_data_v1_SetDeploymentSchedulingPoliciesEnabledRequest } from '../../data/v1/idata'
import { SetDeploymentSchedulingPoliciesRequest as arangodb_cloud_internal_data_v1_SetDeploymentSchedulingPoliciesRequest } from '../../data/v1/idata'
import { SetKubeArangodbAdditionalCommandLineArgumentsRequest as arangodb_cloud_internal_data_v1_SetKubeArangodbAdditionalCommandLineArgumentsRequest } from '../../data/v1/idata'
import { SetMemberLogLevelRequest as arangodb_cloud_internal_data_v1_SetMemberLogLevelRequest } from '../../data/v1/idata'
import { UpdateDataClusterAnnotationsRequest as arangodb_cloud_internal_data_v1_UpdateDataClusterAnnotationsRequest } from '../../data/v1/idata'
import { UpdateDataClusterCiliumHubbleRequest as arangodb_cloud_internal_data_v1_UpdateDataClusterCiliumHubbleRequest } from '../../data/v1/idata'
import { UpdateDataClusterCoreDumpRequest as arangodb_cloud_internal_data_v1_UpdateDataClusterCoreDumpRequest } from '../../data/v1/idata'
import { UpdateDataClusterDiskSizesRequest as arangodb_cloud_internal_data_v1_UpdateDataClusterDiskSizesRequest } from '../../data/v1/idata'
import { UpdateDataClusterMaintenanceModeRequest as arangodb_cloud_internal_data_v1_UpdateDataClusterMaintenanceModeRequest } from '../../data/v1/idata'
import { UpdateDataClusterNodeUpdateConcurrencyLevelRequest as arangodb_cloud_internal_data_v1_UpdateDataClusterNodeUpdateConcurrencyLevelRequest } from '../../data/v1/idata'
import { UpdateDataClusterPrometheusMemoryRequest as arangodb_cloud_internal_data_v1_UpdateDataClusterPrometheusMemoryRequest } from '../../data/v1/idata'
import { UpdateDataClusterRebootNodesAllowedRequest as arangodb_cloud_internal_data_v1_UpdateDataClusterRebootNodesAllowedRequest } from '../../data/v1/idata'
import { UpdateDataClusterUpgradeNodepoolsAllowedRequest as arangodb_cloud_internal_data_v1_UpdateDataClusterUpgradeNodepoolsAllowedRequest } from '../../data/v1/idata'
import { UpdateDataClusterVolumeInfosRequest as arangodb_cloud_internal_data_v1_UpdateDataClusterVolumeInfosRequest } from '../../data/v1/idata'
import { UpdateDeploymentAgencyResourcesFactorRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentAgencyResourcesFactorRequest } from '../../data/v1/idata'
import { UpdateDeploymentAllowTrafficRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentAllowTrafficRequest } from '../../data/v1/idata'
import { UpdateDeploymentAnnotationsRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentAnnotationsRequest } from '../../data/v1/idata'
import { UpdateDeploymentCPUFactorsRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentCPUFactorsRequest } from '../../data/v1/idata'
import { UpdateDeploymentCPURatioRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentCPURatioRequest } from '../../data/v1/idata'
import { UpdateDeploymentCommunicationMethodRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentCommunicationMethodRequest } from '../../data/v1/idata'
import { UpdateDeploymentCoreDumpFilterRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentCoreDumpFilterRequest } from '../../data/v1/idata'
import { UpdateDeploymentCustomCommandLineArgumentsRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentCustomCommandLineArgumentsRequest } from '../../data/v1/idata'
import { UpdateDeploymentDiskSizeRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentDiskSizeRequest } from '../../data/v1/idata'
import { UpdateDeploymentEndpointAllowlistsRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentEndpointAllowlistsRequest } from '../../data/v1/idata'
import { UpdateDeploymentEnvoyAccessLogRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentEnvoyAccessLogRequest } from '../../data/v1/idata'
import { UpdateDeploymentHibernationModeRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentHibernationModeRequest } from '../../data/v1/idata'
import { UpdateDeploymentKubeArangodbRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentKubeArangodbRequest } from '../../data/v1/idata'
import { UpdateDeploymentLoadBalancerRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentLoadBalancerRequest } from '../../data/v1/idata'
import { UpdateDeploymentMaintenanceModeRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentMaintenanceModeRequest } from '../../data/v1/idata'
import { UpdateDeploymentMemoryFactorsRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentMemoryFactorsRequest } from '../../data/v1/idata'
import { UpdateDeploymentMemoryRatioRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentMemoryRatioRequest } from '../../data/v1/idata'
import { UpdateDeploymentMemoryReservesRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentMemoryReservesRequest } from '../../data/v1/idata'
import { UpdateDeploymentNotificationRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentNotificationRequest } from '../../data/v1/idata'
import { UpdateDeploymentReplicationRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentReplicationRequest } from '../../data/v1/idata'
import { UpdateDeploymentSupportPlanRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentSupportPlanRequest } from '../../data/v1/idata'
import { UpdateDeploymentSuppressAlertsRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentSuppressAlertsRequest } from '../../data/v1/idata'
import { UpdateDeploymentToUseJetStreamForAuditLogsRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentToUseJetStreamForAuditLogsRequest } from '../../data/v1/idata'
import { UpdateDeploymentTopologyAwarenessRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentTopologyAwarenessRequest } from '../../data/v1/idata'
import { UpdateDeploymentUseShardRebalancerRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentUseShardRebalancerRequest } from '../../data/v1/idata'
import { UpdateDeploymentVSTEnabledRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentVSTEnabledRequest } from '../../data/v1/idata'
import { UpdateDeploymentVolumeInfosRequest as arangodb_cloud_internal_data_v1_UpdateDeploymentVolumeInfosRequest } from '../../data/v1/idata'
import { UpdateKubernetesResourcesPatchesRequest as arangodb_cloud_internal_data_v1_UpdateKubernetesResourcesPatchesRequest } from '../../data/v1/idata'
import { DataClusterOptimizationStatus as arangodb_cloud_internal_datacluster_optimize_v1_DataClusterOptimizationStatus } from '../../datacluster-optimize/v1/idatacluster-optimize'
import { GetDataClusterOptimizationStatusRequest as arangodb_cloud_internal_datacluster_optimize_v1_GetDataClusterOptimizationStatusRequest } from '../../datacluster-optimize/v1/idatacluster-optimize'
import { StartDataClusterOptimizationRequest as arangodb_cloud_internal_datacluster_optimize_v1_StartDataClusterOptimizationRequest } from '../../datacluster-optimize/v1/idatacluster-optimize'
import { StopDataClusterOptimizationRequest as arangodb_cloud_internal_datacluster_optimize_v1_StopDataClusterOptimizationRequest } from '../../datacluster-optimize/v1/idatacluster-optimize'
import { StartRequest as arangodb_cloud_internal_dcupdate_v1_StartRequest } from '../../dcupdate/v1/dc_update'
import { StopRequest as arangodb_cloud_internal_dcupdate_v1_StopRequest } from '../../dcupdate/v1/dc_update'
import { UpdateStatus as arangodb_cloud_internal_dcupdate_v1_UpdateStatus } from '../../dcupdate/v1/dc_update'
import { DeploymentProfileInfo as arangodb_cloud_internal_deploymentprofile_v1_DeploymentProfileInfo } from '../../deployment-profile/v1/ideploymentprofile'
import { DeploymentProfileInfoList as arangodb_cloud_internal_deploymentprofile_v1_DeploymentProfileInfoList } from '../../deployment-profile/v1/ideploymentprofile'
import { ListDeploymentProfileInfosRequest as arangodb_cloud_internal_deploymentprofile_v1_ListDeploymentProfileInfosRequest } from '../../deployment-profile/v1/ideploymentprofile'
import { ListUpdateJobsRequest as arangodb_cloud_internal_deploymentupdater_v1_ListUpdateJobsRequest } from '../../deployment-updater/v1/ideploymentupdater'
import { UpdateJob as arangodb_cloud_internal_deploymentupdater_v1_UpdateJob } from '../../deployment-updater/v1/ideploymentupdater'
import { UpdateJobList as arangodb_cloud_internal_deploymentupdater_v1_UpdateJobList } from '../../deployment-updater/v1/ideploymentupdater'
import { UpdateJobStateChangeRequest as arangodb_cloud_internal_deploymentupdater_v1_UpdateJobStateChangeRequest } from '../../deployment-updater/v1/ideploymentupdater'
import { EmailMessageList as arangodb_cloud_internal_email_v1_EmailMessageList } from '../../email/v1/iemail'
import { ListEmailMessagesRequest as arangodb_cloud_internal_email_v1_ListEmailMessagesRequest } from '../../email/v1/iemail'
import { FeatureFlags as arangodb_cloud_internal_feature_v1_FeatureFlags } from '../../feature/v1/ifeature'
import { GetFeatureFlagsRequest as arangodb_cloud_internal_feature_v1_GetFeatureFlagsRequest } from '../../feature/v1/ifeature'
import { UpdateFeatureFlagsRequest as arangodb_cloud_internal_feature_v1_UpdateFeatureFlagsRequest } from '../../feature/v1/ifeature'
import { AuthenticationStatistics as arangodb_cloud_internal_iam_v1_AuthenticationStatistics } from '../../iam/v1/iiam'
import { EmailPattern as arangodb_cloud_internal_iam_v1_EmailPattern } from '../../iam/v1/iiam'
import { EmailPatternList as arangodb_cloud_internal_iam_v1_EmailPatternList } from '../../iam/v1/iiam'
import { ListEmailPatternsRequest as arangodb_cloud_internal_iam_v1_ListEmailPatternsRequest } from '../../iam/v1/iiam'
import { ListPublicEmailProviderDomainsRequest as arangodb_cloud_internal_iam_v1_ListPublicEmailProviderDomainsRequest } from '../../iam/v1/iiam'
import { ListUsersRequest as arangodb_cloud_internal_iam_v1_ListUsersRequest } from '../../iam/v1/iiam'
import { MobilePhoneVerificationCode as arangodb_cloud_internal_iam_v1_MobilePhoneVerificationCode } from '../../iam/v1/iiam'
import { PublicEmailProviderDomain as arangodb_cloud_internal_iam_v1_PublicEmailProviderDomain } from '../../iam/v1/iiam'
import { PublicEmailProviderDomainList as arangodb_cloud_internal_iam_v1_PublicEmailProviderDomainList } from '../../iam/v1/iiam'
import { SendVerificationEmailRequest as arangodb_cloud_internal_iam_v1_SendVerificationEmailRequest } from '../../iam/v1/iiam'
import { UserList as arangodb_cloud_internal_iam_v1_UserList } from '../../iam/v1/iiam'
import { AbortRequest as arangodb_cloud_internal_integrationtests_v1_AbortRequest } from '../../integration-tests/v1/integration_tests'
import { GetStatusRequest as arangodb_cloud_internal_integrationtests_v1_GetStatusRequest } from '../../integration-tests/v1/integration_tests'
import { ListTestRunsRequest as arangodb_cloud_internal_integrationtests_v1_ListTestRunsRequest } from '../../integration-tests/v1/integration_tests'
import { RunTestsRequest as arangodb_cloud_internal_integrationtests_v1_RunTestsRequest } from '../../integration-tests/v1/integration_tests'
import { TestList as arangodb_cloud_internal_integrationtests_v1_TestList } from '../../integration-tests/v1/integration_tests'
import { TestResults as arangodb_cloud_internal_integrationtests_v1_TestResults } from '../../integration-tests/v1/integration_tests'
import { TestRun as arangodb_cloud_internal_integrationtests_v1_TestRun } from '../../integration-tests/v1/integration_tests'
import { TestRunList as arangodb_cloud_internal_integrationtests_v1_TestRunList } from '../../integration-tests/v1/integration_tests'
import { DeploymentJobList as arangodb_cloud_internal_job_v1_DeploymentJobList } from '../../job/v1/ijob'
import { ListDeploymentJobsRequest as arangodb_cloud_internal_job_v1_ListDeploymentJobsRequest } from '../../job/v1/ijob'
import { KubernetesAccessRequest as arangodb_cloud_internal_kubeaccess_v1_KubernetesAccessRequest } from '../../kube-access/v1/ikube-access'
import { KubernetesAccessResponse as arangodb_cloud_internal_kubeaccess_v1_KubernetesAccessResponse } from '../../kube-access/v1/ikube-access'
import { MLServicesInfo as arangodb_cloud_internal_ml_v1_MLServicesInfo } from '../../ml/v1/iml'
import { SetAllowGPUWorkloadsRequest as arangodb_cloud_internal_ml_v1_SetAllowGPUWorkloadsRequest } from '../../ml/v1/iml'
import { SetJobsResourceLimitsRequest as arangodb_cloud_internal_ml_v1_SetJobsResourceLimitsRequest } from '../../ml/v1/iml'
import { AnalyseClusterRequest as arangodb_cloud_internal_monitoring_v1_AnalyseClusterRequest } from '../../monitoring/v1/imonitoring'
import { Chunk as arangodb_cloud_internal_monitoring_v1_Chunk } from '../../monitoring/v1/imonitoring'
import { DebugClusterInfo as arangodb_cloud_internal_monitoring_v1_DebugClusterInfo } from '../../monitoring/v1/imonitoring'
import { DebugClusterRequest as arangodb_cloud_internal_monitoring_v1_DebugClusterRequest } from '../../monitoring/v1/imonitoring'
import { DetectSilentOutOfSyncRequest as arangodb_cloud_internal_monitoring_v1_DetectSilentOutOfSyncRequest } from '../../monitoring/v1/imonitoring'
import { GetAgencyDumpRequest as arangodb_cloud_internal_monitoring_v1_GetAgencyDumpRequest } from '../../monitoring/v1/imonitoring'
import { GetAgencyStateRequest as arangodb_cloud_internal_monitoring_v1_GetAgencyStateRequest } from '../../monitoring/v1/imonitoring'
import { GetClusterHealthRequest as arangodb_cloud_internal_monitoring_v1_GetClusterHealthRequest } from '../../monitoring/v1/imonitoring'
import { GetKubernetesResourceRequest as arangodb_cloud_internal_monitoring_v1_GetKubernetesResourceRequest } from '../../monitoring/v1/imonitoring'
import { SetDeploymentLogLevelRequest as arangodb_cloud_internal_monitoring_v1_SetDeploymentLogLevelRequest } from '../../monitoring/v1/imonitoring'
import { ListLoadBalancerAssignmentsRequest as arangodb_cloud_internal_network_v1_ListLoadBalancerAssignmentsRequest } from '../../network/v1/inetwork'
import { ListLoadBalancerTypesRequest as arangodb_cloud_internal_network_v1_ListLoadBalancerTypesRequest } from '../../network/v1/inetwork'
import { ListLoadBalancersRequest as arangodb_cloud_internal_network_v1_ListLoadBalancersRequest } from '../../network/v1/inetwork'
import { LoadBalancer as arangodb_cloud_internal_network_v1_LoadBalancer } from '../../network/v1/inetwork'
import { LoadBalancerAssignmentList as arangodb_cloud_internal_network_v1_LoadBalancerAssignmentList } from '../../network/v1/inetwork'
import { LoadBalancerList as arangodb_cloud_internal_network_v1_LoadBalancerList } from '../../network/v1/inetwork'
import { LoadBalancerTypeList as arangodb_cloud_internal_network_v1_LoadBalancerTypeList } from '../../network/v1/inetwork'
import { PrivateEndpointServiceInfo as arangodb_cloud_internal_network_v1_PrivateEndpointServiceInfo } from '../../network/v1/inetwork'
import { SetLoadBalancerMemoryOverrideRequest as arangodb_cloud_internal_network_v1_SetLoadBalancerMemoryOverrideRequest } from '../../network/v1/inetwork'
import { SetLoadBalancerReplicasOverrideRequest as arangodb_cloud_internal_network_v1_SetLoadBalancerReplicasOverrideRequest } from '../../network/v1/inetwork'
import { UpdateLoadBalancerTypeForDeploymentRequest as arangodb_cloud_internal_network_v1_UpdateLoadBalancerTypeForDeploymentRequest } from '../../network/v1/inetwork'
import { ListAllNotebookModelsRequest as arangodb_cloud_internal_notebook_v1_ListAllNotebookModelsRequest } from '../../notebook/v1/inotebook'
import { ListNotebookInfosRequest as arangodb_cloud_internal_notebook_v1_ListNotebookInfosRequest } from '../../notebook/v1/inotebook'
import { NotebookInfoList as arangodb_cloud_internal_notebook_v1_NotebookInfoList } from '../../notebook/v1/inotebook'
import { NotebookModelInfo as arangodb_cloud_internal_notebook_v1_NotebookModelInfo } from '../../notebook/v1/inotebook'
import { NotebookModelInfoList as arangodb_cloud_internal_notebook_v1_NotebookModelInfoList } from '../../notebook/v1/inotebook'
import { SetCustomNotebookImageRequest as arangodb_cloud_internal_notebook_v1_SetCustomNotebookImageRequest } from '../../notebook/v1/inotebook'
import { SetNotebookMaintenanceModeRequest as arangodb_cloud_internal_notebook_v1_SetNotebookMaintenanceModeRequest } from '../../notebook/v1/inotebook'
import { SetSchedulingPoliciesRequest as arangodb_cloud_internal_notebook_v1_SetSchedulingPoliciesRequest } from '../../notebook/v1/inotebook'
import { RegionLimits as arangodb_cloud_internal_platform_v1_RegionLimits } from '../../platform/v1/iplatform'
import { AttachDeploymentToPrepaidDeploymentRequest as arangodb_cloud_internal_prepaid_v1_AttachDeploymentToPrepaidDeploymentRequest } from '../../prepaid/v1/iprepaid'
import { DetachDeploymentFromPrepaidDeploymentRequest as arangodb_cloud_internal_prepaid_v1_DetachDeploymentFromPrepaidDeploymentRequest } from '../../prepaid/v1/iprepaid'
import { GetPrepaidDeploymentForDeploymentRequest as arangodb_cloud_internal_prepaid_v1_GetPrepaidDeploymentForDeploymentRequest } from '../../prepaid/v1/iprepaid'
import { ListAllPrepaidDeploymentsRequest as arangodb_cloud_internal_prepaid_v1_ListAllPrepaidDeploymentsRequest } from '../../prepaid/v1/iprepaid'
import { PrepaidDeploymentInfo as arangodb_cloud_internal_prepaid_v1_PrepaidDeploymentInfo } from '../../prepaid/v1/iprepaid'
import { PrepaidDeploymentInfoList as arangodb_cloud_internal_prepaid_v1_PrepaidDeploymentInfoList } from '../../prepaid/v1/iprepaid'
import { ListPlanAssignmentsRequest as arangodb_cloud_internal_pricing_v1_ListPlanAssignmentsRequest } from '../../pricing/v1/ipricing'
import { ListPlansRequest as arangodb_cloud_internal_pricing_v1_ListPlansRequest } from '../../pricing/v1/ipricing'
import { Plan as arangodb_cloud_internal_pricing_v1_Plan } from '../../pricing/v1/ipricing'
import { PlanAssignment as arangodb_cloud_internal_pricing_v1_PlanAssignment } from '../../pricing/v1/ipricing'
import { PlanAssignmentList as arangodb_cloud_internal_pricing_v1_PlanAssignmentList } from '../../pricing/v1/ipricing'
import { PlanList as arangodb_cloud_internal_pricing_v1_PlanList } from '../../pricing/v1/ipricing'
import { PriceList as arangodb_cloud_internal_pricing_v1_PriceList } from '../../pricing/v1/ipricing'
import { PriceRequest as arangodb_cloud_internal_pricing_v1_PriceRequest } from '../../pricing/v1/ipricing'
import { CreateReportRequest as arangodb_cloud_internal_reporting_v1_CreateReportRequest } from '../../reporting/v1/ireporting'
import { CreateReportResponse as arangodb_cloud_internal_reporting_v1_CreateReportResponse } from '../../reporting/v1/ireporting'
import { CustomerChanges as arangodb_cloud_internal_reporting_v1_CustomerChanges } from '../../reporting/v1/ireporting'
import { DeploymentChanges as arangodb_cloud_internal_reporting_v1_DeploymentChanges } from '../../reporting/v1/ireporting'
import { DeploymentsCost as arangodb_cloud_internal_reporting_v1_DeploymentsCost } from '../../reporting/v1/ireporting'
import { ExampleInstallations as arangodb_cloud_internal_reporting_v1_ExampleInstallations } from '../../reporting/v1/ireporting'
import { GetCustomerChangesRequest as arangodb_cloud_internal_reporting_v1_GetCustomerChangesRequest } from '../../reporting/v1/ireporting'
import { GetDeploymentChangesRequest as arangodb_cloud_internal_reporting_v1_GetDeploymentChangesRequest } from '../../reporting/v1/ireporting'
import { GetDeploymentsCostRequest as arangodb_cloud_internal_reporting_v1_GetDeploymentsCostRequest } from '../../reporting/v1/ireporting'
import { GetExampleInstallationsRequest as arangodb_cloud_internal_reporting_v1_GetExampleInstallationsRequest } from '../../reporting/v1/ireporting'
import { GetRecurringRevenueByOrganizationRequest as arangodb_cloud_internal_reporting_v1_GetRecurringRevenueByOrganizationRequest } from '../../reporting/v1/ireporting'
import { GetRevenueRequest as arangodb_cloud_internal_reporting_v1_GetRevenueRequest } from '../../reporting/v1/ireporting'
import { Revenue as arangodb_cloud_internal_reporting_v1_Revenue } from '../../reporting/v1/ireporting'
import { RevenueByOrganization as arangodb_cloud_internal_reporting_v1_RevenueByOrganization } from '../../reporting/v1/ireporting'
import { DataProcessingAddendumInfoList as arangodb_cloud_internal_resourcemanager_v1_DataProcessingAddendumInfoList } from '../../resourcemanager/v1/iresourcemanager'
import { EntityDetailsList as arangodb_cloud_internal_resourcemanager_v1_EntityDetailsList } from '../../resourcemanager/v1/iresourcemanager'
import { GetUploadedDocumentRequest as arangodb_cloud_internal_resourcemanager_v1_GetUploadedDocumentRequest } from '../../resourcemanager/v1/iresourcemanager'
import { ListAllOrganizationsRequest as arangodb_cloud_internal_resourcemanager_v1_ListAllOrganizationsRequest } from '../../resourcemanager/v1/iresourcemanager'
import { ListUploadedDocumentsRequest as arangodb_cloud_internal_resourcemanager_v1_ListUploadedDocumentsRequest } from '../../resourcemanager/v1/iresourcemanager'
import { OrganizationCount as arangodb_cloud_internal_resourcemanager_v1_OrganizationCount } from '../../resourcemanager/v1/iresourcemanager'
import { OrganizationInfo as arangodb_cloud_internal_resourcemanager_v1_OrganizationInfo } from '../../resourcemanager/v1/iresourcemanager'
import { OrganizationInfoList as arangodb_cloud_internal_resourcemanager_v1_OrganizationInfoList } from '../../resourcemanager/v1/iresourcemanager'
import { OrganizationSalesInfo as arangodb_cloud_internal_resourcemanager_v1_OrganizationSalesInfo } from '../../resourcemanager/v1/iresourcemanager'
import { OrganizationStatistics as arangodb_cloud_internal_resourcemanager_v1_OrganizationStatistics } from '../../resourcemanager/v1/iresourcemanager'
import { OrganizationSupportInfo as arangodb_cloud_internal_resourcemanager_v1_OrganizationSupportInfo } from '../../resourcemanager/v1/iresourcemanager'
import { RemoveOrganizationNotificationRequest as arangodb_cloud_internal_resourcemanager_v1_RemoveOrganizationNotificationRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetAllowOrganizationDelinquentPayerRequest as arangodb_cloud_internal_resourcemanager_v1_SetAllowOrganizationDelinquentPayerRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetDeploymentModelsRequest as arangodb_cloud_internal_resourcemanager_v1_SetDeploymentModelsRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetOrganizationAllowedCustomImagesRequest as arangodb_cloud_internal_resourcemanager_v1_SetOrganizationAllowedCustomImagesRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetOrganizationAllowedIAMProvidersRequest as arangodb_cloud_internal_resourcemanager_v1_SetOrganizationAllowedIAMProvidersRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetOrganizationAllowedSCIMRequest as arangodb_cloud_internal_resourcemanager_v1_SetOrganizationAllowedSCIMRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetOrganizationAutomaticInvoiceVerificationRequest as arangodb_cloud_internal_resourcemanager_v1_SetOrganizationAutomaticInvoiceVerificationRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetOrganizationBusinessAssociateAgreementSignedRequest as arangodb_cloud_internal_resourcemanager_v1_SetOrganizationBusinessAssociateAgreementSignedRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetOrganizationCostCenterRequest as arangodb_cloud_internal_resourcemanager_v1_SetOrganizationCostCenterRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetOrganizationCustomImageFilterRequest as arangodb_cloud_internal_resourcemanager_v1_SetOrganizationCustomImageFilterRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetOrganizationCustomInvoiceThresholdRequest as arangodb_cloud_internal_resourcemanager_v1_SetOrganizationCustomInvoiceThresholdRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetOrganizationCustomSettingsRequest as arangodb_cloud_internal_resourcemanager_v1_SetOrganizationCustomSettingsRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetOrganizationDataclusterLabelFilterRequest as arangodb_cloud_internal_resourcemanager_v1_SetOrganizationDataclusterLabelFilterRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetOrganizationEntityRequest as arangodb_cloud_internal_resourcemanager_v1_SetOrganizationEntityRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetOrganizationHasPrivateDataClustersRequest as arangodb_cloud_internal_resourcemanager_v1_SetOrganizationHasPrivateDataClustersRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetOrganizationInternalContactsRequest as arangodb_cloud_internal_resourcemanager_v1_SetOrganizationInternalContactsRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetOrganizationKubernetesAccessRequest as arangodb_cloud_internal_resourcemanager_v1_SetOrganizationKubernetesAccessRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetOrganizationRequiresPrepaidDeploymentsRequest as arangodb_cloud_internal_resourcemanager_v1_SetOrganizationRequiresPrepaidDeploymentsRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetOrganizationSalesInfoRequest as arangodb_cloud_internal_resourcemanager_v1_SetOrganizationSalesInfoRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetOrganizationSupportInfoRequest as arangodb_cloud_internal_resourcemanager_v1_SetOrganizationSupportInfoRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetOrganizationTechnicalAccountManagerRequest as arangodb_cloud_internal_resourcemanager_v1_SetOrganizationTechnicalAccountManagerRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetOrganizationTierRequest as arangodb_cloud_internal_resourcemanager_v1_SetOrganizationTierRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetOrganizationUsesCreditBundlesRequest as arangodb_cloud_internal_resourcemanager_v1_SetOrganizationUsesCreditBundlesRequest } from '../../resourcemanager/v1/iresourcemanager'
import { SetQuotaRequest as arangodb_cloud_internal_resourcemanager_v1_SetQuotaRequest } from '../../resourcemanager/v1/iresourcemanager'
import { TermsAndConditionsInfoList as arangodb_cloud_internal_resourcemanager_v1_TermsAndConditionsInfoList } from '../../resourcemanager/v1/iresourcemanager'
import { TierDetailsList as arangodb_cloud_internal_resourcemanager_v1_TierDetailsList } from '../../resourcemanager/v1/iresourcemanager'
import { UpdateOrganizationNotificationRequest as arangodb_cloud_internal_resourcemanager_v1_UpdateOrganizationNotificationRequest } from '../../resourcemanager/v1/iresourcemanager'
import { UploadedDocument as arangodb_cloud_internal_resourcemanager_v1_UploadedDocument } from '../../resourcemanager/v1/iresourcemanager'
import { UploadedDocumentList as arangodb_cloud_internal_resourcemanager_v1_UploadedDocumentList } from '../../resourcemanager/v1/iresourcemanager'
import { ListAllSchedulingPoliciesRequest as arangodb_cloud_internal_scheduling_v1_ListAllSchedulingPoliciesRequest } from '../../scheduling/v1/ischeduling'
import { SchedulingPolicy as arangodb_cloud_internal_scheduling_v1_SchedulingPolicy } from '../../scheduling/v1/ischeduling'
import { SchedulingPolicyList as arangodb_cloud_internal_scheduling_v1_SchedulingPolicyList } from '../../scheduling/v1/ischeduling'
import { OrganizationIAMProviderLimits as arangodb_cloud_internal_security_v1_OrganizationIAMProviderLimits } from '../../security/v1/isecurity'
import { ListAllPlansRequest as arangodb_cloud_internal_support_v1_ListAllPlansRequest } from '../../support/v1/isupport'
import { SupportRequestCustomer as arangodb_cloud_internal_support_v1_SupportRequestCustomer } from '../../support/v1/isupport'
import { ListAllUsageItemsRequest as arangodb_cloud_internal_usage_v1_ListAllUsageItemsRequest } from '../../usage/v1/iusage'
import { UsageItemInfoList as arangodb_cloud_internal_usage_v1_UsageItemInfoList } from '../../usage/v1/iusage'
import { ListTokensRequest as arangodb_cloud_metrics_v1_ListTokensRequest } from '../../metrics/v1/metrics'
import { TokenList as arangodb_cloud_metrics_v1_TokenList } from '../../metrics/v1/metrics'
import { MLServices as arangodb_cloud_ml_v1_MLServices } from '../../ml/v1/ml'
import { PrepaidDeployment as arangodb_cloud_prepaid_v1_PrepaidDeployment } from '../../prepaid/v1/prepaid'
import { DeploymentReplication as arangodb_cloud_replication_v1_DeploymentReplication } from '../../replication/v1/replication'
import { DataProcessingAddendum as arangodb_cloud_resourcemanager_v1_DataProcessingAddendum } from '../../resourcemanager/v1/resourcemanager'
import { EventList as arangodb_cloud_resourcemanager_v1_EventList } from '../../resourcemanager/v1/resourcemanager'
import { ListEventOptions as arangodb_cloud_resourcemanager_v1_ListEventOptions } from '../../resourcemanager/v1/resourcemanager'
import { ListQuotasRequest as arangodb_cloud_resourcemanager_v1_ListQuotasRequest } from '../../resourcemanager/v1/resourcemanager'
import { MemberList as arangodb_cloud_resourcemanager_v1_MemberList } from '../../resourcemanager/v1/resourcemanager'
import { Organization as arangodb_cloud_resourcemanager_v1_Organization } from '../../resourcemanager/v1/resourcemanager'
import { OrganizationList as arangodb_cloud_resourcemanager_v1_OrganizationList } from '../../resourcemanager/v1/resourcemanager'
import { Project as arangodb_cloud_resourcemanager_v1_Project } from '../../resourcemanager/v1/resourcemanager'
import { ProjectList as arangodb_cloud_resourcemanager_v1_ProjectList } from '../../resourcemanager/v1/resourcemanager'
import { QuotaList as arangodb_cloud_resourcemanager_v1_QuotaList } from '../../resourcemanager/v1/resourcemanager'
import { TermsAndConditions as arangodb_cloud_resourcemanager_v1_TermsAndConditions } from '../../resourcemanager/v1/resourcemanager'
import { Tier as arangodb_cloud_resourcemanager_v1_Tier } from '../../resourcemanager/v1/resourcemanager'
import { IPAllowlist as arangodb_cloud_security_v1_IPAllowlist } from '../../security/v1/security'
import { PlanList as arangodb_cloud_support_v1_PlanList } from '../../support/v1/support'
import { ListUsageItemsRequest as arangodb_cloud_usage_v1_ListUsageItemsRequest } from '../../usage/v1/usage'
import { UsageItemList as arangodb_cloud_usage_v1_UsageItemList } from '../../usage/v1/usage'

// File: dashboard/v1/idashboard.proto
// Package: arangodb.cloud.internal.dashboard.v1

// CloneDeploymentFromBackupRequest defines a request object for clone deployment call.
export interface CloneDeploymentFromBackupRequest {
  // The ID of the backup to clone a deployment from.
  // string
  backup_id?: string;
  
  // Target region.
  // This is an optional field
  // string
  region_id?: string;
}

// Status of the Controlplane
export interface ControlplaneStatus {
  // Environment of the cluster
  // string
  environment?: string;
  
  // The git branch flux is pointing to
  // string
  git_branch?: string;
  
  // Status of the Arango DB deployment, including individual arangodb servers on the cluster
  // arangodb.cloud.data.v1.Deployment.Status
  deployment_status?: arangodb_cloud_data_v1_Deployment_Status;
  
  // Set if the deployment is up-to-date and has no pending upgrade (this is the status info from the kube-arangodb operator).
  // boolean
  deployment_is_up_to_date?: boolean;
  
  // Nodes of the cluster
  // arangodb.cloud.internal.data.v1.NodeList
  nodes?: arangodb_cloud_internal_data_v1_NodeList;
  
  // Pod statistics on the cluster
  // arangodb.cloud.internal.data.v1.PodStatistics
  pod_statistics?: arangodb_cloud_internal_data_v1_PodStatistics;
  
  // Metrics namespace related info on the cluster
  // MetricsInfoCP
  metrics_info?: MetricsInfoCP;
}

// Message used to Create manual (internal) backups
export interface CreateBackupRequest {
  // Public backup
  // arangodb.cloud.backup.v1.Backup
  backup?: arangodb_cloud_backup_v1_Backup;
  
  // Set if the backup may be created potentially inconsistent.
  // boolean
  allow_inconsistent?: boolean;
  
  // Set if the backup is for internal ArangoGraph Insights Platform use only, and cannot been seen by the customer.
  // If uploaded it will be charged normally (and the total size will includes this backup).
  // boolean
  is_internal?: boolean;
}

// Metrics namespace related info on the cluster (contolplane specific)
export interface MetricsInfoCP {
  // Metrics namespace related info on the cluster (shared with DataCluster)
  // arangodb.cloud.internal.data.v1.MetricsInfo
  metrics_info?: arangodb_cloud_internal_data_v1_MetricsInfo;
  
  // Information about Grafana
  // MetricsInfoCP_GrafanaInfo
  grafana_info?: MetricsInfoCP_GrafanaInfo;
  
  // Information about Nats
  // MetricsInfoCP_NatsInfo
  nats_info?: MetricsInfoCP_NatsInfo;
}

// Information about Grafana
export interface MetricsInfoCP_GrafanaInfo {
  // The version of Grafana
  // string
  version?: string;
  
  // The total number of installed dashboards
  // number
  dashboards_installed?: number;
  
  // Information about the volume used to store the data
  // arangodb.cloud.data.v1.DataVolumeInfo
  data_volume_info?: arangodb_cloud_data_v1_DataVolumeInfo;
  
  // Information about the volume used to store the logs
  // arangodb.cloud.data.v1.DataVolumeInfo
  logs_volume_info?: arangodb_cloud_data_v1_DataVolumeInfo;
}

// Information about Nats
export interface MetricsInfoCP_NatsInfo {
  // The version of Nats
  // string cluster_version = 1;
  // The version of Nats-streaming
  // string streaming_cluster_version = 2;
  // Information about the volume used to store the queue data in nats-stream
  // arangodb.cloud.data.v1.DataVolumeInfo
  cluster_streaming_data_volume_info?: arangodb_cloud_data_v1_DataVolumeInfo;
  
  // Information about the volume used to store the queue data in jet stream
  // arangodb.cloud.internal.data.v1.JetStreamDataVolumeInfo
  cluster_jetstream_data_volume_info?: arangodb_cloud_internal_data_v1_JetStreamDataVolumeInfo[];
}

// Details about a entity for organization needed by idashboard.
export interface OrganizationEntityDetails {
  // The entity of the organization
  // OrganizationEntityDetails_EntityInfo
  entity?: OrganizationEntityDetails_EntityInfo;
  
  // If set, the entity is explicitly configured.
  // If not set, the entity is selected as the default for this organization.
  // boolean
  is_explicit?: boolean;
}
export interface OrganizationEntityDetails_EntityInfo {
  // string
  name?: string;
}

// Details about a region needed by idashboard.
export interface RegionDetails {
  // number
  minNodeDiskSize?: number;
  
  // If set, the use of shared loadbalancers for deployments in this region is disabled.
  // boolean
  disableSharedLoadBalancers?: boolean;
}

// Request arguments for SetDataClusterLabels.
export interface SetDataClusterLabelsRequest {
  // Identifier of the datacluster to change
  // string
  datacluster_id?: string;
  
  // New values of the labels field for the datacluster.
  // string
  labels?: string[];
}

// Request arguments for SetDataClusterUnassignable.
export interface SetDataClusterUnassignableRequest {
  // Identifier of the datacluster to change
  // string
  datacluster_id?: string;
  
  // New value of the is_unassignable field for the datacluster.
  // boolean
  is_unassignable?: boolean;
}

// Request arguments for SetDeploymentExpirationDate.
export interface SetDeploymentExpirationDateRequest {
  // Identifier of the deployment to change the expiration date of.
  // string
  deployment_id?: string;
  
  // Number of days to add (or remove in case of negative) to the expiration date.
  // Value must be in range [-31 ... 31]
  // Note: that changing the expiration to a day before today results in
  // an InvalidArgument error.
  // number
  days_delta?: number;
  
  // If set, this deployment will be opt-out from expiration
  // The days_delta will be ignored.
  // boolean
  opt_out?: boolean;
}

// List of tiers
export interface TierList {
  // arangodb.cloud.resourcemanager.v1.Tier
  items?: arangodb_cloud_resourcemanager_v1_Tier[];
}

// Request for updating the billing address of a given organization.
export interface UpdateBillingAddressRequest {
  // ID of the organization for which billing address needs to be updated.
  // string
  organization_id?: string;
  
  // Updated billing address
  // arangodb.cloud.billing.v1.Address
  billing_address?: arangodb_cloud_billing_v1_Address;
}

// Request arguments for UpdateDeploymentFoxxAuthentication.
export interface UpdateDeploymentFoxxAuthenticationRequest {
  // Identifier of the deployment to change the foxx authentication of.
  // string
  deployment_id?: string;
  
  // New value of disable_foxx_authentication field of the deployment.
  // boolean
  disable_foxx_authentication?: boolean;
}

// Request arguments for UpdateDeploymentVersion.
export interface UpdateDeploymentVersionRequest {
  // Identifier of the deployment to change the version of.
  // string
  deployment_id?: string;
  
  // ArangoDB version to use for this deployment.
  // See Version.version.
  // If you change this value to a higher version,
  // the deployment will be upgraded.
  // If you change this value to a lower patch value,
  // the deployment will be downgraded.
  // Any attempt to change to a lower minor or major version
  // is considered an invalid request.
  // Any attempt to change to a version that is not in the
  // list of available versions is considered an invalid request.
  // string
  version?: string;
  
  // If provided, dataclusterd will use this custom image tag instead of the configured one for a given version.
  // Further, ImagePullPolicy will be set to Always.
  // This field can only be set by selected organizations.
  // string
  custom_image?: string;
}

// InternalDashboardService is the API used by the support dashboard
export interface IInternalDashboardService {
  // Get the current API version of this service.
  // This is the same info we expose on all public APIs
  // Required permissions:
  // - None
  GetAPIVersion: (req?: arangodb_cloud_common_v1_Empty) => Promise<arangodb_cloud_common_v1_Version>;
  
  // Get the runtime version of this service.
  // This is the same info as we expose on GetServiceDescriptor().Version
  // Required permissions:
  // - None
  GetVersion: (req?: arangodb_cloud_common_v1_Empty) => Promise<arangodb_cloud_common_v1_Version>;
  
  // Is the authenticated user part of the support organization.
  // Required permissions:
  // - none
  IsSupportUser: (req?: arangodb_cloud_common_v1_Empty) => Promise<arangodb_cloud_common_v1_YesOrNo>;
  
  // Return the list of permissions that are available to the currently authenticated
  // user for actions on the resource identified by the given URL.
  // Required permissions:
  // - None
  GetEffectivePermissions: (req: arangodb_cloud_common_v1_URLOptions) => Promise<arangodb_cloud_iam_v1_PermissionList>;
  
  // Return the lists of permissions that are available to the currently authenticated
  // used for actions on the resources identified by the given URLs.
  // This method can replace multiple GetEffectivePermissions calls into a single roundtrip.
  // Required permissions:
  // - None
  GetMultipleEffectivePermissions: (req: arangodb_cloud_iam_v1_GetMultipleEffectivePermissionsRequest) => Promise<arangodb_cloud_iam_v1_MultiplePermissionLists>;
  
  // Fetch a CA certificate by its id.
  // Required permissions:
  // - internal-dashboard.cacertificate.get on the support organization.
  GetCACertificate: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_crypto_v1_CACertificate>;
  
  // Fetch an IP allowlist by its id.
  // Required permissions:
  // - internal-dashboard.ipallowlist.get globally.
  GetIPAllowlist: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_security_v1_IPAllowlist>;
  
  // Fetch all data clusters.
  // Required permissions:
  // - internal-dashboard.datacluster.list on the support organization.
  ListDataClusters: (req: arangodb_cloud_internal_data_v1_ListDataClusterOptions) => Promise<arangodb_cloud_internal_data_v1_DataClusterList>;
  
  // Fetch a data cluster by its id.
  // Required permissions:
  // - internal-dashboard.datacluster.get on the support organization.
  GetDataCluster: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_data_v1_DataCluster>;
  
  // Fetch the kubernetes access config, used to access the datacluster identified by given ID.
  // Required permissions:
  // - internal-dashboard.datacluster.get-credentials globally
  GetDataClusterKubeConfig: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_data_v1_DataClusterKubeConfig>;
  
  // Fetch the (estimated) costs and benefits for the datacluster identified by given ID.
  // Required permissions:
  // - internal-dashboard.datacluster.get-cost-benefits globally
  GetDataClusterCostBenefits: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_data_v1_DataClusterCostBenefits>;
  
  // Update the (previous) target provision info of a data cluster
  // (found in DataCluster.TargetProvisionInfo and DataCluster.PreviousTargetProvisionInfo)
  // Required permissions:
  // - internal-dashboard.datacluster.update on the support organization.
  UpdateDataClusterTargetProvisionInfo: (req: arangodb_cloud_internal_data_v1_DataCluster) => Promise<void>;
  
  // Update the provision_hash of the data-cluster assignment for the deployment
  // identified by the given ID.
  // The provision_hash is updated to the actual hash of the data-cluster.
  // Required permissions:
  // - internal-dashboard.dataclusterassignment.update-provision-hash globally
  UpdateDataClusterAssignmentProvisionHash: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Update the reboot nodes allowed of a datacluster
  // Required permissions:
  // - internal-dashboard.datacluster.manage on the support organization.
  UpdateDataClusterRebootNodesAllowed: (req: arangodb_cloud_internal_data_v1_UpdateDataClusterRebootNodesAllowedRequest) => Promise<void>;
  
  // Update the upgrade nodepools allowed of a datacluster
  // Required permissions:
  // - internal-dashboard.datacluster.manage on the support organization.
  UpdateDataClusterUpgradeNodepoolsAllowed: (req: arangodb_cloud_internal_data_v1_UpdateDataClusterUpgradeNodepoolsAllowedRequest) => Promise<void>;
  
  // Update the maintenance mode of a datacluster
  // Required permissions:
  // - internal-dashboard.datacluster.manage on the support organization.
  UpdateDataClusterMaintenanceMode: (req: arangodb_cloud_internal_data_v1_UpdateDataClusterMaintenanceModeRequest) => Promise<void>;
  
  // Update the annotations of a datacluster
  // Required permissions:
  // - internal-dashboard.datacluster.manage on the support organization.
  UpdateDataClusterAnnotations: (req: arangodb_cloud_internal_data_v1_UpdateDataClusterAnnotationsRequest) => Promise<void>;
  
  // Update the Cilium Hubble settings for a datacluster
  // Required permissions:
  // - internal-dashboard.datacluster.manage on globally
  UpdateDataClusterCiliumHubble: (req: arangodb_cloud_internal_data_v1_UpdateDataClusterCiliumHubbleRequest) => Promise<void>;
  
  // Update the core dump enabled setting for a datacluster
  // Required permissions:
  // - internal-dashboard.datacluster.update-core-dump globally
  UpdateDataClusterCoreDump: (req: arangodb_cloud_internal_data_v1_UpdateDataClusterCoreDumpRequest) => Promise<void>;
  
  // Update the Prometheus memory settings for a datacluster
  // Required permissions:
  // - internal-dashboard.datacluster.manage on globally
  UpdateDataClusterPrometheusMemory: (req: arangodb_cloud_internal_data_v1_UpdateDataClusterPrometheusMemoryRequest) => Promise<void>;
  
  // Update data cluster's volume infos (like type, iops and throughput)
  // Required permissions:
  // - internal-dashboard.datacluster.update-volume-infos on the support organization.
  UpdateDataClusterVolumeInfos: (req: arangodb_cloud_internal_data_v1_UpdateDataClusterVolumeInfosRequest) => Promise<void>;
  
  // Update data cluster's disk sizes
  // Required permissions:
  // - internal-dashboard.datacluster.update-disk-sizes on the support organization.
  UpdateDataClusterDiskSizes: (req: arangodb_cloud_internal_data_v1_UpdateDataClusterDiskSizesRequest) => Promise<void>;
  
  // Change the unassignable status of a datacluster
  // Required permissions:
  // - internal-dashboard.datacluster.manage on the support organization.
  SetDataClusterUnassignable: (req: SetDataClusterUnassignableRequest) => Promise<void>;
  
  // Change the labels of a datacluster
  // Required permissions:
  // - internal-dashboard.datacluster.manage on the support organization.
  SetDataClusterLabels: (req: SetDataClusterLabelsRequest) => Promise<void>;
  
  // Fetch the number of deployments that match all of the given filters.
  // Required permissions:
  // - internal-dashboard.deployment.count globally.
  CountDeployments: (req: arangodb_cloud_internal_data_v1_ListAllDeploymentsRequest) => Promise<arangodb_cloud_internal_data_v1_DeploymentCount>;
  
  // Fetch all deployments that match all of the given filters.
  // Required permissions:
  // - internal-dashboard.deployment.list on the support organization.
  ListAllDeployments: (req: arangodb_cloud_internal_data_v1_ListAllDeploymentsRequest) => Promise<arangodb_cloud_internal_data_v1_DeploymentInfoList>;
  
  // Fetch a deployment by its id.
  // Required permissions:
  // - internal-dashboard.deployment.get on the support organization.
  GetDeployment: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_data_v1_DeploymentInfo>;
  
  // Get the deployment network transfer info identified by given deployment ID.
  // Required permissions:
  // - internal-dashboard.deployment.get-network-transfer on the support organization.
  GetDeploymentNetworkTransfer: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_data_v1_DeploymentNetworkTransfer>;
  
  // Update the hibernation mode of a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-hibernation-mode on the support organization.
  UpdateDeploymentHibernationMode: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentHibernationModeRequest) => Promise<void>;
  
  // Update VST enabled of a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-vst-enabled on the support organization.
  UpdateDeploymentVSTEnabled: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentVSTEnabledRequest) => Promise<void>;
  
  // Update the maintenance mode of a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-maintenance-mode on the support organization.
  UpdateDeploymentMaintenanceMode: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentMaintenanceModeRequest) => Promise<void>;
  
  // Update the allow traffic settings of a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-allow-traffic on the support organization.
  UpdateDeploymentAllowTraffic: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentAllowTrafficRequest) => Promise<void>;
  
  // Update the allowed endpoints for a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-endpoint-allowlists on the deployment
  UpdateDeploymentEndpointAllowlists: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentEndpointAllowlistsRequest) => Promise<void>;
  
  // Update the custom command line arguments for a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-custom-command-line-arguments on the deployment
  UpdateDeploymentCustomCommandLineArguments: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentCustomCommandLineArgumentsRequest) => Promise<void>;
  
  // Update the annotations for a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-annoations on the deployment
  UpdateDeploymentAnnotations: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentAnnotationsRequest) => Promise<void>;
  
  // Update version of a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-version on the support organization.
  UpdateDeploymentVersion: (req: UpdateDeploymentVersionRequest) => Promise<void>;
  
  // Update the kube-arangodb settings for a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-kube-arangodb on the deployment
  UpdateDeploymentKubeArangodb: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentKubeArangodbRequest) => Promise<void>;
  
  // Update the load-balancer settings for a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-loadbalancer on the deployment
  UpdateDeploymentLoadBalancer: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentLoadBalancerRequest) => Promise<void>;
  
  // Update the core dump filter for a deployment identified with given ID.
  // Required permissions:
  // - internal-dashboard.deployment.update-core-dump-filter on the deployment
  UpdateDeploymentCoreDumpFilter: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentCoreDumpFilterRequest) => Promise<void>;
  
  // Update the foxx authentication setting for a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-foxx-authentication on the deployment
  UpdateDeploymentFoxxAuthentication: (req: UpdateDeploymentFoxxAuthenticationRequest) => Promise<void>;
  
  // Fetch credentials for the deployment identified by the given id.
  // Required permissions:
  // - internal-dashboard.deployment.get on the support organization.
  // - internal-dashboard.deploymentcredentials.get on the support organization.
  GetDeploymentRootCredentials: (req: arangodb_cloud_data_v1_DeploymentCredentialsRequest) => Promise<arangodb_cloud_data_v1_DeploymentCredentials>;
  
  // Rotate the credentials for accessing the deployment with given id.
  // Required permissions:
  // - internal-dashboard.deploymentcredentials.rotate on the support organization.
  RotateDeploymentCredentials: (req: arangodb_cloud_internal_data_v1_RotateDeploymentCredentialsRequest) => Promise<void>;
  
  // Get deployment statistics
  // Required permissions:
  // - internal-dashboard.statistics.get globally
  GetDeploymentStatistics: (req?: arangodb_cloud_common_v1_Empty) => Promise<arangodb_cloud_internal_data_v1_DeploymentStatistics>;
  
  // Change the expiration date of a deployment
  // Required permissions:
  // - internal-dashboard.deployment.set-expiration-date on the support organization.
  // and
  // - internal-dashboard.deployment.opt-out-expiration on the support organization if the opt_out flag is set.
  SetDeploymentExpirationDate: (req: SetDeploymentExpirationDateRequest) => Promise<void>;
  
  // Fetch all deployment infos that are assigned to the data cluster.
  // Required permissions:
  // - internal-dashboard.deployment.list on the support organization.
  ListAssignedDeployments: (req: arangodb_cloud_common_v1_ListOptions) => Promise<arangodb_cloud_internal_data_v1_DeploymentInfoList>;
  
  // Fetch all users that match all of the given filters.
  // Required permissions:
  // - internal-dashboard.user.list on the support organization.
  ListUsers: (req: arangodb_cloud_internal_iam_v1_ListUsersRequest) => Promise<arangodb_cloud_internal_iam_v1_UserList>;
  
  // Fetch a user by its id.
  // Required permissions:
  // - internal-dashboard.user.get on the support organization.
  GetUser: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_iam_v1_User>;
  
  // Get the mobile phone verification code for a user identified by the given ID.
  // Required permissions:
  // - internal-dashboard.user.get-verification-code on the support organization..
  GetUserMobilePhoneVerificationCode: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_iam_v1_MobilePhoneVerificationCode>;
  
  // Reset mobile phone number for user identified by given ID.
  // Required permissions:
  // - internal-dashboard.user.reset-mobile-phone globally.
  ResetMobilePhone: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Fetch all organizations that match all of the given filters.
  // Required permissions:
  // - internal-dashboard.organization.list on the support organization.
  // This method is deprecated and will be remove in the very near future, do not use this method anymore
  ListOrganizations: (req: arangodb_cloud_internal_resourcemanager_v1_ListAllOrganizationsRequest) => Promise<arangodb_cloud_resourcemanager_v1_OrganizationList>;
  
  // Fetch all organizations that match all of the given filters.
  // Required permissions:
  // - internal-dashboard.organization.list on the support organization.
  ListOrganizationInfos: (req: arangodb_cloud_internal_resourcemanager_v1_ListAllOrganizationsRequest) => Promise<arangodb_cloud_internal_resourcemanager_v1_OrganizationInfoList>;
  
  // Fetch the number of organizations that match all of the given filters.
  // Required permissions:
  // - internal-dashboard.organization.count globally.
  CountOrganizations: (req: arangodb_cloud_internal_resourcemanager_v1_ListAllOrganizationsRequest) => Promise<arangodb_cloud_internal_resourcemanager_v1_OrganizationCount>;
  
  // Fetch an organization by its id.
  // Required permissions:
  // - internal-dashboard.organization.get on the support organization.
  // This method is deprecated and will be remove in the very near future, do not use this method anymore
  GetOrganization: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_resourcemanager_v1_Organization>;
  
  // Update notification for a deployment
  // Required permissions:
  // - internal-dashboard.organization.update-notification
  UpdateOrganizationNotification: (req: arangodb_cloud_internal_resourcemanager_v1_UpdateOrganizationNotificationRequest) => Promise<void>;
  
  // Remove notification for a deployment
  // Required permissions:
  // - internal-dashboard.organization.update-notification
  RemoveOrganizationNotification: (req: arangodb_cloud_internal_resourcemanager_v1_RemoveOrganizationNotificationRequest) => Promise<void>;
  
  // Get a list of members of the organization identified by the given context ID.
  // Required permissions:
  // - internal-dashboard.organization.get-members on the support organization.
  ListOrganizationMembers: (req: arangodb_cloud_common_v1_ListOptions) => Promise<arangodb_cloud_resourcemanager_v1_MemberList>;
  
  // Fetch all projects of the organization identified by the given context ID.
  // Required permissions:
  // - internal-dashboard.project.list on the support organization.
  ListProjects: (req: arangodb_cloud_common_v1_ListOptions) => Promise<arangodb_cloud_resourcemanager_v1_ProjectList>;
  
  // Fetch a project by its id.
  // Required permissions:
  // - internal-dashboard.project.get on the support organization.
  GetProject: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_resourcemanager_v1_Project>;
  
  // Fetch all tier spefications known by the system.
  // Required permissions:
  // - internal-dashboard.tier.list on the support organization.
  ListTiers: (req: arangodb_cloud_common_v1_ListOptions) => Promise<TierList>;
  
  // Fetch all detailed tier spefications known by the system.
  // Required permissions:
  // - internal-dashboard.tier.list on the support organization.
  ListTierDetails: (req: arangodb_cloud_common_v1_ListOptions) => Promise<arangodb_cloud_internal_resourcemanager_v1_TierDetailsList>;
  
  // Set the tier of a given organization to the given tier ID.
  // Required permissions:
  // - internal-dashboard.organization.set-tier on the support organization.
  SetOrganizationTier: (req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationTierRequest) => Promise<void>;
  
  // Set the cost center of a given organization to the given value.
  // Required permissions:
  // - internal-dashboard.organization.set-cost-center on the support organization.
  SetOrganizationCostCenter: (req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationCostCenterRequest) => Promise<void>;
  
  // Fetch the entity that the organization with given ID has a contract with.
  // Required permissions:
  // - internal-resourcemanager.organization.get-entity on the support organization.
  GetOrganizationEntity: (req: arangodb_cloud_common_v1_IDOptions) => Promise<OrganizationEntityDetails>;
  
  // Set the entity that the given organization has a contract with to the given entity ID.
  // Required permissions:
  // - internal-dashboard.organization.set-entity on the support organization.
  SetOrganizationEntity: (req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationEntityRequest) => Promise<void>;
  
  // Fetch the support info for the organization with given ID.
  // Required permissions:
  // - internal-dashboard.organization.get-support-info on the support organization.
  GetOrganizationSupportInfo: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_resourcemanager_v1_OrganizationSupportInfo>;
  
  // Set the support information for the organization identified by given ID.
  // Required permissions:
  // - internal-dashboard.organization.set-support-info on the support organization..
  SetOrganizationSupportInfo: (req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationSupportInfoRequest) => Promise<void>;
  
  // Fetch the sales info for the organization with given ID.
  // Required permissions:
  // - internal-dashboard.organization.get-sales-info on the support organization.
  GetOrganizationSalesInfo: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_resourcemanager_v1_OrganizationSalesInfo>;
  
  // Set the sales information for the organization identified by given ID.
  // Required permissions:
  // - internal-dashboard.organization.set-sales-info on the support organization..
  SetOrganizationSalesInfo: (req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationSalesInfoRequest) => Promise<void>;
  
  // Fetch all UsageItem resources in the organization identified by the given
  // organization ID that match the given criteria.
  // Required permissions:
  // - internal-dashboard.usageitem.list on the support organization.
  ListUsageItems: (req: arangodb_cloud_usage_v1_ListUsageItemsRequest) => Promise<arangodb_cloud_usage_v1_UsageItemList>;
  
  // Fetch all UsageItemInfo resources that match the given criteria.
  // In contrast to the public ListUsageItems function, this function does not
  // require an organization ID.
  // Required permissions:
  // - internal-dashboard.usageitem.list globally
  ListAllUsageItemInfos: (req: arangodb_cloud_internal_usage_v1_ListAllUsageItemsRequest) => Promise<arangodb_cloud_internal_usage_v1_UsageItemInfoList>;
  
  // Fetch all pricing plan that match the given request.
  // Required permissions:
  // - internal-dashboard.pricingplan.list on the support organization.
  ListPlans: (req: arangodb_cloud_internal_pricing_v1_ListPlansRequest) => Promise<arangodb_cloud_internal_pricing_v1_PlanList>;
  
  // Fetch the pricing plan identified by the given ID.
  // Required permissions:
  // - internal-dashboard.pricingplan.get on the support organization.
  GetPlan: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_pricing_v1_Plan>;
  
  // Create a new pricing plan.
  // Required permissions:
  // - internal-dashboard.pricingplan.create on the support organization.
  CreatePlan: (req: arangodb_cloud_internal_pricing_v1_Plan) => Promise<arangodb_cloud_internal_pricing_v1_Plan>;
  
  // Update an existing pricing plan.
  // Note that a plan can only be updated while it is not locked.
  // Required permissions:
  // - internal-dashboard.pricingplan.update on the support organization.
  UpdatePlan: (req: arangodb_cloud_internal_pricing_v1_Plan) => Promise<arangodb_cloud_internal_pricing_v1_Plan>;
  
  // Delete an existing pricing plan.
  // Note that a plan can only be deleted while it is not locked.
  // Required permissions:
  // - internal-dashboard.pricingplan.delete on the support organization.
  DeletePlan: (req: arangodb_cloud_internal_pricing_v1_Plan) => Promise<void>;
  
  // Fetch all pricing plan assignments that match the given request.
  // Required permissions:
  // - internal-dashboard.pricingplanassignment.list on the support organization.
  ListPlanAssignments: (req: arangodb_cloud_internal_pricing_v1_ListPlanAssignmentsRequest) => Promise<arangodb_cloud_internal_pricing_v1_PlanAssignmentList>;
  
  // Fetch the pricing plan assignment identified by the given ID.
  // Required permissions:
  // - internal-dashboard.pricingplanassignment.get on the support organization.
  GetPlanAssignment: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_pricing_v1_PlanAssignment>;
  
  // Create a new pricing plan assignment.
  // Required permissions:
  // - internal-dashboard.pricingplanassignment.create on the support organization.
  CreatePlanAssignment: (req: arangodb_cloud_internal_pricing_v1_PlanAssignment) => Promise<arangodb_cloud_internal_pricing_v1_PlanAssignment>;
  
  // Update an existing pricing plan assignment.
  // Only the end date of an assignment can be changed.
  // Required permissions:
  // - internal-dashboard.pricingplanassignment.update on the support organization.
  UpdatePlanAssignment: (req: arangodb_cloud_internal_pricing_v1_PlanAssignment) => Promise<arangodb_cloud_internal_pricing_v1_PlanAssignment>;
  
  // Calculate the price for a specific usage of a resources.
  // Required permissions:
  // - internal-dashboard.price.calculate on the support organization.
  CalculatePrice: (req: arangodb_cloud_internal_pricing_v1_PriceRequest) => Promise<arangodb_cloud_internal_pricing_v1_PriceList>;
  
  // Get a list of quota values for the organization identified by the given context ID.
  // If a quota is not specified on organization level, a (potentially tier specific) default
  // value is returned.
  // Required permissions:
  // - internal-dashboard.organization.get on the support organization.
  ListOrganizationQuotas: (req: arangodb_cloud_resourcemanager_v1_ListQuotasRequest) => Promise<arangodb_cloud_resourcemanager_v1_QuotaList>;
  
  // Set a quota at organization level.
  // Required permissions:
  // - internal-dashboard.organization.set-quota on the support organization.
  SetOrganizationQuota: (req: arangodb_cloud_internal_resourcemanager_v1_SetQuotaRequest) => Promise<void>;
  
  // Get a list of quota values for the project identified by the given context ID.
  // If a quota is not specified on project level, a value from organization level
  // is returned.
  // Required permissions:
  // - internal-dashboard.project.get on the support organization.
  ListProjectQuotas: (req: arangodb_cloud_resourcemanager_v1_ListQuotasRequest) => Promise<arangodb_cloud_resourcemanager_v1_QuotaList>;
  
  // Set a quota at project level.
  // Required permissions:
  // - internal-dashboard.project.set-quota on the support organization.
  SetProjectQuota: (req: arangodb_cloud_internal_resourcemanager_v1_SetQuotaRequest) => Promise<void>;
  
  // Fetch all email patterns.
  // Required permissions:
  // - internal-dashboard.emailpattern.list on the support organization.
  ListEmailPatterns: (req: arangodb_cloud_internal_iam_v1_ListEmailPatternsRequest) => Promise<arangodb_cloud_internal_iam_v1_EmailPatternList>;
  
  // Fetch an email pattern by its id.
  // Required permissions:
  // - internal-dashboard.emailpattern.get on the support organization.
  GetEmailPattern: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_iam_v1_EmailPattern>;
  
  // Create a new email pattern
  // Required permissions:
  // - internal-dashboard.emailpattern.create on the support organization.
  CreateEmailPattern: (req: arangodb_cloud_internal_iam_v1_EmailPattern) => Promise<arangodb_cloud_internal_iam_v1_EmailPattern>;
  
  // Update an email pattern
  // Required permissions:
  // - internal-dashboard.emailpattern.update on the support organization.
  UpdateEmailPattern: (req: arangodb_cloud_internal_iam_v1_EmailPattern) => Promise<arangodb_cloud_internal_iam_v1_EmailPattern>;
  
  // Delete an email pattern
  // Required permissions:
  // - internal-dashboard.emailpattern.delete on the support organization.
  DeleteEmailPattern: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Fetch all public email provider domains.
  // Required permissions:
  // - internal-dashboard.publicemailproviderdomain.list on the support organization.
  ListPublicEmailProviderDomains: (req: arangodb_cloud_internal_iam_v1_ListPublicEmailProviderDomainsRequest) => Promise<arangodb_cloud_internal_iam_v1_PublicEmailProviderDomainList>;
  
  // Fetch an public email provider domain by its id.
  // Required permissions:
  // - internal-dashboard.publicemailproviderdomain.get on the support organization.
  GetPublicEmailProviderDomain: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_iam_v1_PublicEmailProviderDomain>;
  
  // Create a new public email provider domain
  // Required permissions:
  // - internal-dashboard.publicemailproviderdomain.create on the support organization.
  CreatePublicEmailProviderDomain: (req: arangodb_cloud_internal_iam_v1_PublicEmailProviderDomain) => Promise<arangodb_cloud_internal_iam_v1_PublicEmailProviderDomain>;
  
  // Update a public email provider domain
  // Required permissions:
  // - internal-dashboard.publicemailproviderdomain.update on the support organization.
  UpdatePublicEmailProviderDomain: (req: arangodb_cloud_internal_iam_v1_PublicEmailProviderDomain) => Promise<arangodb_cloud_internal_iam_v1_PublicEmailProviderDomain>;
  
  // Delete a public email provider domain
  // Required permissions:
  // - internal-dashboard.publicemailproviderdomain.delete on the support organization.
  DeletePublicEmailProviderDomain: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Get the actual status of the Controlplane
  // Required permissions:
  // - internal-dashboard.controlplanestatus.get on the support organization.
  GetControlplaneStatus: (req?: arangodb_cloud_common_v1_Empty) => Promise<ControlplaneStatus>;
  
  // Fetch all Invoice resources that match the given criteria.
  // Required permissions:
  // - internal-dashboard.invoice.list on the support organization.
  ListAllInvoices: (req: arangodb_cloud_internal_billing_v1_ListAllInvoicesRequest) => Promise<arangodb_cloud_billing_v1_InvoiceList>;
  
  // Fetch all Invoice resources that match the given criteria and return them as a csv file stream in Chunks
  // Required permissions:
  // - internal-dashboard.invoice.list on the support organization.
  ListAllInvoicesCSV: (req: arangodb_cloud_internal_billing_v1_ListAllInvoicesRequest) => Promise<void>;
  
  // Fetch statistics for all Invoice resources that match the given criteria.
  // Required permissions:
  // - internal-dashboard.invoice.get-statistics globally
  GetInvoiceStatistics: (req: arangodb_cloud_internal_billing_v1_ListAllInvoicesRequest) => Promise<arangodb_cloud_internal_billing_v1_InvoiceStatistics>;
  
  // Fetch the Invoice resources identified by the given ID.
  // Required permissions:
  // - internal-dashboard.invoice.get on the support organization.
  GetInvoice: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_billing_v1_Invoice>;
  
  // Fetch a preliminary Invoice identified by the given organization ID.
  // The preliminary invoice contains all costs from the last invoice (if any, otherwise the creation date of the organization) until last night (midnight UTC).
  // Required permissions:
  // - internal-dashboard.invoice.get-preliminary globally
  GetPreliminaryInvoice: (req: arangodb_cloud_billing_v1_GetPreliminaryInvoiceRequest) => Promise<arangodb_cloud_billing_v1_Invoice>;
  
  // Calculate the Invoice provided in items.
  // The result contain the correct totals (including VAT and Sales tax if applicable)
  // Note: This invoice is not saved at this point
  // Required permissions:
  // - internal-dashboard.invoice.calculate on the support organization.
  CalculateInvoice: (req: arangodb_cloud_billing_v1_Invoice) => Promise<arangodb_cloud_billing_v1_Invoice>;
  
  // Update the Invoice provided, this updates both the invoice itself as well as the items in the invoice.
  // Note: This invoice is expected to have the correct calculation, the invoice is saved 'as-is'.
  // Required permissions:
  // - internal-dashboard.invoice.update on the support organization.
  // Note that once a payment is added or the invoice is completed or rejected,
  // this function results in a PermissionDenied error.
  UpdateInvoice: (req: arangodb_cloud_billing_v1_Invoice) => Promise<arangodb_cloud_billing_v1_Invoice>;
  
  // Update the status & payments of an invoice.
  // Verification status is not updated.
  // Required permissions:
  // - internal-dashboard.invoice.update-status globally.
  // Note that once the invoice status is "completed",
  // this function results in a PermissionDenied error.
  UpdateInvoiceStatus: (req: arangodb_cloud_billing_v1_Invoice) => Promise<arangodb_cloud_billing_v1_Invoice>;
  
  // Mark the invoice identified by given ID as verified.
  // Required permissions:
  // - internal-dashboard.invoice.verify globally.
  VerifyInvoice: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Mark the invoice identified by given ID as needs rebuild.
  // Required permissions:
  // - internal-dashboard.invoice.rebuild globally.
  RebuildInvoice: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Mark the invoice identified by given ID as completed.
  // Required permissions:
  // - internal-dashboard.invoice.complete globally.
  // Note that once a payment is added or the invoice is completed or rejected,
  // this function results in a PermissionDenied error.
  CompleteInvoice: (req: arangodb_cloud_internal_billing_v1_CompleteInvoiceRequest) => Promise<void>;
  
  // Fetch all providers that are supported by the ArangoDB cloud.
  // Required permissions:
  // - internal-dashboard.currency.list on the support organization..
  ListCurrencies: (req: arangodb_cloud_common_v1_ListOptions) => Promise<arangodb_cloud_currency_v1_CurrencyList>;
  
  // Fetch a currency by its id.
  // Required permissions:
  // - internal-dashboard.currency.get on the support organization..
  GetCurrency: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_currency_v1_Currency>;
  
  // Fetch the default currency for a given (optional) organization.
  // Required permissions:
  // - internal-dashboard.currency.get-default on the support organization..
  GetDefaultCurrency: (req: arangodb_cloud_currency_v1_GetDefaultCurrencyRequest) => Promise<arangodb_cloud_currency_v1_Currency>;
  
  // Fetch the system wide default currency.
  // Required permissions:
  // - internal-dashboard.currency.get-default-system on the support organization..
  GetDefaultSystemCurrency: (req?: arangodb_cloud_common_v1_Empty) => Promise<arangodb_cloud_currency_v1_Currency>;
  
  // List all known conversion rates
  // Required permissions:
  // - internal-dashboard.conversionrate.list on the support organization..
  ListConversionRates: (req: arangodb_cloud_common_v1_ListOptions) => Promise<arangodb_cloud_internal_currency_v1_ConversionRateList>;
  
  // Set a conversion rate.
  // Required permissions:
  // - internal-dashboard.conversionrate.set on the support organization..
  SetConversionRate: (req: arangodb_cloud_internal_currency_v1_ConversionRate) => Promise<void>;
  
  // Convert a price from one currency to another.
  // Required permissions:
  // - internal-dashboard.currency.convert on the support organization..
  Convert: (req: arangodb_cloud_internal_currency_v1_ConvertRequest) => Promise<arangodb_cloud_internal_currency_v1_ConvertResponse>;
  
  // Get authentication statistics
  // Required permissions:
  // - internal-dashboard.statistics.get globally
  GetAuthenticationStatistics: (req?: arangodb_cloud_common_v1_Empty) => Promise<arangodb_cloud_internal_iam_v1_AuthenticationStatistics>;
  
  // Get organization statistics
  // Required permissions:
  // - internal-dashboard.statistics.get globally
  GetOrganizationStatistics: (req?: arangodb_cloud_common_v1_Empty) => Promise<arangodb_cloud_internal_resourcemanager_v1_OrganizationStatistics>;
  
  // Set the allowed deployment model flags of the organization identified
  // by the given context id.
  // Required permissions:
  // - internal-dashboard.organization.set-deployment-models globally.
  SetOrganizationDeploymentModels: (req: arangodb_cloud_internal_resourcemanager_v1_SetDeploymentModelsRequest) => Promise<void>;
  
  // Allow an organization to use custom images for ArangoDB deployments.
  // Required permissions:
  // - internal-dashboard.organization.set-allow-custom-images globally.
  SetOrganizationAllowedCustomImages: (req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationAllowedCustomImagesRequest) => Promise<void>;
  
  // Allow an organization to use IAM providers on deployments.
  // Required permissions:
  // - internal-dashboard.organization.set-allow-iamproviders globally.
  SetOrganizationAllowedIAMProviders: (req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationAllowedIAMProvidersRequest) => Promise<void>;
  
  // Allow an organization to use SCIM to manage IAM entities (like users).
  // Required permissions:
  // - internal-dashboard.organization.set-allow-scim globally.
  SetOrganizationAllowedSCIM: (req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationAllowedSCIMRequest) => Promise<void>;
  
  // Set the allowed deployment model flags of the project identified
  // by the given context id.
  // - internal-dashboard.project.set-deployment-models globally.
  SetProjectDeploymentModels: (req: arangodb_cloud_internal_resourcemanager_v1_SetDeploymentModelsRequest) => Promise<void>;
  
  // Fetch all deployment models available for deployments.
  // Required permissions:
  // - internal-dashboard.deploymentmodel.list globally
  ListAllDeploymentModels: (req?: arangodb_cloud_common_v1_Empty) => Promise<arangodb_cloud_data_v1_DeploymentModelList>;
  
  // Fetch all node sizes available for deployments.
  // Required permissions:
  // - internal-dashboard.nodesize.list globally
  ListAllNodeSizes: (req?: arangodb_cloud_common_v1_Empty) => Promise<arangodb_cloud_data_v1_NodeSizeList>;
  
  // Fetch the node sizes available for deployments
  // owned by the project with given ID, created in the given region with given ID.
  // If project ID "all" is used, then all node sizes for the region with given
  // ID are returned.
  // Required permissions:
  // - internal-dashboard.nodesize.list globally
  ListNodeSizes: (req: arangodb_cloud_data_v1_NodeSizesRequest) => Promise<arangodb_cloud_data_v1_NodeSizeList>;
  
  // Get all details of a node size with given ID.
  // Required permissions:
  // - internal-dashboard.nodesize.get globally
  GetNodeSizeDetails: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_data_v1_NodeSizeDetails>;
  
  // Fetch a specific version of the Terms & Conditions.
  // Required permissions:
  // - internal-dashboard.termsandconditions.get globally.
  GetTermsAndConditions: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_resourcemanager_v1_TermsAndConditions>;
  
  // List all terms & conditions.
  // Required permissions:
  // - internal-dashboard.termsandconditions.list globally.
  ListTermsAndConditions: (req: arangodb_cloud_common_v1_ListOptions) => Promise<arangodb_cloud_internal_resourcemanager_v1_TermsAndConditionsInfoList>;
  
  // Create a new set of terms & conditions.
  // Required permissions:
  // - internal-dashboard.termsandconditions.create globally.
  CreateTermsAndConditions: (req: arangodb_cloud_resourcemanager_v1_TermsAndConditions) => Promise<arangodb_cloud_resourcemanager_v1_TermsAndConditions>;
  
  // Update a set of terms & conditions.
  // Once terms & conditions have been marked as current, they can no longer be updated.
  // Required permissions:
  // - internal-dashboard.termsandconditions.update globally.
  UpdateTermsAndConditions: (req: arangodb_cloud_resourcemanager_v1_TermsAndConditions) => Promise<arangodb_cloud_resourcemanager_v1_TermsAndConditions>;
  
  // Make the terms & conditions with given ID the current one.
  // Required permissions:
  // - internal-dashboard.termsandconditions.set-current globally.
  SetCurrentTermsAndConditions: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Fetch a specific version of the data processing addendum.
  // Required permissions:
  // - internal-dashboard.dataprocessingaddendum.get globally.
  GetDataProcessingAddendum: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_resourcemanager_v1_DataProcessingAddendum>;
  
  // List all data processing addendums.
  // Required permissions:
  // - internal-dashboard.dataprocessingaddendum.list globally.
  ListDataProcessingAddendums: (req: arangodb_cloud_common_v1_ListOptions) => Promise<arangodb_cloud_internal_resourcemanager_v1_DataProcessingAddendumInfoList>;
  
  // Create a new data processing addendum.
  // Required permissions:
  // - internal-dashboard.dataprocessingaddendum.create globally.
  CreateDataProcessingAddendum: (req: arangodb_cloud_resourcemanager_v1_DataProcessingAddendum) => Promise<arangodb_cloud_resourcemanager_v1_DataProcessingAddendum>;
  
  // Update a data processing addendum.
  // Once a data processing addendum has been marked as current, they can no longer be updated.
  // Required permissions:
  // - internal-dashboard.dataprocessingaddendum.update globally.
  UpdateDataProcessingAddendum: (req: arangodb_cloud_resourcemanager_v1_DataProcessingAddendum) => Promise<arangodb_cloud_resourcemanager_v1_DataProcessingAddendum>;
  
  // Make the data processing addendum with given ID the current one.
  // Required permissions:
  // - internal-dashboard.dataprocessingaddendum.set-current globally.
  SetCurrentDataProcessingAddendum: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Fetch the limits of a region, identified by given ID.
  // Required permissions:
  // - internal-dashboard.regionlimits.get globally
  GetRegionLimits: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_platform_v1_RegionLimits>;
  
  // Set the limits of a region identified by given ID.
  // Required permissions:
  // - internal-dashboard.regionlimits.set globally
  SetRegionLimits: (req: arangodb_cloud_internal_platform_v1_RegionLimits) => Promise<void>;
  
  // Fetch a region's details by its id.
  // Required permissions:
  // - internal-dashboard.regiondetails.get globally
  GetRegionDetails: (req: arangodb_cloud_common_v1_IDOptions) => Promise<RegionDetails>;
  
  // Check if the organization with given ID is excluded from billing.
  // Required permissions:
  // - internal-dashboard.organization.get-excluded-from-billing globally.
  GetOrganizationExcludedFromBilling: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_common_v1_YesOrNo>;
  
  // Set that the organization with given ID is excluded (or not) from billing.
  // Required permissions:
  // - internal-dashboard.organization.manage-excluded-from-billing globally.
  SetOrganizationExcludedFromBilling: (req: arangodb_cloud_internal_billing_v1_SetOrganizationExcludedFromBillingRequest) => Promise<void>;
  
  // Check if the organization with given ID is configured to use purchase
  // orders for billing (instead of online payments).
  // Required permissions:
  // - internal-dashboard.organization.get-uses-purchase-orders globally.
  GetOrganizationUsesPurchaseOrders: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_common_v1_YesOrNo>;
  
  // Set that the organization with given ID is configured to use purchase orders
  // for billing (instead of online payments).
  // Required permissions:
  // - internal-dashboard.organization.manage-uses-purchase-orders globally.
  SetOrganizationUsesPurchaseOrders: (req: arangodb_cloud_internal_billing_v1_SetOrganizationUsesPurchaseOrdersRequest) => Promise<void>;
  
  // Fetch the billing configuration of an organization identified by the given ID.
  // Required permissions:
  // - internal-dashboard.organization.get-billing-config globally
  GetBillingConfig: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_billing_v1_BillingConfig>;
  
  // Update the billing address of the organization specified by the organization ID.
  // Required permissions:
  // - internal-dashboard.organization.update-billing-address globally
  UpdateBillingAddress: (req: UpdateBillingAddressRequest) => Promise<void>;
  
  // Fetch all payment methods that are configured for the organization identified
  // by the given context ID.
  // Required permissions:
  // - internal-dashboard.paymentmethod.list globally.
  ListPaymentMethods: (req: arangodb_cloud_billing_v1_ListPaymentMethodsRequest) => Promise<arangodb_cloud_billing_v1_PaymentMethodList>;
  
  // Fetch all ArangoDB versions that are available.
  // Required permissions:
  // - internal-dashboard.version.list globally
  ListAllVersions: (req: arangodb_cloud_common_v1_ListOptions) => Promise<arangodb_cloud_data_v1_VersionList>;
  
  // RunTests will execute tests based on a provided Filter.
  // Required permissions:
  // - internal-dashboard.tests.run
  RunTests: (req: arangodb_cloud_internal_integrationtests_v1_RunTestsRequest) => Promise<arangodb_cloud_internal_integrationtests_v1_TestRun>;
  
  // ListTestRuns returns all known test runs.
  // Required permissions:
  // - internal-dashboard.testruns.list
  ListTestRuns: (req: arangodb_cloud_internal_integrationtests_v1_ListTestRunsRequest) => Promise<arangodb_cloud_internal_integrationtests_v1_TestRunList>;
  
  // Delete a test run from the recorded list of test runs.
  // This will fail if the test is still running.
  // Required permissions:
  // - internal-dashboard.testruns.delete
  DeleteTestRun: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // GetTestStatus returns the status information about the currently executing tests.
  // Required permissions:
  // - internal-dashboard.tests.get-status
  GetTestStatus: (req: arangodb_cloud_internal_integrationtests_v1_GetStatusRequest) => Promise<arangodb_cloud_internal_integrationtests_v1_TestResults>;
  
  // Abort will stop all currently running tests. To get an update about the status of the
  // tests after aborting them, call GetTestStatus.
  // Required permissions:
  // - internal-dashboard.tests.abort
  AbortTests: (req: arangodb_cloud_internal_integrationtests_v1_AbortRequest) => Promise<void>;
  
  // GetTestCases will return all available test cases which can be executed.
  // Required permissions:
  // - internal-dashboard.tests.get-tests
  GetTestCases: (req?: arangodb_cloud_common_v1_Empty) => Promise<arangodb_cloud_internal_integrationtests_v1_TestList>;
  
  // Fetch all events that match all of the given filters.
  // Required permissions:
  // - internal-dashboard.event.list globally.
  // Note that this uses a POST request. ListEventOptions has repeated fields,
  // which does not work in GET requests.
  ListAllEvents: (req: arangodb_cloud_resourcemanager_v1_ListEventOptions) => Promise<arangodb_cloud_resourcemanager_v1_EventList>;
  
  // Get a specific uploaded document
  // Required permissions:
  // - internal-dashboard.uploaddocument.get globally.
  GetUploadedDocument: (req: arangodb_cloud_internal_resourcemanager_v1_GetUploadedDocumentRequest) => Promise<arangodb_cloud_internal_resourcemanager_v1_UploadedDocument>;
  
  // List all uploaded documents.
  // Required permissions:
  // - internal-dashboard.uploaddocument.list globally.
  ListUploadedDocuments: (req: arangodb_cloud_internal_resourcemanager_v1_ListUploadedDocumentsRequest) => Promise<arangodb_cloud_internal_resourcemanager_v1_UploadedDocumentList>;
  
  // Upload a document
  // Required permissions:
  // - internal-dashboard.uploaddocument.create globally.
  UploadDocument: (req: arangodb_cloud_internal_resourcemanager_v1_UploadedDocument) => Promise<arangodb_cloud_internal_resourcemanager_v1_UploadedDocument>;
  
  // Fetch all support plan that match the given request.
  // Required permissions:
  // - internal-dashboard.supportplan.list globally.
  ListSupportPlans: (req: arangodb_cloud_internal_support_v1_ListAllPlansRequest) => Promise<arangodb_cloud_support_v1_PlanList>;
  
  // Submit an support request for the customer.
  // Required permissions:
  // - internal-support.supportrequests.create-request-for-customer
  CreateSupportRequestForCustomer: (req: arangodb_cloud_internal_support_v1_SupportRequestCustomer) => Promise<arangodb_cloud_internal_support_v1_SupportRequestCustomer>;
  
  // Fetch all backup policies for a specific deployment.
  // Required permissions:
  // - internal-dashboard.backuppolicy.list globally.
  ListBackupPolicies: (req: arangodb_cloud_backup_v1_ListBackupPoliciesRequest) => Promise<arangodb_cloud_internal_backup_v1_BackupPolicyInfoList>;
  
  // Update the allow inconsistent flag of a backup policy
  // Required permissions:
  // - internal-dashboard.backuppolicy.update-allow-inconsistent globally
  UpdateBackupPolicyAllowInconsistent: (req: arangodb_cloud_internal_backup_v1_UpdateBackupPolicyAllowInconsistentRequest) => Promise<void>;
  
  // Update the upload incremental flag of a backup policy
  // Required permissions:
  // - internal-dashboard.backuppolicyinfo.update-upload-incremental on the backup policy
  UpdateBackupPolicyUploadIncremental: (req: arangodb_cloud_internal_backup_v1_UpdateBackupPolicyUploadIncrementalRequest) => Promise<void>;
  
  // Fetch all backups for a specific deployment.
  // Required permissions:
  // - internal-dashboard.backup.list globally.
  ListBackups: (req: arangodb_cloud_internal_backup_v1_ListBackupInfosRequest) => Promise<arangodb_cloud_internal_backup_v1_BackupInfoList>;
  
  // Create a new manual backup
  // Setting the backup_policy_id field in the backup is not allowed
  // Required permissions:
  // -  internal-dashboard.backup.create on the deployment that owns the backup and is identified by the given ID in backupInfo.backup.deployment_id.
  CreateBackup: (req: CreateBackupRequest) => Promise<arangodb_cloud_internal_backup_v1_BackupInfo>;
  
  // Update a backup
  // Required permissions:
  // -  internal-dashboard.backup.update on the backup identified by the given ID.
  UpdateBackup: (req: arangodb_cloud_backup_v1_Backup) => Promise<void>;
  
  // Download a backup identified by the given ID from remote storage to the volumes of the servers of the deployment.
  // This operation can only be executed on backups which have the same number of DB Servers in the backup and the current running cluster.
  // If this backup was already downloaded, another download will be done.
  // If the backup is still available on the cluster there is no need to explicitly download the backup before restoring.
  // This function will return immediately.
  // To track status, please invoke GetBackup and check the .status field inside the returned backup object
  // Required permissions:
  // -  internal-dashboard.backup.download on the backup identified by the given ID.
  DownloadBackup: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Restore (or recover) a backup identified by the given ID
  // This operation can only be executed on backups where status.available is set and
  // the mayor and minor version of the backup and the current running cluster are the same.
  // This function will return immediately.
  // To track status, please invoke GetDeployment on the data API and check the
  // .status.restoring_backup and .status.restore_backup_status fields inside the returned deployment object
  // Required permissions:
  // -  internal-dashboard.backup.restore on the backup identified by the given ID.
  RestoreBackup: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Delete a backup identified by the given ID, after which removal of any remote storage of the backup is started.
  // Note that the backup are initially only marked for deletion.
  // Once all remote storage for the backup has been removed, the backup itself is removed.
  // Required permissions:
  // -  internal-dashboard.backup.delete on the backup identified by the given ID.
  DeleteBackup: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Takes a backup and creates a deployment from it. For all intents and purposes this new deployment
  // will be the same as the deployment at that exact moment when the backup was taken from it. This means that
  // the new deployment will be in the same project and use the same provider as the old deployment did. Optionally
  // a different region can be provided using the region id field on the request. Furthermore, the new deployment
  // will have the same server settings ( count, mode, replication factor ) as the old deployment did at the time
  // of taking the backup. After the new deployment successfully started, the backup will be used to restore the
  // data into the new deployment. The new deployment will have a different endpoint, and the password will also
  // be reset for it. All other user settings will remain the same.
  // The old deployment will not be touched.
  // Required permissions:
  // - internal-dashboard.deployment.clone-from-backup globally
  CloneDeploymentFromBackup: (req: CloneDeploymentFromBackupRequest) => Promise<arangodb_cloud_data_v1_Deployment>;
  
  // Resumes a paused deployment identified by the given id.
  // When ResumeDeployment is invoked on a deployment that has is_paused not set, an PreconditionFailed error is returned.
  // Required permissions:
  // - internal-dashboard.deployment.resume globally
  ResumeDeployment: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Fetch IAM provider limits for the organization identified by given ID.
  // If no limits are found for the identified organization, a default (empty) set of
  // limits is returned.
  // Required permissions:
  // - internal-dashboard.organization.get-iamprovider-limits globally.
  GetOrganizationIAMProviderLimits: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_security_v1_OrganizationIAMProviderLimits>;
  
  // Set IAM provider limits for the organization identified by given ID.
  // Required permissions:
  // - internal-dashboard.organization.set-iamprovider-limits globally.
  SetOrganizationIAMProviderLimits: (req: arangodb_cloud_internal_security_v1_OrganizationIAMProviderLimits) => Promise<void>;
  
  // Update the replication settings of a deployment.
  // Required permissions:
  // - internal-dashboard.deployment.update-replication on the deployment
  UpdateDeploymentReplication: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentReplicationRequest) => Promise<void>;
  
  // Update the support plan for a deployment identified with given ID.
  // Required permissions:
  // - internal-dashboard.deployment.update-support-plan on the deployment
  UpdateDeploymentSupportPlan: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentSupportPlanRequest) => Promise<void>;
  
  // Update the CPU ratio between coordinator and DB server.
  // Required permissions:
  // - internal-dashboard.deployment.update-cpu-ratio on the deployment
  UpdateDeploymentCPURatio: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentCPURatioRequest) => Promise<void>;
  
  // Update the memory ratio between coordinator and DB server.
  // Required permissions:
  // - internal-dashboard.deployment.update-memory-ratio on the deployment
  UpdateDeploymentMemoryRatio: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentMemoryRatioRequest) => Promise<void>;
  
  // Update the CPU factor (0.5-1.0) between deployment and k8s resources.
  // This is a factor between the CPU defined in the deployment (ultimatily coming from node-size) and the limit and request as provided to k8s resources.
  // Required permissions:
  // - internal-dashboard.deployment.update-cpu-factors on the deployment
  UpdateDeploymentCPUFactors: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentCPUFactorsRequest) => Promise<void>;
  
  // Update the memory factor (0.5-1.0) between deployment and k8s resources.
  // This is a factor between the memory defined in the deployment (coming from node-size) and the limit and request as provided to k8s resources.
  // Required permissions:
  // - internal-dashboard.deployment.update-memory-factors on the deployment
  UpdateDeploymentMemoryFactors: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentMemoryFactorsRequest) => Promise<void>;
  
  // StartDCUpdate will start execute updates (if needed).
  // Required permissions:
  // - internal-dashboard.dcupdate.start globally
  StartDCUpdate: (req: arangodb_cloud_internal_dcupdate_v1_StartRequest) => Promise<arangodb_cloud_internal_dcupdate_v1_UpdateStatus>;
  
  // GetDCUpdateStatus returns the status of the current update process.
  // Required permissions:
  // - internal-dashboard.dcupdate.get-status globally
  GetDCUpdateStatus: (req?: arangodb_cloud_common_v1_Empty) => Promise<arangodb_cloud_internal_dcupdate_v1_UpdateStatus>;
  
  // StopDCUpdate will stop the currently running update process (if any).
  // Required permissions:
  // - internal-dashboard.dcupdate.stop globally
  StopDCUpdate: (req: arangodb_cloud_internal_dcupdate_v1_StopRequest) => Promise<void>;
  
  // Set the custom image filter for the organization identified by given ID.
  // Required permissions:
  // - internal-dashboard.organization.set-custom-image-filter globally.
  SetOrganizationCustomImageFilter: (req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationCustomImageFilterRequest) => Promise<void>;
  
  // Fetch all organization infos that match all of the given filters.
  // Required permissions:
  // - internal-dashboard.organization.list on the support organization.
  ListAllOrganizationInfos: (req: arangodb_cloud_internal_resourcemanager_v1_ListAllOrganizationsRequest) => Promise<arangodb_cloud_internal_resourcemanager_v1_OrganizationInfoList>;
  
  // Fetch an organization info by id.
  // Required permissions:
  // - internal-dashboard.organization.get on the support organization.
  GetOrganizationInfo: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_resourcemanager_v1_OrganizationInfo>;
  
  // Change the log level of the deployment identified by given ID.
  // Required permissions:
  // - internal-dashboard.deployment.set-log-level on the deployment identified by the given deployment ID.
  SetDeploymentLogLevel: (req: arangodb_cloud_internal_monitoring_v1_SetDeploymentLogLevelRequest) => Promise<void>;
  
  // Start a debug container in the namespace of the deployment identified by given ID.
  // Required permissions:
  // - internal-dashboard.deployment.debug on the deployment identified by the given deployment ID.
  DebugCluster: (req: arangodb_cloud_internal_monitoring_v1_DebugClusterRequest) => Promise<arangodb_cloud_internal_monitoring_v1_DebugClusterInfo>;
  
  // Rotate a server for a deployment. Takes a deployment id and a server id and tells the operator
  // to cycle out the pod which belongs to that server.
  // Required permissions:
  // - internal-dashboard.deployment.rotate-server on the deployment identified by the given deployment ID.
  RotateDeploymentServer: (req: arangodb_cloud_data_v1_RotateDeploymentServerRequest) => Promise<void>;
  
  // Rotate a server for a deployment with force. Takes a deployment id and a server id and tells the operator
  // to cycle out the pod which belongs to that server.
  // Required permissions:
  // - internal-dashboard.deployment.rotate-server-with-force on the deployment identified by the given deployment ID.
  RotateDeploymentServerWithForce: (req: arangodb_cloud_data_v1_RotateDeploymentServerRequest) => Promise<void>;
  
  // RebalanceDeploymentShards rebalances shards for deployment across the DB servers.
  // Required permissions:
  // - internal-dashboard.deployment.rebalance-shards on the deployment identified by the given request.
  RebalanceDeploymentShards: (req: arangodb_cloud_data_v1_RebalanceDeploymentShardsRequest) => Promise<void>;
  
  // Get the revenue that should be accounted for in a given period.
  // Required permissions:
  // - internal-dashboard.revenue.get globally
  GetRevenue: (req: arangodb_cloud_internal_reporting_v1_GetRevenueRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_reporting_v1_Revenue>) => void) => Promise<IServerStream>;
  
  // Get the recurring revenue for in a given period.
  // Recurring revenue is counting fixed price elements only.
  // Required permissions:
  // - internal-dashboard.revenue.get globally
  GetRecurringRevenue: (req: arangodb_cloud_internal_reporting_v1_GetRevenueRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_reporting_v1_Revenue>) => void) => Promise<IServerStream>;
  
  // Get the recurring revenue by organization in a given period.
  // Recurring revenue is counting fixed price elements only.
  // One revenue item per organizations is returned.
  // Required permissions:
  // - internal-dashboard.revenue.get globally
  GetRecurringRevenueByOrganization: (req: arangodb_cloud_internal_reporting_v1_GetRecurringRevenueByOrganizationRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_reporting_v1_RevenueByOrganization>) => void) => Promise<IServerStream>;
  
  // Get the costs of deployments running in a given period.
  // Note that only 1 cost item is returned, but this can take a while.
  // Required permissions:
  // - internal-dashboard.deployments-cost.get globally
  GetDeploymentsCost: (req: arangodb_cloud_internal_reporting_v1_GetDeploymentsCostRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_reporting_v1_DeploymentsCost>) => void) => Promise<IServerStream>;
  
  // Get the number of changes in deployments in a given period.
  // Note that only 1 item is returned, but this can take a while.
  // Required permissions:
  // - internal-dashboard.deployment-changes.get globally
  GetDeploymentChanges: (req: arangodb_cloud_internal_reporting_v1_GetDeploymentChangesRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_reporting_v1_DeploymentChanges>) => void) => Promise<IServerStream>;
  
  // Get the number of changes in customers in a given period.
  // Note that only 1 item is returned, but this can take a while.
  // Required permissions:
  // - internal-dashboard.customer-changes.get globally
  GetCustomerChanges: (req: arangodb_cloud_internal_reporting_v1_GetCustomerChangesRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_reporting_v1_CustomerChanges>) => void) => Promise<IServerStream>;
  
  // GetAPICompatibility returns the API compatibility of the current controlplane.
  // Required permissions:
  // - internal-dashboard.apicompat.get globally
  GetAPICompatibility: (req: arangodb_cloud_internal_apicompat_v1_CompatibilityRequest) => Promise<arangodb_cloud_internal_apicompat_v1_APICompatibility>;
  
  // Fetch the cluster-health of the deployment identified by given ID.
  // Required permissions:
  // - internal-dashboard.deployment.get-cluster-health on the deployment identified by the given deployment ID.
  GetClusterHealth: (req: arangodb_cloud_internal_monitoring_v1_GetClusterHealthRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_monitoring_v1_Chunk>) => void) => Promise<IServerStream>;
  
  // Fetch the agency state of the deployment identified by given ID.
  // Required permissions:
  // - internal-dashboard.deployment.get-agency-state on the deployment identified by the given deployment ID.
  GetAgencyState: (req: arangodb_cloud_internal_monitoring_v1_GetAgencyStateRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_monitoring_v1_Chunk>) => void) => Promise<IServerStream>;
  
  // Fetch the agency state of the deployment identified by given ID.
  // Required permissions:
  // - internal-dashboard.deployment.analyse on the deployment identified by the given deployment ID.
  AnalyseCluster: (req: arangodb_cloud_internal_monitoring_v1_AnalyseClusterRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_monitoring_v1_Chunk>) => void) => Promise<IServerStream>;
  
  // Fetch the agency dump of the deployment identified by given ID.
  // Required permissions:
  // - internal-dashboard.deployment.get-agency-dump on the deployment identified by the given deployment ID.
  GetAgencyDump: (req: arangodb_cloud_internal_monitoring_v1_GetAgencyDumpRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_monitoring_v1_Chunk>) => void) => Promise<IServerStream>;
  
  // Detect silent out-of-sync bug in the deployment identified by given ID.
  // Required permissions:
  // - internal-dashboard.deployment.analyse globally.
  DetectSilentOutOfSync: (req: arangodb_cloud_internal_monitoring_v1_DetectSilentOutOfSyncRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_monitoring_v1_Chunk>) => void) => Promise<IServerStream>;
  
  // GetAllServiceDescriptors returns the all service descriptors retrieved by this service to inspect.
  // Required permissions:
  // -  internal-dashboard.apicompat.get-service-descriptors globally
  GetAllServiceDescriptors: (req: arangodb_cloud_internal_apicompat_v1_AllServiceDescriptorsRequest) => Promise<arangodb_cloud_internal_apicompat_v1_AllServiceDescriptors>;
  
  // Send verification email for user identified by given ID.
  // Required permissions:
  // - internal-dashboard.user.send-verification-email globally.
  SendVerificationEmail: (req: arangodb_cloud_internal_iam_v1_SendVerificationEmailRequest) => Promise<void>;
  
  // Fetch all deployment jobs that match the given filter.
  // Required permissions:
  // - internal-dashboard.deploymentjob.list on the deployment that owns the deployment job and is identified by the given (non-empty) ID.
  // - internal-dashboard.deploymentjob.list globally in case no deployment identifier is given.
  ListDeploymentJobs: (req: arangodb_cloud_internal_job_v1_ListDeploymentJobsRequest) => Promise<arangodb_cloud_internal_job_v1_DeploymentJobList>;
  
  // Set the flag that determines if members of the organization are allowed
  // to request Kubernetes access to their deployments.
  // Required permissions:
  // - internal-dashboard.organization.set-kubernetes-access globally.
  SetOrganizationKubernetesAccess: (req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationKubernetesAccessRequest) => Promise<void>;
  
  // Set the datacluster label filter for the organization identified by given ID.
  // Required permissions:
  // - internal-dashboard.organization.set-datacluster-label-filter globally.
  SetOrganizationDataclusterLabelFilter: (req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationDataclusterLabelFilterRequest) => Promise<void>;
  
  // Set the flag that determines if the organization has signed a
  // Business Associate Agreement with ArangoDB.
  // Required permissions:
  // - internal-dashboard.organization.set-business-associate-agreement-signed globally.
  SetOrganizationBusinessAssociateAgreementSigned: (req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationBusinessAssociateAgreementSignedRequest) => Promise<void>;
  
  // ReDeleteDeployment will trigger a delete operation on an already deleting deployment.
  // Required permissions:
  // - internal-dashboard.deployment.redelete on the deployment identified by the given deployment ID.
  ReDeleteDeployment: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // RequestKubernetesAccess is used to request credentials and configuration
  // for accessing Kubernetes resources inside ArangoGraph Insights Platform.
  // Required permissions:
  // - internal-dashboard.datacluster.connect If the request if for datacluster wide access.
  // - internal-dashboard.deployment.connect If the request if for access to a specific deployment only.
  RequestKubernetesAccess: (req: arangodb_cloud_internal_kubeaccess_v1_KubernetesAccessRequest) => Promise<arangodb_cloud_internal_kubeaccess_v1_KubernetesAccessResponse>;
  
  // Get usage statistics for example installations in a given period.
  // Note that only 1 item is returned, but this can take a while.
  // Required permissions:
  // - internal-dashboard.example-installations.get globally
  GetExampleInstallations: (req: arangodb_cloud_internal_reporting_v1_GetExampleInstallationsRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_reporting_v1_ExampleInstallations>) => void) => Promise<IServerStream>;
  
  // Fetch all example datasets.
  // Required permissions:
  // - internal-dashboard.exampledataset.list-all globally
  ListAllExampleDatasets: (req: arangodb_cloud_example_v1_ListExampleDatasetsRequest) => Promise<arangodb_cloud_example_v1_ExampleDatasetList>;
  
  // Fetch all installations for a specific deployment.
  // Required permissions:
  // - internal-dashboard.exampledatasetinstallation.list on the deployment that owns the installation and is identified by the given ID.
  ListExampleDatasetInstallations: (req: arangodb_cloud_example_v1_ListExampleDatasetInstallationsRequest) => Promise<arangodb_cloud_example_v1_ExampleDatasetInstallationList>;
  
  // Fetch all audit logs in the organization identified by the given ID.
  // Required permissions:
  // - internal-dashboard.auditlog.list on the organization identified by the given ID.
  ListAuditLogs: (req: arangodb_cloud_audit_v1_ListAuditLogsRequest) => Promise<arangodb_cloud_audit_v1_AuditLogList>;
  
  // Fetch a specific AuditLog identified by the given ID.
  // Required permissions:
  // - internal-dashboard.auditlog.get on the audit log identified by the given ID.
  GetAuditLog: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_audit_v1_AuditLog>;
  
  // Delete an audit log.
  // Note that audit logs are initially only marked for deleted.
  // Once all their resources are removed the audit log itself is deleted
  // and cannot be restored.
  // Note that deleting an AuditLog will detach it from all Projects that
  // it was attached to.
  // Required permissions:
  // - internal-dashboard.auditlog.delete on the audit log.
  DeleteAuditLog: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Fetch all audit log archive infos in the audit log identified by the given ID.
  // Required permissions:
  // - internal-dashboard.auditlogarchiveinfo.list on the audit log identified by the given ID.
  ListAuditLogArchiveInfos: (req: arangodb_cloud_internal_audit_v1_ListAuditLogArchiveInfosRequest) => Promise<arangodb_cloud_internal_audit_v1_AuditLogArchiveInfoList>;
  
  // Fetch a specific audit log archive info identified by the given ID.
  // Required permissions:
  // - internal-dashboard.auditlogarchiveinfo.get on the audit log archive identified by the given ID.
  GetAuditLogArchiveInfo: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_audit_v1_AuditLogArchiveInfo>;
  
  // Delete an audit log archive.
  // Note that this method will return a precondition-failed error
  // if there is a non-deleted deployment using this archive.
  // Note that audit log archives are initially only marked for deleted.
  // Once all their resources are removed the audit log archive itself is deleted
  // and cannot be restored.
  // Required permissions:
  // - internal-dashboard.auditlogarchive.delete on the audit log archive.
  DeleteAuditLogArchive: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Fetch all audit log archive chunks in the audit log archive identified by the given ID.
  // Required permissions:
  // - internal-dashboard.auditlogarchivechunk.list on the audit log archive identified by the given ID.
  ListAuditLogArchiveChunks: (req: arangodb_cloud_internal_audit_v1_ListAuditLogArchiveChunksRequest) => Promise<arangodb_cloud_internal_audit_v1_AuditLogArchiveChunkList>;
  
  // Fetch a specific AuditLog identified by the given ID.
  // Required permissions:
  // - internal-dashboard.auditlogarchivechunk.get on the audit log archive chunk identified by the given ID.
  GetAuditLogArchiveChunk: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_audit_v1_AuditLogArchiveChunk>;
  
  // Fetch a specific AuditLogArchive identified by the given ID.
  // Required permissions:
  // - internal-dashboard.auditlogarchive.get on the audit log archive identified by the given ID.
  GetAuditLogArchive: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_audit_v1_AuditLogArchive>;
  
  // Fetch all audit events that match the given filter.
  // Note that this method will return a precondition-failed error
  // if there is no destination of type "cloud" in the AuditLog.
  // Required permissions:
  // - internal-dashboard.auditlogevents.get on the audit log identified by the given ID.
  GetAuditLogEvents: (req: arangodb_cloud_audit_v1_GetAuditLogEventsRequest, cb: (obj: IStreamMessage<arangodb_cloud_audit_v1_AuditLogEventList>) => void) => Promise<IServerStream>;
  
  // Fetch the AuditLog that is attached to the project identified by the given ID.
  // If no AuditLog is attached to the project, a not-found error is returned.
  // Required permissions:
  // - internal-dashboard.auditlogattachment.get on the project identified by the given ID.
  GetAuditLogAttachedToProject: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_audit_v1_AuditLog>;
  
  // Fetch the feature flags for the given context.
  // Required permissions:
  // - internal-dashboard.featureflag.get globally
  GetFeatureFlags: (req: arangodb_cloud_internal_feature_v1_GetFeatureFlagsRequest) => Promise<arangodb_cloud_internal_feature_v1_FeatureFlags>;
  
  // Update the feature flags for the given context.
  // Required permissions:
  // - internal-dashboard.featureflag.update globally
  UpdateFeatureFlags: (req: arangodb_cloud_internal_feature_v1_UpdateFeatureFlagsRequest) => Promise<void>;
  
  // Update the factor that is used to calculate the memory & CPU resources
  // for the agency of a deployment.
  // - internal-dashboard.deployment.update-agency-resources-factor globally
  UpdateDeploymentAgencyResourcesFactor: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentAgencyResourcesFactorRequest) => Promise<void>;
  
  // Update the communication method used within the deployment.
  // - internal-dashboard.deployment.update-communication-method on the deployment
  UpdateDeploymentCommunicationMethod: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentCommunicationMethodRequest) => Promise<void>;
  
  // Fetch all load balancer types (optional filtered for the provided deployment ID).
  // Required permissions:
  // - internal-dashboard.loadbalancertype.list globally
  ListLoadBalancerTypes: (req: arangodb_cloud_internal_network_v1_ListLoadBalancerTypesRequest) => Promise<arangodb_cloud_internal_network_v1_LoadBalancerTypeList>;
  
  // Update the loadbalancer type for the provided deployment ID.
  // This starts the process to move to the desired type without any downtime, so it can take some time before the update is ready.
  // Required permissions:
  // - internal-dashboard.loadbalancertype.update-deployment globally
  UpdateLoadBalancerTypeForDeployment: (req: arangodb_cloud_internal_network_v1_UpdateLoadBalancerTypeForDeploymentRequest) => Promise<void>;
  
  // Fetch all load balancers with matching deployment or datacluster ID.
  // Required permissions:
  // - internal-dashboard.loadbalancer.list globally
  ListLoadBalancers: (req: arangodb_cloud_internal_network_v1_ListLoadBalancersRequest) => Promise<arangodb_cloud_internal_network_v1_LoadBalancerList>;
  
  // Update the load balancer.
  // This will not update the status field.
  // Required permissions:
  // - internal-dashboard.loadbalancer.update globally
  UpdateLoadBalancer: (req: arangodb_cloud_internal_network_v1_LoadBalancer) => Promise<void>;
  
  // Set dedicated load balancer pods memory requests and limits.
  // Required permissions:
  // - internal-dashboard.loadbalancer.update on the load balancer
  SetLoadBalancerMemoryOverride: (req: arangodb_cloud_internal_network_v1_SetLoadBalancerMemoryOverrideRequest) => Promise<void>;
  
  // Set shared load balancer replicas override.
  // Required permissions:
  // - internal-dashboard.loadbalancer.update on the load balancer
  SetLoadBalancerReplicasOverride: (req: arangodb_cloud_internal_network_v1_SetLoadBalancerReplicasOverrideRequest) => Promise<void>;
  
  // Fetch all load balancer assignments for matching deployment and/or load balancer ID.
  // Required permissions:
  // - internal-dashboard.loadbalancerassignment.list globally
  ListLoadBalancerAssignments: (req: arangodb_cloud_internal_network_v1_ListLoadBalancerAssignmentsRequest) => Promise<arangodb_cloud_internal_network_v1_LoadBalancerAssignmentList>;
  
  // List all email messages that match the given request.
  // Messages are ordered by creation date (recent to old).
  // Required permissions:
  // - internal-dashboard.emailmessage.list globally.
  ListEmailMessages: (req: arangodb_cloud_internal_email_v1_ListEmailMessagesRequest) => Promise<arangodb_cloud_internal_email_v1_EmailMessageList>;
  
  // Set the flag that determines if the organization requires prepaid deployments to create deployments
  // Required permissions:
  // - internal-resourcemanager.organization.set-requires-prepaid-deployments globally.
  SetOrganizationRequiresPrepaidDeployments: (req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationRequiresPrepaidDeploymentsRequest) => Promise<void>;
  
  // ListAllPrepaidDeployments fetches a list of all prepaid deployments that match a given filter.
  // Required permissions:
  // - internal-dashboard.prepaiddeployment.list globally.
  ListAllPrepaidDeployments: (req: arangodb_cloud_internal_prepaid_v1_ListAllPrepaidDeploymentsRequest) => Promise<arangodb_cloud_internal_prepaid_v1_PrepaidDeploymentInfoList>;
  
  // GetPrepaidDeploymentInfo gets PrepaidDeploymentInfo by id.
  // Required permissions:
  // - internal-dashboard.prepaiddeployment.get-info globally.
  GetPrepaidDeploymentInfo: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_prepaid_v1_PrepaidDeploymentInfo>;
  
  // CreatePrepaidDeployment creates a new PrepaidDeployment for given organization.
  // Required permissions:
  // - internal-dashboard.prepaiddeployment.create globally.
  CreatePrepaidDeployment: (req: arangodb_cloud_prepaid_v1_PrepaidDeployment) => Promise<arangodb_cloud_internal_prepaid_v1_PrepaidDeploymentInfo>;
  
  // UpdatePrepaidDeployment updated PrepaidDeployment definition.
  // Required permissions:
  // - internal-dashboard.prepaiddeployment.update globally.
  UpdatePrepaidDeployment: (req: arangodb_cloud_prepaid_v1_PrepaidDeployment) => Promise<void>;
  
  // DeletePrepaidDeployment deletes a PrepaidDeployment and attached Deployment.
  // Required permissions:
  // - internal-dashboard.prepaiddeployment.delete globally.
  DeletePrepaidDeployment: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // AttachDeploymentToPrepaidDeployment attaches existing Deployment to PrepaidDeployment.
  // Required permissions:
  // - internal-dashboard.prepaiddeployment.attach globally.
  AttachDeploymentToPrepaidDeployment: (req: arangodb_cloud_internal_prepaid_v1_AttachDeploymentToPrepaidDeploymentRequest) => Promise<void>;
  
  // DetachDeploymentFromPrepaidDeployment detaches Deployment from PrepaidDeployments.
  // Required permissions:
  // - internal-dashboard.prepaiddeployment.detach globally.
  DetachDeploymentFromPrepaidDeployment: (req: arangodb_cloud_internal_prepaid_v1_DetachDeploymentFromPrepaidDeploymentRequest) => Promise<void>;
  
  // GetPrepaidDeploymentForDeployment gets PrepaidDeployment for existing Deployment.
  // Required permissions:
  // - internal-dashboard.prepaiddeployment.get-for-deployment globally.
  GetPrepaidDeploymentForDeployment: (req: arangodb_cloud_internal_prepaid_v1_GetPrepaidDeploymentForDeploymentRequest) => Promise<arangodb_cloud_internal_prepaid_v1_PrepaidDeploymentInfo>;
  
  // Fetch all changes that match the given request.
  // Required permissions:
  // - internal-dashboard.change.list globally.
  ListChanges: (req: arangodb_cloud_internal_changelog_v1_ListChangesRequest) => Promise<arangodb_cloud_internal_changelog_v1_ChangeList>;
  
  // Get the URL of the uploaded document with the name provided as Id in the internal dashboard.
  // Required permissions:
  // - internal-dashboard.url.get
  GetUploadedDocumentURL: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_dashboardurl_v1_URL>;
  
  // Fetch a private endpoint service info by the deployment ID.
  // Required permissions:
  // - internal-dashboard.privateendpointservice.get-by-deployment-id globally.
  GetPrivateEndpointServiceInfoByDeploymentID: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_network_v1_PrivateEndpointServiceInfo>;
  
  // Delete a private endpoint service identified by the given ID.
  // Note that the delete isn't available in the public API, because we do not want that the switch towards public endpoint can be done there.
  // Note that the private endpoint service is initially only marked for deletion.
  // Once all their resources are removed the private endpoint service itself is removed.
  // Required permissions:
  // - internal-dashboard.privateendpointservice.delete globally.
  DeletePrivateEndpointService: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Fetch all email addresses for the deployment identified by given ID.
  // Required permissions:
  // - internal-dashboard.deployment.get-email-addresses globally.
  GetDeploymentEmailAddresses: (req: arangodb_cloud_internal_data_v1_GetDeploymentEmailAddressesRequest) => Promise<arangodb_cloud_internal_data_v1_DeploymentEmailAddresses>;
  
  // Update deployment's components memory reserves
  // - internal-dashboard.deployment.update-memory-reserves globally.
  UpdateDeploymentMemoryReserves: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentMemoryReservesRequest) => Promise<void>;
  
  // Update deployment topology awareness
  // - internal-dashboard.deployment.update-topology-awareness globally.
  UpdateDeploymentTopologyAwareness: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentTopologyAwarenessRequest) => Promise<void>;
  
  // Update deployment's disk size
  // Required permissions:
  // - internal-dashboard.deployment.update-disk-size on the deployment
  // - internal-dashboard.deployment.update-disk-size-ignore-maximum-size on the deployment (if ignore_maximum_size is set)
  UpdateDeploymentDiskSize: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentDiskSizeRequest) => Promise<void>;
  
  // Update deployment's volume infos (like type, iops and throughput)
  // - internal-dashboard.deployment.update-volume-infos on the deployment
  UpdateDeploymentVolumeInfos: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentVolumeInfosRequest) => Promise<void>;
  
  // Update the maintenance mode of a deployment's operator
  // Required permissions:
  // - internal-dashboard.deployment.update-operator-maintenance-mode on the deployment
  UpdateDeploymentOperatorMaintenanceMode: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentMaintenanceModeRequest) => Promise<void>;
  
  // Update the maintenance mode of a deployment's agancy
  // Required permissions:
  // - internal-dashboard.deployment.update-agency-maintenance-mode on the deployment
  UpdateDeploymentAgencyMaintenanceMode: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentMaintenanceModeRequest) => Promise<void>;
  
  // Remove one or more finalizers from the deployment identified by the given context ID.
  // If this removes all finalizers from the deployment and the deployment is marked for deletion, it will be removed.
  // Required permissions:
  // - internal-dashboard.deployment.manage on the deployment.
  RemoveDeploymentFinalizers: (req: arangodb_cloud_internal_common_v1_FinalizerRequest) => Promise<void>;
  
  // Remove one or more finalizers from the data cluster identified by the given context ID.
  // If this removes all finalizers from the data cluster and the data cluster is marked for deletion, it will be removed.
  // Required permissions:
  // - internal-dashboard.datacluster.manage globally.
  RemoveDataClusterFinalizers: (req: arangodb_cloud_internal_common_v1_FinalizerRequest) => Promise<void>;
  
  // Remove one or more finalizers from the backup identified by the given context ID.
  // If this removes all finalizers from the backup and the backup is marked for deletion, it will be removed.
  // Required permissions:
  // - internal-dashboard.backup.manage on the backup.
  RemoveBackupFinalizers: (req: arangodb_cloud_internal_common_v1_FinalizerRequest) => Promise<void>;
  
  // Remove one or more finalizers from the backup policy identified by the given context ID.
  // If this removes all finalizers from the backup policy and the backup policy is marked for deletion, it will be removed.
  // Required permissions:
  // - internal-dashboard.backuppolicy.manage on the backup policy.
  RemoveBackupPolicyFinalizers: (req: arangodb_cloud_internal_common_v1_FinalizerRequest) => Promise<void>;
  
  // CreateComment creates a new comment for given resource.
  // Required permissions:
  // - internal-dashboard.comment.create globally.
  CreateComment: (req: arangodb_cloud_internal_comment_v1_CreateCommentRequest) => Promise<arangodb_cloud_internal_comment_v1_Comment>;
  
  // ListComments lists all comments for given resource.
  // Required permissions:
  // - internal-dashboard.comment.list globally.
  ListComments: (req: arangodb_cloud_internal_comment_v1_ListCommentsRequest) => Promise<arangodb_cloud_internal_comment_v1_CommentList>;
  
  // Set custom organization invoice threshold on the organization in default currency
  // Requires permissions:
  // - internal-dashboard.organization.set-custom-invoice-threshold
  SetOrganizationCustomInvoiceThreshold: (req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationCustomInvoiceThresholdRequest) => Promise<void>;
  
  // Fetch all metrics token in the deployment identified by the given deployment ID.
  // Required permissions:
  // - internal-dashboard.metricstoken.list on the deployment identified by the given deployment ID
  ListMetricsTokens: (req: arangodb_cloud_metrics_v1_ListTokensRequest) => Promise<arangodb_cloud_metrics_v1_TokenList>;
  
  // Lists disk performances that match all of the given filters.
  // Required permissions:
  // - internal-dashboard.diskperformance.list globally
  ListAllDiskPerformances: (req: arangodb_cloud_internal_data_v1_ListAllDiskPerformancesRequest) => Promise<arangodb_cloud_data_v1_DiskPerformanceList>;
  
  // Update the deployment suppress alerts settings for given deployment identifier.
  // Required permissions:
  // - internal-dashboard.deployment.update-suppress-alerts on the deployment
  UpdateDeploymentSuppressAlerts: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentSuppressAlertsRequest) => Promise<void>;
  
  // Set the flag, that determines if organization has private data clusters for its deployment or not
  // Requires permissions:
  // - internal-dashboard.organization.set-has-private-data-clusters
  SetOrganizationHasPrivateDataClusters: (req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationHasPrivateDataClustersRequest) => Promise<void>;
  
  // Set technical account manager on the organization
  // Required permissions:
  // - internal-dashboard.organization.set-technical-account-manager
  SetOrganizationTechnicalAccountManager: (req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationTechnicalAccountManagerRequest) => Promise<void>;
  
  // Set automatic invoice verification on the organization
  // Required permissions:
  // - internal-dashboard.organization.set-automatic-invoice-verification
  SetOrganizationAutomaticInvoiceVerification: (req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationAutomaticInvoiceVerificationRequest) => Promise<void>;
  
  // Set internal contacts on the organization
  // Required permissions:
  // - internal-dashboard.organization.set-internal-contacts on the provided organization ID.
  SetOrganizationInternalContacts: (req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationInternalContactsRequest) => Promise<void>;
  
  // Fetch all InvoiceInfo resources that match the given criteria.
  // Required permissions:
  // - internal-dashboard.invoice.list globally
  ListAllInvoiceInfos: (req: arangodb_cloud_internal_billing_v1_ListAllInvoicesRequest) => Promise<arangodb_cloud_internal_billing_v1_InvoiceInfoList>;
  
  // Sets non_collectible flag on given InvoiceInfo
  // Required permissions:
  // - internal-dashboard.invoice.set-non-collectible globally.
  SetInvoiceNonCollectible: (req: arangodb_cloud_internal_billing_v1_SetInvoiceNonCollectibleRequest) => Promise<void>;
  
  // Get InvoiceInfo for Invoice identified by id
  // Required permissions:
  // - internal-dashboard.invoice.get globally
  GetInvoiceInfo: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_billing_v1_InvoiceInfo>;
  
  // Update the deployment shard rebalancer settings for given deployment identifier.
  // RequiredPermissions:
  // - internal-dashboard.deployment.update-use-shard-rebalancer on the deployment
  UpdateDeploymentUseShardRebalancer: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentUseShardRebalancerRequest) => Promise<void>;
  
  // Fetch the kubernetes resource of a deployment identified by given ID.
  // Required permissions:
  // - internal-dashboard.deployment.get-kubernetes-resource on the deployment identified by the given deployment ID.
  GetKubernetesResource: (req: arangodb_cloud_internal_monitoring_v1_GetKubernetesResourceRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_monitoring_v1_Chunk>) => void) => Promise<IServerStream>;
  
  // Update the deployment kubernetes resources patches
  // RequiredPermissions:
  // - internal-dashboard.deployment.update-kubernetes-resource-patches on the deployment
  UpdateKubernetesResourcesPatches: (req: arangodb_cloud_internal_data_v1_UpdateKubernetesResourcesPatchesRequest) => Promise<void>;
  
  // Create a new credit.
  // Required permissions:
  // - internal-dashboard.credit.create globally
  CreateCredit: (req: arangodb_cloud_internal_billing_v1_Credit) => Promise<arangodb_cloud_internal_billing_v1_Credit>;
  
  // Discard uncommited credit usage
  // Required permissions:
  // - internal-dashboard.credit.discard-usage globally
  DiscardUncommitedCreditUsage: (req: arangodb_cloud_internal_billing_v1_DiscardUncommitedCreditUsageRequest) => Promise<void>;
  
  // Mark credit as exhausted
  // Required permissions:
  // - internal-dashboard.credit.set-exhausted globally
  SetCreditExhausted: (req: arangodb_cloud_internal_billing_v1_SetCreditExhaustedRequest) => Promise<void>;
  
  // List credits matching requested criteria
  // Required permissions:
  // - internal-dashboard.credit.list globally
  ListCredit: (req: arangodb_cloud_internal_billing_v1_ListCreditRequest) => Promise<arangodb_cloud_internal_billing_v1_CreditList>;
  
  // List credit usages matching requested criteria
  // Required permissions:
  // - internal-dashboard.credit.list-usage globally
  ListCreditUsage: (req: arangodb_cloud_internal_billing_v1_ListCreditUsageRequest) => Promise<arangodb_cloud_internal_billing_v1_CreditUsageList>;
  
  // Mark the invoice identified by given ID as canceled.
  // Required permissions:
  // - internal-dashboard.invoice.cancel globally.
  CancelInvoice: (req: arangodb_cloud_internal_billing_v1_CancelInvoiceRequest) => Promise<void>;
  
  // Trigger the (async) creation of a report.
  // The contents of the report are derived from request flags.
  // The report which will be delivered by email.
  // Required permissions:
  // - internal-dashboard.report.create globally
  CreateReport: (req: arangodb_cloud_internal_reporting_v1_CreateReportRequest) => Promise<arangodb_cloud_internal_reporting_v1_CreateReportResponse>;
  
  // Copy a backup manually from source backup to a given region identifier.
  // It is not allowed to copy backup that does not have upload flag set to true
  // Required permissions:
  // - internal-dashboard.backup.copy on the backup identified by the given ID.
  CopyBackup: (req: arangodb_cloud_backup_v1_CopyBackupRequest) => Promise<arangodb_cloud_backup_v1_Backup>;
  
  // Fetch all entities spefications known by the system.
  // Required permissions:
  // - internal-dashboard.entity.list globally
  ListEntities: (req: arangodb_cloud_common_v1_ListOptions) => Promise<arangodb_cloud_internal_resourcemanager_v1_EntityDetailsList>;
  
  // Delete a deployment by its id.
  // Required permissions:
  // - internal-dashboard.deployment.delete globally.
  DeleteDeployment: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Get an existing DeploymentReplication using its deployment ID
  // Required permissions:
  // - internal-dashboard.deploymentreplication.get
  GetMigrationDeploymentReplication: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_replication_v1_DeploymentReplication>;
  
  // Update an existing DeploymentReplication spec. If does not exist, this will create a new one.
  // This call expects the complete entity with the updated fields.
  // Required permissions:
  // - internal-dashboard.deploymentreplication.update
  UpdateMigrationDeploymentReplication: (req: arangodb_cloud_replication_v1_DeploymentReplication) => Promise<arangodb_cloud_replication_v1_DeploymentReplication>;
  
  // List NotebookInfos given the optional filter(s).
  // Required permissions:
  // - internal-dashboard.notebook.list globally
  ListNotebookInfos: (req: arangodb_cloud_internal_notebook_v1_ListNotebookInfosRequest) => Promise<arangodb_cloud_internal_notebook_v1_NotebookInfoList>;
  
  // Set a custom image for notebook specified by the Notebook ID.
  // Required permissions:
  // - internal-dashboard.notebook.set-custom-image on the notebook
  SetCustomNotebookImage: (req: arangodb_cloud_internal_notebook_v1_SetCustomNotebookImageRequest) => Promise<void>;
  
  // Set a custom notebook image that should be used when creating Notebooks for this deployment.
  // Required permissions:
  // - internal-dashboard.deployment.set-custom-notebook-image
  SetDeploymentCustomNotebookImage: (req: arangodb_cloud_internal_data_v1_SetCustomNotebookImageRequest) => Promise<void>;
  
  // Get the revenue that should be accounted for in a given period.
  // Required permissions:
  // - internal-dashboard.revenue.get globally
  CreateInvoicesZIP: (req: arangodb_cloud_internal_billing_v1_ListAllInvoicesRequest) => Promise<void>;
  
  // Set the additional command line arguments for a kube-arangodb
  // Required permissions:
  // - internal-dashboard.deployment.set-kube-arangodb-additional-command-line-arguments on the deployment
  SetKubeArangodbAdditionalCommandLineArguments: (req: arangodb_cloud_internal_data_v1_SetKubeArangodbAdditionalCommandLineArgumentsRequest) => Promise<void>;
  
  // List DeploymentInfos given the optional filter(s).
  // Required permissions:
  // - internal-dashboard.deploymentprofile.list globally
  ListDeploymentProfileInfos: (req: arangodb_cloud_internal_deploymentprofile_v1_ListDeploymentProfileInfosRequest) => Promise<arangodb_cloud_internal_deploymentprofile_v1_DeploymentProfileInfoList>;
  
  // GetDeploymentProfileInfo retrieves a deployment profile info by its ID.
  // Required permissions:
  // - internal-dashboard.deploymentprofile.get
  GetDeploymentProfileInfo: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_deploymentprofile_v1_DeploymentProfileInfo>;
  
  // CreateDeploymentProfile creates a new deployment profile by specifying its configuration.
  // Required permissions:
  // - internal-dashboard.deploymentprofile.create
  CreateDeploymentProfile: (req: arangodb_cloud_internal_deploymentprofile_v1_DeploymentProfileInfo) => Promise<arangodb_cloud_internal_deploymentprofile_v1_DeploymentProfileInfo>;
  
  // UpdateDeploymentProfile updates an existing deployment profile info.
  // Required permissions:
  // - internal-dashboard.deploymentprofile.update
  UpdateDeploymentProfile: (req: arangodb_cloud_internal_deploymentprofile_v1_DeploymentProfileInfo) => Promise<void>;
  
  // DeleteDeploymentProfile removes the deployment profile by specifying its ID.
  // Required permissions:
  // - internal-dashboard.deploymentprofile.delete
  DeleteDeploymentProfile: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Create a new deployment update job.
  // Returns the newly created UpdateJob.
  // Required permissions:
  // - internal-dashboard.deploymentupdatejob.create
  CreateDeploymentUpdateJob: (req: arangodb_cloud_internal_deploymentupdater_v1_UpdateJob) => Promise<arangodb_cloud_internal_deploymentupdater_v1_UpdateJob>;
  
  // Get an existing deployment update job. Return error if not found.
  // Required permissions:
  // - internal-dashboard.deploymentupdatejob.get
  GetDeploymentUpdateJob: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_deploymentupdater_v1_UpdateJob>;
  
  // Update an existing deployment update job.
  // This call expects the complete entity with the updated fields.
  // Returns the updated UpdateJob.
  // Required permissions:
  // - internal-dashboard.deploymentupdatejob.update
  UpdateDeploymentUpdateJob: (req: arangodb_cloud_internal_deploymentupdater_v1_UpdateJob) => Promise<arangodb_cloud_internal_deploymentupdater_v1_UpdateJob>;
  
  // Start an existing deployment update job.
  // Returns the updated UpdateJob.
  // Does nothing if UpdateJob already started.
  // Required permissions:
  // - internal-dashboard.deploymentupdatejob.start
  StartDeploymentUpdateJob: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_deploymentupdater_v1_UpdateJob>;
  
  // Stop an existing deployment update job which is running.
  // Returns the updated UpdateJob.
  // Does nothing if UpdateJob already stopped.
  // Required permissions:
  // - internal-dashboard.deploymentupdatejob.stop
  StopDeploymentUpdateJob: (req: arangodb_cloud_internal_deploymentupdater_v1_UpdateJobStateChangeRequest) => Promise<arangodb_cloud_internal_deploymentupdater_v1_UpdateJob>;
  
  // Pause an existing deployment update job. If already paused, does nothing.
  // Returns the updated UpdateJob.
  // Does nothing if UpdateJob already paused.
  // Required permissions:
  // - internal-dashboard.deploymentupdatejob.pause
  PauseDeploymentUpdateJob: (req: arangodb_cloud_internal_deploymentupdater_v1_UpdateJobStateChangeRequest) => Promise<arangodb_cloud_internal_deploymentupdater_v1_UpdateJob>;
  
  // Resume an existing deployment update job. If already running, does nothing.
  // Returns the updated UpdateJob.
  // Does nothing if UpdateJob already running.
  // Required permissions:
  // - internal-dashboard.deploymentupdatejob.resume
  ResumeDeploymentUpdateJob: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_deploymentupdater_v1_UpdateJob>;
  
  // Delete an existing deployment update job. If does not exist, returns an error.
  // Required permissions:
  // - internal-dashboard.deploymentupdatejob.delete
  DeleteDeploymentUpdateJob: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // List deployment update jobs.
  // Required permissions:
  // - internal-dashboard.deploymentupdatejob.list
  ListDeploymentUpdateJobs: (req: arangodb_cloud_internal_deploymentupdater_v1_ListUpdateJobsRequest) => Promise<arangodb_cloud_internal_deploymentupdater_v1_UpdateJobList>;
  
  // List all available scheduling policies
  // Required permissions:
  // - internal-dashboard.schedulingpolicy.list
  ListAllSchedulingPolicies: (req: arangodb_cloud_internal_scheduling_v1_ListAllSchedulingPoliciesRequest) => Promise<arangodb_cloud_internal_scheduling_v1_SchedulingPolicyList>;
  
  // Get a SchedulingPolicy object identified by id provided in request
  // Required permissions:
  // - internal-dashboard.schedulingpolicy.get
  GetSchedulingPolicy: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_scheduling_v1_SchedulingPolicy>;
  
  // Set scheduling policies for deployment
  // Required permission:
  // - internal-dashboard.deployment.set-scheduling-policies on the deployment
  SetDeploymentSchedulingPolicies: (req: arangodb_cloud_internal_data_v1_SetDeploymentSchedulingPoliciesRequest) => Promise<void>;
  
  // List detailed information about all notebook models
  // Required permissions:
  // - internal-dashboard.notebookmodel.list
  ListAllNotebookModelInfos: (req: arangodb_cloud_internal_notebook_v1_ListAllNotebookModelsRequest) => Promise<arangodb_cloud_internal_notebook_v1_NotebookModelInfoList>;
  
  // Get detailed information about notebook model
  // Required permissions:
  // - internal-dashboard.notebookmodel.get
  GetNotebookModelInfo: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_notebook_v1_NotebookModelInfo>;
  
  // Set scheduling policies for given notebook identified by notebook_id
  // Required permissions:
  // - internal-dashboard.notebookmodel.set-scheduling-policies
  SetNotebookSchedulingPolicies: (req: arangodb_cloud_internal_notebook_v1_SetSchedulingPoliciesRequest) => Promise<void>;
  
  // Update the private endpoint service info, including fields which cannot be modified with the public API
  // (like the AKS subscriptions ID once the servvice has made a connection)
  // This will not update the status field, for updating the status, please use UpdatePrivateEndpointServiceStatus.
  // Required permissions:
  // - internal-dashboard.privateendpointservice.update
  UpdatePrivateEndpointServiceInfo: (req: arangodb_cloud_internal_network_v1_PrivateEndpointServiceInfo) => Promise<void>;
  
  // ListNodePools returns node pools associated with given data cluster
  // Required permission:
  // - internal-dashboard.nodepool.list globally
  ListNodePools: (req: arangodb_cloud_internal_data_v1_ListNodePoolsRequest) => Promise<arangodb_cloud_internal_data_v1_NodePoolList>;
  
  // ScaleNodePool sets requested nodes value for given node pool
  // RequiredPermissions:
  // - internal-dashboard.nodepool.scale globally
  ScaleNodePool: (req: arangodb_cloud_internal_data_v1_ScaleNodePoolRequest) => Promise<void>;
  
  // Set whenever scheduling policies propagation from node size is enabled for given deployment
  // Required permission:
  // - internal-dashboard.deployment.set-scheduling-policies-enabled on the deployment
  SetDeploymentSchedulingPoliciesEnabled: (req: arangodb_cloud_internal_data_v1_SetDeploymentSchedulingPoliciesEnabledRequest) => Promise<void>;
  
  // Get MLServicesInfo by its deployment id.
  // Required permissions:
  // - internal-dashboard.mlservices.get on the provided deployment ID
  GetMLServicesInfo: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_ml_v1_MLServicesInfo>;
  
  // Set if ML service is enabled for given deployment.
  // Required permissions:
  // - internal-dashboard.mlservices.set-ml-service-enabled on the MLServices.
  SetMLServiceEnabled: (req: arangodb_cloud_ml_v1_MLServices) => Promise<void>;
  
  // Set if ML workloads can run on GPU machines.
  // Required permissions:
  // - internal-dashboard.mlservices.set-allow-gpu-workloads globally.
  SetAllowGPUWorkloads: (req: arangodb_cloud_internal_ml_v1_SetAllowGPUWorkloadsRequest) => Promise<void>;
  
  // Set the resource limits (and requests) on ML jobs.
  // Note: This will set new limits on all types of jobs (prediction, training, etc.)
  // Required permission:
  // - internal-dashboard.mlservices.set-jobs-resource-limits globally.
  SetMLJobsResourceLimits: (req: arangodb_cloud_internal_ml_v1_SetJobsResourceLimitsRequest) => Promise<void>;
  
  // Set if an organization is allowed to be a delinquent payer.
  // Required permissions:
  // - internal-dashboard.organization.set-allow-delinquent-payer on the provided organization ID.
  SetOrganizationAllowDelinquentPayer: (req: arangodb_cloud_internal_resourcemanager_v1_SetAllowOrganizationDelinquentPayerRequest) => Promise<void>;
  
  // Set if an organization is allowed to make custom CPU settings.
  // Required permissions:
  // - internal-dashboard.organization.set-disable-custom-cpu globally.
  SetOrganizationDisableCustomCPU: (req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationCustomSettingsRequest) => Promise<void>;
  
  // Set if an organization is allowed to make custom disk settings.
  // Required permissions:
  // - internal-dashboard.organization.set-disable-custom-disk globally.
  SetOrganizationDisableCustomDisk: (req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationCustomSettingsRequest) => Promise<void>;
  
  // Set whenever public backups should be disabled/enabled.
  // Required permission:
  // - internal-dashboard.deployment.set-public-backup-disabled on the deployment
  SetDeploymentPublicBackupsDisabled: (req: arangodb_cloud_internal_data_v1_SetDeploymentPublicBackupsDisabledRequest) => Promise<void>;
  
  // Create a new credit bundle.
  // Required permissions:
  // - internal-dashboard.creditbundle.create globally.
  CreateCreditBundleInfo: (req: arangodb_cloud_internal_credits_v1_CreditBundleInfo) => Promise<arangodb_cloud_internal_credits_v1_CreditBundleInfo>;
  
  // Update an existing credit bundle.
  // This operation is not allowed if the specified credit bundle is used.
  // Required permisisons:
  // - internal-dashboard.creditbundle.update globally.
  UpdateCreditBundle: (req: arangodb_cloud_credits_v1_CreditBundle) => Promise<void>;
  
  // Delete an existing credit bundle specified by the ID.
  // This operation is not allowed if the specified credit bundle is used.
  // Required permisisons:
  // - internal-dashboard.creditbundle.delete globally.
  DeleteCreditBundle: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Lock a credit bundle specified by the ID.
  // Returns an error if a bundle is already locked.
  // Required permisisons:
  // - internal-dashboard.creditbundle.set-lock globally.
  LockCreditBundle: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Unlock a credit bundle specified by the ID.
  // Returns an error if a bundle is already unlocked.
  // Required permisisons:
  // - internal-dashboard.creditbundle.set-unlock globally.
  UnlockCreditBundle: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // List all credit bundles based on the request.
  // Required permisions:
  // - internal-dashboard.creditbundle.list globally.
  ListAllCreditBundleInfos: (req: arangodb_cloud_internal_credits_v1_ListAllCreditBundlesRequest) => Promise<arangodb_cloud_internal_credits_v1_CreditBundleInfoList>;
  
  // Get the amount of credit debt for an organization identified by the id.
  // Required permissions:
  // - internal-dashboard.creditdebt.get globally.
  GetCreditDebt: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_credits_v1_CreditDebt>;
  
  // Set if an organization must use credit bundles.
  // Required permissions:
  // - internal-dashboard.organization.set-uses-credit-bundles globally.
  SetOrganizationUsesCreditBundles: (req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationUsesCreditBundlesRequest) => Promise<void>;
  
  // Enable/disable envoy access log for deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-envoy-access-log globally.
  UpdateDeploymentEnvoyAccessLog: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentEnvoyAccessLogRequest) => Promise<void>;
  
  // StartDataClusterOptimization starts optimization process of DataCluster
  // Required permissions:
  // - internal-dashboard.datacluster.start-optimization globally.
  StartDataClusterOptimization: (req: arangodb_cloud_internal_datacluster_optimize_v1_StartDataClusterOptimizationRequest) => Promise<arangodb_cloud_internal_datacluster_optimize_v1_DataClusterOptimizationStatus>;
  
  // StopDataClusterOptimization stops optimization process of DataCluster
  // Required permissions:
  // - internal-dashboard.datacluster.stop-optimization globally.
  StopDataClusterOptimization: (req: arangodb_cloud_internal_datacluster_optimize_v1_StopDataClusterOptimizationRequest) => Promise<void>;
  
  // GetDataClusterOptimizationStatus gets optimization process status of DataCluster
  // Required permissions:
  // - internal-dashboard.datacluster.get-optimization-status globally.
  GetDataClusterOptimizationStatus: (req: arangodb_cloud_internal_datacluster_optimize_v1_GetDataClusterOptimizationStatusRequest) => Promise<arangodb_cloud_internal_datacluster_optimize_v1_DataClusterOptimizationStatus>;
  
  // Fetch all core dumps identified by the given filter.
  // Required permissions:
  // - internal-dashboard.coredump.list globally.
  ListCoreDumps: (req: arangodb_cloud_internal_coredump_v1_ListCoreDumpsRequest) => Promise<arangodb_cloud_internal_coredump_v1_CoreDumpList>;
  
  // Fetch the core dump (metadata) with the given ID.
  // Required permissions:
  // - internal-dashboard.coredump.get globally.
  GetCoreDump: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_coredump_v1_CoreDump>;
  
  // Fetch the unencrypted content of a core dump identified by given ID.
  // Required permissions:
  // - internal-dashboard.coredump.get-content globally.
  GetCoreDumpContent: (req: arangodb_cloud_common_v1_IDOptions) => Promise<void>;
  
  // Update a core dump status.
  // Required permissions:
  // - internal-dashboard.coredump.update-status globally.
  UpdateCoreDumpStatus: (req: arangodb_cloud_internal_coredump_v1_CoreDump) => Promise<void>;
  
  // Set a Notebook to maintenance mode.
  // Enabling maintenance mode on a notebook will prevent its state from being reconciled.
  // Required permissions:
  // - internal-dashboard.notebook.set-maintenance-mode
  SetNotebookMaintenanceMode: (req: arangodb_cloud_internal_notebook_v1_SetNotebookMaintenanceModeRequest) => Promise<void>;
  
  // Set the node update concurrency level for a data cluster
  // Required permissions:
  // - internal-dashboard.datacluster.update-node-update-concurrency-level
  UpdateDataClusterNodeUpdateConcurrencyLevel: (req: arangodb_cloud_internal_data_v1_UpdateDataClusterNodeUpdateConcurrencyLevelRequest) => Promise<void>;
  
  // Set the deployment to use jetstream for auditlogs
  // Required permissions:
  // - internal-dashboard.deployment.update-use-jetstream-for-auditlogs
  UpdateDeploymentToUseJetStreamForAuditLogs: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentToUseJetStreamForAuditLogsRequest) => Promise<void>;
  
  // Update notification for a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-notification
  UpdateDeploymentNotification: (req: arangodb_cloud_internal_data_v1_UpdateDeploymentNotificationRequest) => Promise<void>;
  
  // Remove notification for a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-notification
  RemoveDeploymentNotification: (req: arangodb_cloud_internal_data_v1_RemoveDeploymentNotificationRequest) => Promise<void>;
  
  // Set deployment log levels
  // Required permissions:
  // - internal-dashboard.deployment.set-members-log-level
  SetDeploymentLogLevels: (req: arangodb_cloud_internal_data_v1_SetMemberLogLevelRequest) => Promise<void>;
  
  // Get default log levels
  // ID arg can be omitted
  // Required permissions:
  // - internal-dashboard.deployment.get-default-log-levels
  GetDefaultLogLevels: (req: arangodb_cloud_common_v1_IDOptions) => Promise<arangodb_cloud_internal_data_v1_LogLevelList>;
  
  // Get available charts
  // Required permissions:
  // - internal-dashboard.charts.list
  ListAllCharts: (req?: arangodb_cloud_common_v1_Empty) => Promise<arangodb_cloud_internal_charts_v1_ChartList>;
  
  // Create chart installation for deployment
  // Required permissions:
  // - internal-dashboard.chartinstallations.create
  CreateChartInstallation: (req: arangodb_cloud_internal_charts_v1_ChartInstallation) => Promise<arangodb_cloud_internal_charts_v1_ChartInstallation>;
  
  // Update chart installation for deployment
  // Required permissions:
  // - internal-dashboard.chartinstallations.update
  UpdateChartInstallation: (req: arangodb_cloud_internal_charts_v1_ChartInstallation) => Promise<arangodb_cloud_internal_charts_v1_ChartInstallation>;
  
  // List chart installations for deployment
  // Required permissions:
  // - internal-dashboard.chartinstallations.get
  ListChartInstallations: (req: arangodb_cloud_internal_charts_v1_ListChartInstallationsRequest) => Promise<arangodb_cloud_internal_charts_v1_ChartInstallationList>;
  
  // Delete chart installation for deployment
  // Required permissions:
  // - internal-dashboard.chartinstallations.delete
  DeleteChartInstallation: (req: arangodb_cloud_internal_charts_v1_ChartInstallation) => Promise<arangodb_cloud_common_v1_YesOrNo>;
}

// InternalDashboardService is the API used by the support dashboard
export class InternalDashboardService implements IInternalDashboardService {
  // Get the current API version of this service.
  // This is the same info we expose on all public APIs
  // Required permissions:
  // - None
  async GetAPIVersion(req?: arangodb_cloud_common_v1_Empty): Promise<arangodb_cloud_common_v1_Version> {
    const path = `/internal-api/dashboard/v1/api-version`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Get the runtime version of this service.
  // This is the same info as we expose on GetServiceDescriptor().Version
  // Required permissions:
  // - None
  async GetVersion(req?: arangodb_cloud_common_v1_Empty): Promise<arangodb_cloud_common_v1_Version> {
    const path = `/internal-api/dashboard/v1/version`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Is the authenticated user part of the support organization.
  // Required permissions:
  // - none
  async IsSupportUser(req?: arangodb_cloud_common_v1_Empty): Promise<arangodb_cloud_common_v1_YesOrNo> {
    const path = `/internal-api/dashboard/v1/user/is/support`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Return the list of permissions that are available to the currently authenticated
  // user for actions on the resource identified by the given URL.
  // Required permissions:
  // - None
  async GetEffectivePermissions(req: arangodb_cloud_common_v1_URLOptions): Promise<arangodb_cloud_iam_v1_PermissionList> {
    const path = `/internal-api/dashboard/v1/policies/effective-permissions`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Return the lists of permissions that are available to the currently authenticated
  // used for actions on the resources identified by the given URLs.
  // This method can replace multiple GetEffectivePermissions calls into a single roundtrip.
  // Required permissions:
  // - None
  async GetMultipleEffectivePermissions(req: arangodb_cloud_iam_v1_GetMultipleEffectivePermissionsRequest): Promise<arangodb_cloud_iam_v1_MultiplePermissionLists> {
    const url = `/internal-api/dashboard/v1/policies/effective-permissions-multiple`;
    return api.post(url, req);
  }
  
  // Fetch a CA certificate by its id.
  // Required permissions:
  // - internal-dashboard.cacertificate.get on the support organization.
  async GetCACertificate(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_crypto_v1_CACertificate> {
    const path = `/internal-api/dashboard/v1/cacertificates/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Fetch an IP allowlist by its id.
  // Required permissions:
  // - internal-dashboard.ipallowlist.get globally.
  async GetIPAllowlist(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_security_v1_IPAllowlist> {
    const path = `/internal-api/dashboard/v1/ipallowlists/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Fetch all data clusters.
  // Required permissions:
  // - internal-dashboard.datacluster.list on the support organization.
  async ListDataClusters(req: arangodb_cloud_internal_data_v1_ListDataClusterOptions): Promise<arangodb_cloud_internal_data_v1_DataClusterList> {
    const url = `/internal-api/dashboard/v1/dataclusters`;
    return api.put(url, req);
  }
  
  // Fetch a data cluster by its id.
  // Required permissions:
  // - internal-dashboard.datacluster.get on the support organization.
  async GetDataCluster(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_data_v1_DataCluster> {
    const path = `/internal-api/dashboard/v1/dataclusters/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Fetch the kubernetes access config, used to access the datacluster identified by given ID.
  // Required permissions:
  // - internal-dashboard.datacluster.get-credentials globally
  async GetDataClusterKubeConfig(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_data_v1_DataClusterKubeConfig> {
    const path = `/internal-api/dashboard/v1/dataclusters/${encodeURIComponent(req.id || '')}/kube-config`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Fetch the (estimated) costs and benefits for the datacluster identified by given ID.
  // Required permissions:
  // - internal-dashboard.datacluster.get-cost-benefits globally
  async GetDataClusterCostBenefits(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_data_v1_DataClusterCostBenefits> {
    const path = `/internal-api/dashboard/v1/dataclusters/${encodeURIComponent(req.id || '')}/cost-benefits`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Update the (previous) target provision info of a data cluster
  // (found in DataCluster.TargetProvisionInfo and DataCluster.PreviousTargetProvisionInfo)
  // Required permissions:
  // - internal-dashboard.datacluster.update on the support organization.
  async UpdateDataClusterTargetProvisionInfo(req: arangodb_cloud_internal_data_v1_DataCluster): Promise<void> {
    const url = `/internal-api/dashboard/v1/dataclusters/${encodeURIComponent(req.id || '')}/targetprovisioninfo`;
    return api.put(url, req);
  }
  
  // Update the provision_hash of the data-cluster assignment for the deployment
  // identified by the given ID.
  // The provision_hash is updated to the actual hash of the data-cluster.
  // Required permissions:
  // - internal-dashboard.dataclusterassignment.update-provision-hash globally
  async UpdateDataClusterAssignmentProvisionHash(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.id || '')}/assignment-provision-hash`;
    const url = path + api.queryString(req, [`id`]);
    return api.put(url, undefined);
  }
  
  // Update the reboot nodes allowed of a datacluster
  // Required permissions:
  // - internal-dashboard.datacluster.manage on the support organization.
  async UpdateDataClusterRebootNodesAllowed(req: arangodb_cloud_internal_data_v1_UpdateDataClusterRebootNodesAllowedRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/dataclusters/${encodeURIComponent(req.datacluster_id || '')}/reboot-nodes-allowed`;
    return api.put(url, req);
  }
  
  // Update the upgrade nodepools allowed of a datacluster
  // Required permissions:
  // - internal-dashboard.datacluster.manage on the support organization.
  async UpdateDataClusterUpgradeNodepoolsAllowed(req: arangodb_cloud_internal_data_v1_UpdateDataClusterUpgradeNodepoolsAllowedRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/dataclusters/${encodeURIComponent(req.datacluster_id || '')}/upgrade-nodepools-allowed`;
    return api.put(url, req);
  }
  
  // Update the maintenance mode of a datacluster
  // Required permissions:
  // - internal-dashboard.datacluster.manage on the support organization.
  async UpdateDataClusterMaintenanceMode(req: arangodb_cloud_internal_data_v1_UpdateDataClusterMaintenanceModeRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/dataclusters/${encodeURIComponent(req.datacluster_id || '')}/maintenance-mode`;
    return api.put(url, req);
  }
  
  // Update the annotations of a datacluster
  // Required permissions:
  // - internal-dashboard.datacluster.manage on the support organization.
  async UpdateDataClusterAnnotations(req: arangodb_cloud_internal_data_v1_UpdateDataClusterAnnotationsRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/dataclusters/${encodeURIComponent(req.datacluster_id || '')}/annotations`;
    return api.put(url, req);
  }
  
  // Update the Cilium Hubble settings for a datacluster
  // Required permissions:
  // - internal-dashboard.datacluster.manage on globally
  async UpdateDataClusterCiliumHubble(req: arangodb_cloud_internal_data_v1_UpdateDataClusterCiliumHubbleRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/dataclusters/${encodeURIComponent(req.datacluster_id || '')}/cilium-hubble`;
    return api.put(url, req);
  }
  
  // Update the core dump enabled setting for a datacluster
  // Required permissions:
  // - internal-dashboard.datacluster.update-core-dump globally
  async UpdateDataClusterCoreDump(req: arangodb_cloud_internal_data_v1_UpdateDataClusterCoreDumpRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/dataclusters/${encodeURIComponent(req.datacluster_id || '')}/core-dump`;
    return api.put(url, req);
  }
  
  // Update the Prometheus memory settings for a datacluster
  // Required permissions:
  // - internal-dashboard.datacluster.manage on globally
  async UpdateDataClusterPrometheusMemory(req: arangodb_cloud_internal_data_v1_UpdateDataClusterPrometheusMemoryRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/dataclusters/${encodeURIComponent(req.datacluster_id || '')}/prometheus-memory`;
    return api.put(url, req);
  }
  
  // Update data cluster's volume infos (like type, iops and throughput)
  // Required permissions:
  // - internal-dashboard.datacluster.update-volume-infos on the support organization.
  async UpdateDataClusterVolumeInfos(req: arangodb_cloud_internal_data_v1_UpdateDataClusterVolumeInfosRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/dataclusters/${encodeURIComponent(req.datacluster_id || '')}/volume-infos`;
    return api.put(url, req);
  }
  
  // Update data cluster's disk sizes
  // Required permissions:
  // - internal-dashboard.datacluster.update-disk-sizes on the support organization.
  async UpdateDataClusterDiskSizes(req: arangodb_cloud_internal_data_v1_UpdateDataClusterDiskSizesRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/dataclusters/${encodeURIComponent(req.datacluster_id || '')}/disk-sizes`;
    return api.put(url, req);
  }
  
  // Change the unassignable status of a datacluster
  // Required permissions:
  // - internal-dashboard.datacluster.manage on the support organization.
  async SetDataClusterUnassignable(req: SetDataClusterUnassignableRequest): Promise<void> {
    const path = `/internal-api/dashboard/v1/dataclusters/${encodeURIComponent(req.datacluster_id || '')}/unassignable`;
    const url = path + api.queryString(req, [`datacluster_id`]);
    return api.put(url, undefined);
  }
  
  // Change the labels of a datacluster
  // Required permissions:
  // - internal-dashboard.datacluster.manage on the support organization.
  async SetDataClusterLabels(req: SetDataClusterLabelsRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/dataclusters/${encodeURIComponent(req.datacluster_id || '')}/labels`;
    return api.put(url, req);
  }
  
  // Fetch the number of deployments that match all of the given filters.
  // Required permissions:
  // - internal-dashboard.deployment.count globally.
  async CountDeployments(req: arangodb_cloud_internal_data_v1_ListAllDeploymentsRequest): Promise<arangodb_cloud_internal_data_v1_DeploymentCount> {
    const url = `/internal-api/dashboard/v1/deployment-count`;
    return api.put(url, req);
  }
  
  // Fetch all deployments that match all of the given filters.
  // Required permissions:
  // - internal-dashboard.deployment.list on the support organization.
  async ListAllDeployments(req: arangodb_cloud_internal_data_v1_ListAllDeploymentsRequest): Promise<arangodb_cloud_internal_data_v1_DeploymentInfoList> {
    const url = `/internal-api/dashboard/v1/deployments`;
    return api.put(url, req);
  }
  
  // Fetch a deployment by its id.
  // Required permissions:
  // - internal-dashboard.deployment.get on the support organization.
  async GetDeployment(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_data_v1_DeploymentInfo> {
    const path = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Get the deployment network transfer info identified by given deployment ID.
  // Required permissions:
  // - internal-dashboard.deployment.get-network-transfer on the support organization.
  async GetDeploymentNetworkTransfer(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_data_v1_DeploymentNetworkTransfer> {
    const path = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.id || '')}/network-transfer`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Update the hibernation mode of a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-hibernation-mode on the support organization.
  async UpdateDeploymentHibernationMode(req: arangodb_cloud_internal_data_v1_UpdateDeploymentHibernationModeRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/hibernation-mode`;
    return api.put(url, req);
  }
  
  // Update VST enabled of a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-vst-enabled on the support organization.
  async UpdateDeploymentVSTEnabled(req: arangodb_cloud_internal_data_v1_UpdateDeploymentVSTEnabledRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/vst-enabled`;
    return api.put(url, req);
  }
  
  // Update the maintenance mode of a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-maintenance-mode on the support organization.
  async UpdateDeploymentMaintenanceMode(req: arangodb_cloud_internal_data_v1_UpdateDeploymentMaintenanceModeRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/maintenance-mode`;
    return api.put(url, req);
  }
  
  // Update the allow traffic settings of a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-allow-traffic on the support organization.
  async UpdateDeploymentAllowTraffic(req: arangodb_cloud_internal_data_v1_UpdateDeploymentAllowTrafficRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/allow-traffic`;
    return api.put(url, req);
  }
  
  // Update the allowed endpoints for a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-endpoint-allowlists on the deployment
  async UpdateDeploymentEndpointAllowlists(req: arangodb_cloud_internal_data_v1_UpdateDeploymentEndpointAllowlistsRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/endpoint-allowlists`;
    return api.put(url, req);
  }
  
  // Update the custom command line arguments for a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-custom-command-line-arguments on the deployment
  async UpdateDeploymentCustomCommandLineArguments(req: arangodb_cloud_internal_data_v1_UpdateDeploymentCustomCommandLineArgumentsRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/custom-command-line-arguments`;
    return api.put(url, req);
  }
  
  // Update the annotations for a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-annoations on the deployment
  async UpdateDeploymentAnnotations(req: arangodb_cloud_internal_data_v1_UpdateDeploymentAnnotationsRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/annotations`;
    return api.put(url, req);
  }
  
  // Update version of a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-version on the support organization.
  async UpdateDeploymentVersion(req: UpdateDeploymentVersionRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/version`;
    return api.put(url, req);
  }
  
  // Update the kube-arangodb settings for a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-kube-arangodb on the deployment
  async UpdateDeploymentKubeArangodb(req: arangodb_cloud_internal_data_v1_UpdateDeploymentKubeArangodbRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/kube-arangodb`;
    return api.put(url, req);
  }
  
  // Update the load-balancer settings for a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-loadbalancer on the deployment
  async UpdateDeploymentLoadBalancer(req: arangodb_cloud_internal_data_v1_UpdateDeploymentLoadBalancerRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/loadbalancer`;
    return api.put(url, req);
  }
  
  // Update the core dump filter for a deployment identified with given ID.
  // Required permissions:
  // - internal-dashboard.deployment.update-core-dump-filter on the deployment
  async UpdateDeploymentCoreDumpFilter(req: arangodb_cloud_internal_data_v1_UpdateDeploymentCoreDumpFilterRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/core-dump-filter`;
    return api.put(url, req);
  }
  
  // Update the foxx authentication setting for a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-foxx-authentication on the deployment
  async UpdateDeploymentFoxxAuthentication(req: UpdateDeploymentFoxxAuthenticationRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/foxx-authentication`;
    return api.put(url, req);
  }
  
  // Fetch credentials for the deployment identified by the given id.
  // Required permissions:
  // - internal-dashboard.deployment.get on the support organization.
  // - internal-dashboard.deploymentcredentials.get on the support organization.
  async GetDeploymentRootCredentials(req: arangodb_cloud_data_v1_DeploymentCredentialsRequest): Promise<arangodb_cloud_data_v1_DeploymentCredentials> {
    const path = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/root-credentials`;
    const url = path + api.queryString(req, [`deployment_id`]);
    return api.get(url, undefined);
  }
  
  // Rotate the credentials for accessing the deployment with given id.
  // Required permissions:
  // - internal-dashboard.deploymentcredentials.rotate on the support organization.
  async RotateDeploymentCredentials(req: arangodb_cloud_internal_data_v1_RotateDeploymentCredentialsRequest): Promise<void> {
    const path = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/credentials`;
    const url = path + api.queryString(req, [`deployment_id`]);
    return api.put(url, undefined);
  }
  
  // Get deployment statistics
  // Required permissions:
  // - internal-dashboard.statistics.get globally
  async GetDeploymentStatistics(req?: arangodb_cloud_common_v1_Empty): Promise<arangodb_cloud_internal_data_v1_DeploymentStatistics> {
    const path = `/internal-api/dashboard/v1/deployment-stats`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Change the expiration date of a deployment
  // Required permissions:
  // - internal-dashboard.deployment.set-expiration-date on the support organization.
  // and
  // - internal-dashboard.deployment.opt-out-expiration on the support organization if the opt_out flag is set.
  async SetDeploymentExpirationDate(req: SetDeploymentExpirationDateRequest): Promise<void> {
    const path = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/expiration-date`;
    const url = path + api.queryString(req, [`deployment_id`]);
    return api.put(url, undefined);
  }
  
  // Fetch all deployment infos that are assigned to the data cluster.
  // Required permissions:
  // - internal-dashboard.deployment.list on the support organization.
  async ListAssignedDeployments(req: arangodb_cloud_common_v1_ListOptions): Promise<arangodb_cloud_internal_data_v1_DeploymentInfoList> {
    const path = `/internal-api/dashboard/v1/dataclusters/${encodeURIComponent(req.context_id || '')}/deployments`;
    const url = path + api.queryString(req, [`context_id`]);
    return api.get(url, undefined);
  }
  
  // Fetch all users that match all of the given filters.
  // Required permissions:
  // - internal-dashboard.user.list on the support organization.
  async ListUsers(req: arangodb_cloud_internal_iam_v1_ListUsersRequest): Promise<arangodb_cloud_internal_iam_v1_UserList> {
    const path = `/internal-api/dashboard/v1/users`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch a user by its id.
  // Required permissions:
  // - internal-dashboard.user.get on the support organization.
  async GetUser(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_iam_v1_User> {
    const path = `/internal-api/dashboard/v1/users/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Get the mobile phone verification code for a user identified by the given ID.
  // Required permissions:
  // - internal-dashboard.user.get-verification-code on the support organization..
  async GetUserMobilePhoneVerificationCode(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_iam_v1_MobilePhoneVerificationCode> {
    const path = `/internal-api/dashboard/v1/users/${encodeURIComponent(req.id || '')}/verification-code`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Reset mobile phone number for user identified by given ID.
  // Required permissions:
  // - internal-dashboard.user.reset-mobile-phone globally.
  async ResetMobilePhone(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/internal-api/dashboard/v1/users/${encodeURIComponent(req.id || '')}/reset-mobile-phone`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Fetch all organizations that match all of the given filters.
  // Required permissions:
  // - internal-dashboard.organization.list on the support organization.
  // This method is deprecated and will be remove in the very near future, do not use this method anymore
  async ListOrganizations(req: arangodb_cloud_internal_resourcemanager_v1_ListAllOrganizationsRequest): Promise<arangodb_cloud_resourcemanager_v1_OrganizationList> {
    const path = `/internal-api/dashboard/v1/organizations`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch all organizations that match all of the given filters.
  // Required permissions:
  // - internal-dashboard.organization.list on the support organization.
  async ListOrganizationInfos(req: arangodb_cloud_internal_resourcemanager_v1_ListAllOrganizationsRequest): Promise<arangodb_cloud_internal_resourcemanager_v1_OrganizationInfoList> {
    const url = `/internal-api/dashboard/v1/organization-infos`;
    return api.put(url, req);
  }
  
  // Fetch the number of organizations that match all of the given filters.
  // Required permissions:
  // - internal-dashboard.organization.count globally.
  async CountOrganizations(req: arangodb_cloud_internal_resourcemanager_v1_ListAllOrganizationsRequest): Promise<arangodb_cloud_internal_resourcemanager_v1_OrganizationCount> {
    const url = `/internal-api/dashboard/v1/organization-count`;
    return api.put(url, req);
  }
  
  // Fetch an organization by its id.
  // Required permissions:
  // - internal-dashboard.organization.get on the support organization.
  // This method is deprecated and will be remove in the very near future, do not use this method anymore
  async GetOrganization(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_resourcemanager_v1_Organization> {
    const path = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Update notification for a deployment
  // Required permissions:
  // - internal-dashboard.organization.update-notification
  async UpdateOrganizationNotification(req: arangodb_cloud_internal_resourcemanager_v1_UpdateOrganizationNotificationRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/notifications`;
    return api.post(url, req);
  }
  
  // Remove notification for a deployment
  // Required permissions:
  // - internal-dashboard.organization.update-notification
  async RemoveOrganizationNotification(req: arangodb_cloud_internal_resourcemanager_v1_RemoveOrganizationNotificationRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/notifications`;
    return api.delete(url, req);
  }
  
  // Get a list of members of the organization identified by the given context ID.
  // Required permissions:
  // - internal-dashboard.organization.get-members on the support organization.
  async ListOrganizationMembers(req: arangodb_cloud_common_v1_ListOptions): Promise<arangodb_cloud_resourcemanager_v1_MemberList> {
    const path = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.context_id || '')}/members`;
    const url = path + api.queryString(req, [`context_id`]);
    return api.get(url, undefined);
  }
  
  // Fetch all projects of the organization identified by the given context ID.
  // Required permissions:
  // - internal-dashboard.project.list on the support organization.
  async ListProjects(req: arangodb_cloud_common_v1_ListOptions): Promise<arangodb_cloud_resourcemanager_v1_ProjectList> {
    const path = `/internal-api/dashboard/v1/projects`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch a project by its id.
  // Required permissions:
  // - internal-dashboard.project.get on the support organization.
  async GetProject(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_resourcemanager_v1_Project> {
    const path = `/internal-api/dashboard/v1/projects/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Fetch all tier spefications known by the system.
  // Required permissions:
  // - internal-dashboard.tier.list on the support organization.
  async ListTiers(req: arangodb_cloud_common_v1_ListOptions): Promise<TierList> {
    const path = `/internal-api/dashboard/v1/tiers`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch all detailed tier spefications known by the system.
  // Required permissions:
  // - internal-dashboard.tier.list on the support organization.
  async ListTierDetails(req: arangodb_cloud_common_v1_ListOptions): Promise<arangodb_cloud_internal_resourcemanager_v1_TierDetailsList> {
    const path = `/internal-api/dashboard/v1/tier-details`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Set the tier of a given organization to the given tier ID.
  // Required permissions:
  // - internal-dashboard.organization.set-tier on the support organization.
  async SetOrganizationTier(req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationTierRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/tier`;
    return api.post(url, req);
  }
  
  // Set the cost center of a given organization to the given value.
  // Required permissions:
  // - internal-dashboard.organization.set-cost-center on the support organization.
  async SetOrganizationCostCenter(req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationCostCenterRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/cost-center`;
    return api.post(url, req);
  }
  
  // Fetch the entity that the organization with given ID has a contract with.
  // Required permissions:
  // - internal-resourcemanager.organization.get-entity on the support organization.
  async GetOrganizationEntity(req: arangodb_cloud_common_v1_IDOptions): Promise<OrganizationEntityDetails> {
    const path = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.id || '')}/entity`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Set the entity that the given organization has a contract with to the given entity ID.
  // Required permissions:
  // - internal-dashboard.organization.set-entity on the support organization.
  async SetOrganizationEntity(req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationEntityRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/entity`;
    return api.post(url, req);
  }
  
  // Fetch the support info for the organization with given ID.
  // Required permissions:
  // - internal-dashboard.organization.get-support-info on the support organization.
  async GetOrganizationSupportInfo(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_resourcemanager_v1_OrganizationSupportInfo> {
    const path = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.id || '')}/support-info`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Set the support information for the organization identified by given ID.
  // Required permissions:
  // - internal-dashboard.organization.set-support-info on the support organization..
  async SetOrganizationSupportInfo(req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationSupportInfoRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/support-info`;
    return api.post(url, req);
  }
  
  // Fetch the sales info for the organization with given ID.
  // Required permissions:
  // - internal-dashboard.organization.get-sales-info on the support organization.
  async GetOrganizationSalesInfo(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_resourcemanager_v1_OrganizationSalesInfo> {
    const path = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.id || '')}/sales-info`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Set the sales information for the organization identified by given ID.
  // Required permissions:
  // - internal-dashboard.organization.set-sales-info on the support organization..
  async SetOrganizationSalesInfo(req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationSalesInfoRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/sales-info`;
    return api.post(url, req);
  }
  
  // Fetch all UsageItem resources in the organization identified by the given
  // organization ID that match the given criteria.
  // Required permissions:
  // - internal-dashboard.usageitem.list on the support organization.
  async ListUsageItems(req: arangodb_cloud_usage_v1_ListUsageItemsRequest): Promise<arangodb_cloud_usage_v1_UsageItemList> {
    const path = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/usageitems`;
    const url = path + api.queryString(req, [`organization_id`]);
    return api.get(url, undefined);
  }
  
  // Fetch all UsageItemInfo resources that match the given criteria.
  // In contrast to the public ListUsageItems function, this function does not
  // require an organization ID.
  // Required permissions:
  // - internal-dashboard.usageitem.list globally
  async ListAllUsageItemInfos(req: arangodb_cloud_internal_usage_v1_ListAllUsageItemsRequest): Promise<arangodb_cloud_internal_usage_v1_UsageItemInfoList> {
    const url = `/internal-api/dashboard/v1/usageiteminfos`;
    return api.post(url, req);
  }
  
  // Fetch all pricing plan that match the given request.
  // Required permissions:
  // - internal-dashboard.pricingplan.list on the support organization.
  async ListPlans(req: arangodb_cloud_internal_pricing_v1_ListPlansRequest): Promise<arangodb_cloud_internal_pricing_v1_PlanList> {
    const path = `/internal-api/dashboard/v1/pricingplans`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch the pricing plan identified by the given ID.
  // Required permissions:
  // - internal-dashboard.pricingplan.get on the support organization.
  async GetPlan(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_pricing_v1_Plan> {
    const path = `/internal-api/dashboard/v1/pricingplans/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Create a new pricing plan.
  // Required permissions:
  // - internal-dashboard.pricingplan.create on the support organization.
  async CreatePlan(req: arangodb_cloud_internal_pricing_v1_Plan): Promise<arangodb_cloud_internal_pricing_v1_Plan> {
    const url = `/internal-api/dashboard/v1/pricingplans`;
    return api.post(url, req);
  }
  
  // Update an existing pricing plan.
  // Note that a plan can only be updated while it is not locked.
  // Required permissions:
  // - internal-dashboard.pricingplan.update on the support organization.
  async UpdatePlan(req: arangodb_cloud_internal_pricing_v1_Plan): Promise<arangodb_cloud_internal_pricing_v1_Plan> {
    const url = `/internal-api/dashboard/v1/pricingplans/${encodeURIComponent(req.id || '')}`;
    return api.put(url, req);
  }
  
  // Delete an existing pricing plan.
  // Note that a plan can only be deleted while it is not locked.
  // Required permissions:
  // - internal-dashboard.pricingplan.delete on the support organization.
  async DeletePlan(req: arangodb_cloud_internal_pricing_v1_Plan): Promise<void> {
    const path = `/internal-api/dashboard/v1/pricingplans/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.delete(url, undefined);
  }
  
  // Fetch all pricing plan assignments that match the given request.
  // Required permissions:
  // - internal-dashboard.pricingplanassignment.list on the support organization.
  async ListPlanAssignments(req: arangodb_cloud_internal_pricing_v1_ListPlanAssignmentsRequest): Promise<arangodb_cloud_internal_pricing_v1_PlanAssignmentList> {
    const path = `/internal-api/dashboard/v1/pricingplanassignments`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch the pricing plan assignment identified by the given ID.
  // Required permissions:
  // - internal-dashboard.pricingplanassignment.get on the support organization.
  async GetPlanAssignment(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_pricing_v1_PlanAssignment> {
    const path = `/internal-api/dashboard/v1/pricingplanassignments/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Create a new pricing plan assignment.
  // Required permissions:
  // - internal-dashboard.pricingplanassignment.create on the support organization.
  async CreatePlanAssignment(req: arangodb_cloud_internal_pricing_v1_PlanAssignment): Promise<arangodb_cloud_internal_pricing_v1_PlanAssignment> {
    const url = `/internal-api/dashboard/v1/pricingplanassignments`;
    return api.post(url, req);
  }
  
  // Update an existing pricing plan assignment.
  // Only the end date of an assignment can be changed.
  // Required permissions:
  // - internal-dashboard.pricingplanassignment.update on the support organization.
  async UpdatePlanAssignment(req: arangodb_cloud_internal_pricing_v1_PlanAssignment): Promise<arangodb_cloud_internal_pricing_v1_PlanAssignment> {
    const url = `/internal-api/dashboard/v1/pricingplanassignments/${encodeURIComponent(req.id || '')}`;
    return api.put(url, req);
  }
  
  // Calculate the price for a specific usage of a resources.
  // Required permissions:
  // - internal-dashboard.price.calculate on the support organization.
  async CalculatePrice(req: arangodb_cloud_internal_pricing_v1_PriceRequest): Promise<arangodb_cloud_internal_pricing_v1_PriceList> {
    const url = `/internal-api/dashboard/v1/price/calculate`;
    return api.post(url, req);
  }
  
  // Get a list of quota values for the organization identified by the given context ID.
  // If a quota is not specified on organization level, a (potentially tier specific) default
  // value is returned.
  // Required permissions:
  // - internal-dashboard.organization.get on the support organization.
  async ListOrganizationQuotas(req: arangodb_cloud_resourcemanager_v1_ListQuotasRequest): Promise<arangodb_cloud_resourcemanager_v1_QuotaList> {
    const path = `/internal-api/dashboard/v1/organizations/${encodeURIComponent((req.options || {}).context_id || '')}/quotas`;
    const url = path + api.queryString(req, [`options.context_id`]);
    return api.get(url, undefined);
  }
  
  // Set a quota at organization level.
  // Required permissions:
  // - internal-dashboard.organization.set-quota on the support organization.
  async SetOrganizationQuota(req: arangodb_cloud_internal_resourcemanager_v1_SetQuotaRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.id || '')}/quotas`;
    return api.post(url, req);
  }
  
  // Get a list of quota values for the project identified by the given context ID.
  // If a quota is not specified on project level, a value from organization level
  // is returned.
  // Required permissions:
  // - internal-dashboard.project.get on the support organization.
  async ListProjectQuotas(req: arangodb_cloud_resourcemanager_v1_ListQuotasRequest): Promise<arangodb_cloud_resourcemanager_v1_QuotaList> {
    const path = `/internal-api/dashboard/v1/projects/${encodeURIComponent((req.options || {}).context_id || '')}/quotas`;
    const url = path + api.queryString(req, [`options.context_id`]);
    return api.get(url, undefined);
  }
  
  // Set a quota at project level.
  // Required permissions:
  // - internal-dashboard.project.set-quota on the support organization.
  async SetProjectQuota(req: arangodb_cloud_internal_resourcemanager_v1_SetQuotaRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/projects/${encodeURIComponent(req.id || '')}/quotas`;
    return api.post(url, req);
  }
  
  // Fetch all email patterns.
  // Required permissions:
  // - internal-dashboard.emailpattern.list on the support organization.
  async ListEmailPatterns(req: arangodb_cloud_internal_iam_v1_ListEmailPatternsRequest): Promise<arangodb_cloud_internal_iam_v1_EmailPatternList> {
    const path = `/internal-api/dashboard/v1/emailpatterns`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch an email pattern by its id.
  // Required permissions:
  // - internal-dashboard.emailpattern.get on the support organization.
  async GetEmailPattern(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_iam_v1_EmailPattern> {
    const path = `/internal-api/dashboard/v1/emailpatterns/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Create a new email pattern
  // Required permissions:
  // - internal-dashboard.emailpattern.create on the support organization.
  async CreateEmailPattern(req: arangodb_cloud_internal_iam_v1_EmailPattern): Promise<arangodb_cloud_internal_iam_v1_EmailPattern> {
    const url = `/internal-api/dashboard/v1/emailpatterns`;
    return api.post(url, req);
  }
  
  // Update an email pattern
  // Required permissions:
  // - internal-dashboard.emailpattern.update on the support organization.
  async UpdateEmailPattern(req: arangodb_cloud_internal_iam_v1_EmailPattern): Promise<arangodb_cloud_internal_iam_v1_EmailPattern> {
    const url = `/internal-api/dashboard/v1/emailpatterns/${encodeURIComponent(req.id || '')}`;
    return api.patch(url, req);
  }
  
  // Delete an email pattern
  // Required permissions:
  // - internal-dashboard.emailpattern.delete on the support organization.
  async DeleteEmailPattern(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/internal-api/dashboard/v1/emailpatterns/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.delete(url, undefined);
  }
  
  // Fetch all public email provider domains.
  // Required permissions:
  // - internal-dashboard.publicemailproviderdomain.list on the support organization.
  async ListPublicEmailProviderDomains(req: arangodb_cloud_internal_iam_v1_ListPublicEmailProviderDomainsRequest): Promise<arangodb_cloud_internal_iam_v1_PublicEmailProviderDomainList> {
    const path = `/internal-api/dashboard/v1/publicemailproviderdomains`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch an public email provider domain by its id.
  // Required permissions:
  // - internal-dashboard.publicemailproviderdomain.get on the support organization.
  async GetPublicEmailProviderDomain(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_iam_v1_PublicEmailProviderDomain> {
    const path = `/internal-api/dashboard/v1/publicemailproviderdomains/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Create a new public email provider domain
  // Required permissions:
  // - internal-dashboard.publicemailproviderdomain.create on the support organization.
  async CreatePublicEmailProviderDomain(req: arangodb_cloud_internal_iam_v1_PublicEmailProviderDomain): Promise<arangodb_cloud_internal_iam_v1_PublicEmailProviderDomain> {
    const url = `/internal-api/dashboard/v1/publicemailproviderdomains`;
    return api.post(url, req);
  }
  
  // Update a public email provider domain
  // Required permissions:
  // - internal-dashboard.publicemailproviderdomain.update on the support organization.
  async UpdatePublicEmailProviderDomain(req: arangodb_cloud_internal_iam_v1_PublicEmailProviderDomain): Promise<arangodb_cloud_internal_iam_v1_PublicEmailProviderDomain> {
    const url = `/internal-api/dashboard/v1/publicemailproviderdomains/${encodeURIComponent(req.id || '')}`;
    return api.patch(url, req);
  }
  
  // Delete a public email provider domain
  // Required permissions:
  // - internal-dashboard.publicemailproviderdomain.delete on the support organization.
  async DeletePublicEmailProviderDomain(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/internal-api/dashboard/v1/publicemailproviderdomains/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.delete(url, undefined);
  }
  
  // Get the actual status of the Controlplane
  // Required permissions:
  // - internal-dashboard.controlplanestatus.get on the support organization.
  async GetControlplaneStatus(req?: arangodb_cloud_common_v1_Empty): Promise<ControlplaneStatus> {
    const path = `/internal-api/dashboard/v1/controlplanestatus`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch all Invoice resources that match the given criteria.
  // Required permissions:
  // - internal-dashboard.invoice.list on the support organization.
  async ListAllInvoices(req: arangodb_cloud_internal_billing_v1_ListAllInvoicesRequest): Promise<arangodb_cloud_billing_v1_InvoiceList> {
    const path = `/internal-api/dashboard/v1/invoices`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch all Invoice resources that match the given criteria and return them as a csv file stream in Chunks
  // Required permissions:
  // - internal-dashboard.invoice.list on the support organization.
  async ListAllInvoicesCSV(req: arangodb_cloud_internal_billing_v1_ListAllInvoicesRequest): Promise<void> {
    const path = `/internal-api/dashboard/v1/invoices-csv`;
    const url = path + api.queryString(req, []);
    return api.download(url);
  }
  
  // Fetch statistics for all Invoice resources that match the given criteria.
  // Required permissions:
  // - internal-dashboard.invoice.get-statistics globally
  async GetInvoiceStatistics(req: arangodb_cloud_internal_billing_v1_ListAllInvoicesRequest): Promise<arangodb_cloud_internal_billing_v1_InvoiceStatistics> {
    const url = `/internal-api/dashboard/v1/invoice-statistics`;
    return api.post(url, req);
  }
  
  // Fetch the Invoice resources identified by the given ID.
  // Required permissions:
  // - internal-dashboard.invoice.get on the support organization.
  async GetInvoice(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_billing_v1_Invoice> {
    const path = `/internal-api/dashboard/v1/invoices/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Fetch a preliminary Invoice identified by the given organization ID.
  // The preliminary invoice contains all costs from the last invoice (if any, otherwise the creation date of the organization) until last night (midnight UTC).
  // Required permissions:
  // - internal-dashboard.invoice.get-preliminary globally
  async GetPreliminaryInvoice(req: arangodb_cloud_billing_v1_GetPreliminaryInvoiceRequest): Promise<arangodb_cloud_billing_v1_Invoice> {
    const path = `/internal-api/dashboard/v1/invoices/${encodeURIComponent(req.organization_id || '')}/preliminary`;
    const url = path + api.queryString(req, [`organization_id`]);
    return api.get(url, undefined);
  }
  
  // Calculate the Invoice provided in items.
  // The result contain the correct totals (including VAT and Sales tax if applicable)
  // Note: This invoice is not saved at this point
  // Required permissions:
  // - internal-dashboard.invoice.calculate on the support organization.
  async CalculateInvoice(req: arangodb_cloud_billing_v1_Invoice): Promise<arangodb_cloud_billing_v1_Invoice> {
    const url = `/internal-api/dashboard/v1/invoices/${encodeURIComponent(req.id || '')}/calculate`;
    return api.post(url, req);
  }
  
  // Update the Invoice provided, this updates both the invoice itself as well as the items in the invoice.
  // Note: This invoice is expected to have the correct calculation, the invoice is saved 'as-is'.
  // Required permissions:
  // - internal-dashboard.invoice.update on the support organization.
  // Note that once a payment is added or the invoice is completed or rejected,
  // this function results in a PermissionDenied error.
  async UpdateInvoice(req: arangodb_cloud_billing_v1_Invoice): Promise<arangodb_cloud_billing_v1_Invoice> {
    const url = `/internal-api/dashboard/v1/invoices/${encodeURIComponent(req.id || '')}`;
    return api.put(url, req);
  }
  
  // Update the status & payments of an invoice.
  // Verification status is not updated.
  // Required permissions:
  // - internal-dashboard.invoice.update-status globally.
  // Note that once the invoice status is "completed",
  // this function results in a PermissionDenied error.
  async UpdateInvoiceStatus(req: arangodb_cloud_billing_v1_Invoice): Promise<arangodb_cloud_billing_v1_Invoice> {
    const url = `/internal-api/dashboard/v1/invoices/${encodeURIComponent(req.id || '')}/status`;
    return api.put(url, req);
  }
  
  // Mark the invoice identified by given ID as verified.
  // Required permissions:
  // - internal-dashboard.invoice.verify globally.
  async VerifyInvoice(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/internal-api/dashboard/v1/invoices/${encodeURIComponent(req.id || '')}/verify`;
    const url = path + api.queryString(req, [`id`]);
    return api.post(url, undefined);
  }
  
  // Mark the invoice identified by given ID as needs rebuild.
  // Required permissions:
  // - internal-dashboard.invoice.rebuild globally.
  async RebuildInvoice(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/internal-api/dashboard/v1/invoices/${encodeURIComponent(req.id || '')}/rebuild`;
    const url = path + api.queryString(req, [`id`]);
    return api.post(url, undefined);
  }
  
  // Mark the invoice identified by given ID as completed.
  // Required permissions:
  // - internal-dashboard.invoice.complete globally.
  // Note that once a payment is added or the invoice is completed or rejected,
  // this function results in a PermissionDenied error.
  async CompleteInvoice(req: arangodb_cloud_internal_billing_v1_CompleteInvoiceRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/invoices/${encodeURIComponent(req.invoice_id || '')}/complete`;
    return api.post(url, req);
  }
  
  // Fetch all providers that are supported by the ArangoDB cloud.
  // Required permissions:
  // - internal-dashboard.currency.list on the support organization..
  async ListCurrencies(req: arangodb_cloud_common_v1_ListOptions): Promise<arangodb_cloud_currency_v1_CurrencyList> {
    const path = `/internal-api/dashboard/v1/currencies`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch a currency by its id.
  // Required permissions:
  // - internal-dashboard.currency.get on the support organization..
  async GetCurrency(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_currency_v1_Currency> {
    const path = `/internal-api/dashboard/v1/currencies/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Fetch the default currency for a given (optional) organization.
  // Required permissions:
  // - internal-dashboard.currency.get-default on the support organization..
  async GetDefaultCurrency(req: arangodb_cloud_currency_v1_GetDefaultCurrencyRequest): Promise<arangodb_cloud_currency_v1_Currency> {
    const path = `/internal-api/dashboard/v1/default-currency`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch the system wide default currency.
  // Required permissions:
  // - internal-dashboard.currency.get-default-system on the support organization..
  async GetDefaultSystemCurrency(req?: arangodb_cloud_common_v1_Empty): Promise<arangodb_cloud_currency_v1_Currency> {
    const path = `/internal-api/dashboard/v1/default-system-currency`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // List all known conversion rates
  // Required permissions:
  // - internal-dashboard.conversionrate.list on the support organization..
  async ListConversionRates(req: arangodb_cloud_common_v1_ListOptions): Promise<arangodb_cloud_internal_currency_v1_ConversionRateList> {
    const path = `/internal-api/dashboard/v1/conversionrates`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Set a conversion rate.
  // Required permissions:
  // - internal-dashboard.conversionrate.set on the support organization..
  async SetConversionRate(req: arangodb_cloud_internal_currency_v1_ConversionRate): Promise<void> {
    const url = `/internal-api/dashboard/v1/conversionrate`;
    return api.put(url, req);
  }
  
  // Convert a price from one currency to another.
  // Required permissions:
  // - internal-dashboard.currency.convert on the support organization..
  async Convert(req: arangodb_cloud_internal_currency_v1_ConvertRequest): Promise<arangodb_cloud_internal_currency_v1_ConvertResponse> {
    const url = `/internal-api/dashboard/v1/convert-currency`;
    return api.put(url, req);
  }
  
  // Get authentication statistics
  // Required permissions:
  // - internal-dashboard.statistics.get globally
  async GetAuthenticationStatistics(req?: arangodb_cloud_common_v1_Empty): Promise<arangodb_cloud_internal_iam_v1_AuthenticationStatistics> {
    const path = `/internal-api/dashboard/v1/authentication-stats`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Get organization statistics
  // Required permissions:
  // - internal-dashboard.statistics.get globally
  async GetOrganizationStatistics(req?: arangodb_cloud_common_v1_Empty): Promise<arangodb_cloud_internal_resourcemanager_v1_OrganizationStatistics> {
    const path = `/internal-api/dashboard/v1/organization-stats`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Set the allowed deployment model flags of the organization identified
  // by the given context id.
  // Required permissions:
  // - internal-dashboard.organization.set-deployment-models globally.
  async SetOrganizationDeploymentModels(req: arangodb_cloud_internal_resourcemanager_v1_SetDeploymentModelsRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.context_id || '')}/deployment-models`;
    return api.put(url, req);
  }
  
  // Allow an organization to use custom images for ArangoDB deployments.
  // Required permissions:
  // - internal-dashboard.organization.set-allow-custom-images globally.
  async SetOrganizationAllowedCustomImages(req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationAllowedCustomImagesRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/set-allow-custom-images`;
    return api.put(url, req);
  }
  
  // Allow an organization to use IAM providers on deployments.
  // Required permissions:
  // - internal-dashboard.organization.set-allow-iamproviders globally.
  async SetOrganizationAllowedIAMProviders(req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationAllowedIAMProvidersRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/set-allow-iamproviders`;
    return api.put(url, req);
  }
  
  // Allow an organization to use SCIM to manage IAM entities (like users).
  // Required permissions:
  // - internal-dashboard.organization.set-allow-scim globally.
  async SetOrganizationAllowedSCIM(req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationAllowedSCIMRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/set-allow-scim`;
    return api.put(url, req);
  }
  
  // Set the allowed deployment model flags of the project identified
  // by the given context id.
  // - internal-dashboard.project.set-deployment-models globally.
  async SetProjectDeploymentModels(req: arangodb_cloud_internal_resourcemanager_v1_SetDeploymentModelsRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/projects/${encodeURIComponent(req.context_id || '')}/deployment-models`;
    return api.put(url, req);
  }
  
  // Fetch all deployment models available for deployments.
  // Required permissions:
  // - internal-dashboard.deploymentmodel.list globally
  async ListAllDeploymentModels(req?: arangodb_cloud_common_v1_Empty): Promise<arangodb_cloud_data_v1_DeploymentModelList> {
    const path = `/internal-api/dashboard/v1/deployment-models`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch all node sizes available for deployments.
  // Required permissions:
  // - internal-dashboard.nodesize.list globally
  async ListAllNodeSizes(req?: arangodb_cloud_common_v1_Empty): Promise<arangodb_cloud_data_v1_NodeSizeList> {
    const path = `/internal-api/dashboard/v1/node-sizes`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch the node sizes available for deployments
  // owned by the project with given ID, created in the given region with given ID.
  // If project ID "all" is used, then all node sizes for the region with given
  // ID are returned.
  // Required permissions:
  // - internal-dashboard.nodesize.list globally
  async ListNodeSizes(req: arangodb_cloud_data_v1_NodeSizesRequest): Promise<arangodb_cloud_data_v1_NodeSizeList> {
    const path = `/internal-api/dashboard/v1/organization-node-sizes`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Get all details of a node size with given ID.
  // Required permissions:
  // - internal-dashboard.nodesize.get globally
  async GetNodeSizeDetails(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_data_v1_NodeSizeDetails> {
    const path = `/internal-api/dashboard/v1/node-sizes/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Fetch a specific version of the Terms & Conditions.
  // Required permissions:
  // - internal-dashboard.termsandconditions.get globally.
  async GetTermsAndConditions(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_resourcemanager_v1_TermsAndConditions> {
    const path = `/internal-api/dashboard/v1/termsandconditions/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // List all terms & conditions.
  // Required permissions:
  // - internal-dashboard.termsandconditions.list globally.
  async ListTermsAndConditions(req: arangodb_cloud_common_v1_ListOptions): Promise<arangodb_cloud_internal_resourcemanager_v1_TermsAndConditionsInfoList> {
    const path = `/internal-api/dashboard/v1/termsandconditions`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Create a new set of terms & conditions.
  // Required permissions:
  // - internal-dashboard.termsandconditions.create globally.
  async CreateTermsAndConditions(req: arangodb_cloud_resourcemanager_v1_TermsAndConditions): Promise<arangodb_cloud_resourcemanager_v1_TermsAndConditions> {
    const url = `/internal-api/dashboard/v1/termsandconditions`;
    return api.post(url, req);
  }
  
  // Update a set of terms & conditions.
  // Once terms & conditions have been marked as current, they can no longer be updated.
  // Required permissions:
  // - internal-dashboard.termsandconditions.update globally.
  async UpdateTermsAndConditions(req: arangodb_cloud_resourcemanager_v1_TermsAndConditions): Promise<arangodb_cloud_resourcemanager_v1_TermsAndConditions> {
    const url = `/internal-api/dashboard/v1/termsandconditions/${encodeURIComponent(req.id || '')}`;
    return api.put(url, req);
  }
  
  // Make the terms & conditions with given ID the current one.
  // Required permissions:
  // - internal-dashboard.termsandconditions.set-current globally.
  async SetCurrentTermsAndConditions(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const url = `/internal-api/dashboard/v1/current-termsandconditions`;
    return api.put(url, req);
  }
  
  // Fetch a specific version of the data processing addendum.
  // Required permissions:
  // - internal-dashboard.dataprocessingaddendum.get globally.
  async GetDataProcessingAddendum(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_resourcemanager_v1_DataProcessingAddendum> {
    const path = `/internal-api/dashboard/v1/dpa/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // List all data processing addendums.
  // Required permissions:
  // - internal-dashboard.dataprocessingaddendum.list globally.
  async ListDataProcessingAddendums(req: arangodb_cloud_common_v1_ListOptions): Promise<arangodb_cloud_internal_resourcemanager_v1_DataProcessingAddendumInfoList> {
    const path = `/internal-api/dashboard/v1/dpa`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Create a new data processing addendum.
  // Required permissions:
  // - internal-dashboard.dataprocessingaddendum.create globally.
  async CreateDataProcessingAddendum(req: arangodb_cloud_resourcemanager_v1_DataProcessingAddendum): Promise<arangodb_cloud_resourcemanager_v1_DataProcessingAddendum> {
    const url = `/internal-api/dashboard/v1/dpa`;
    return api.post(url, req);
  }
  
  // Update a data processing addendum.
  // Once a data processing addendum has been marked as current, they can no longer be updated.
  // Required permissions:
  // - internal-dashboard.dataprocessingaddendum.update globally.
  async UpdateDataProcessingAddendum(req: arangodb_cloud_resourcemanager_v1_DataProcessingAddendum): Promise<arangodb_cloud_resourcemanager_v1_DataProcessingAddendum> {
    const url = `/internal-api/dashboard/v1/dpa/${encodeURIComponent(req.id || '')}`;
    return api.put(url, req);
  }
  
  // Make the data processing addendum with given ID the current one.
  // Required permissions:
  // - internal-dashboard.dataprocessingaddendum.set-current globally.
  async SetCurrentDataProcessingAddendum(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const url = `/internal-api/dashboard/v1/current-dpa`;
    return api.put(url, req);
  }
  
  // Fetch the limits of a region, identified by given ID.
  // Required permissions:
  // - internal-dashboard.regionlimits.get globally
  async GetRegionLimits(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_platform_v1_RegionLimits> {
    const path = `/internal-api/dashboard/v1/regionlimits/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Set the limits of a region identified by given ID.
  // Required permissions:
  // - internal-dashboard.regionlimits.set globally
  async SetRegionLimits(req: arangodb_cloud_internal_platform_v1_RegionLimits): Promise<void> {
    const url = `/internal-api/dashboard/v1/regionlimits/${encodeURIComponent(req.id || '')}`;
    return api.put(url, req);
  }
  
  // Fetch a region's details by its id.
  // Required permissions:
  // - internal-dashboard.regiondetails.get globally
  async GetRegionDetails(req: arangodb_cloud_common_v1_IDOptions): Promise<RegionDetails> {
    const path = `/internal-api/dashboard/v1/region/${encodeURIComponent(req.id || '')}/details`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Check if the organization with given ID is excluded from billing.
  // Required permissions:
  // - internal-dashboard.organization.get-excluded-from-billing globally.
  async GetOrganizationExcludedFromBilling(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_common_v1_YesOrNo> {
    const path = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.id || '')}/excluded-from-billing`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Set that the organization with given ID is excluded (or not) from billing.
  // Required permissions:
  // - internal-dashboard.organization.manage-excluded-from-billing globally.
  async SetOrganizationExcludedFromBilling(req: arangodb_cloud_internal_billing_v1_SetOrganizationExcludedFromBillingRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/excluded-from-billing`;
    return api.put(url, req);
  }
  
  // Check if the organization with given ID is configured to use purchase
  // orders for billing (instead of online payments).
  // Required permissions:
  // - internal-dashboard.organization.get-uses-purchase-orders globally.
  async GetOrganizationUsesPurchaseOrders(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_common_v1_YesOrNo> {
    const path = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.id || '')}/uses-purchase-orders`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Set that the organization with given ID is configured to use purchase orders
  // for billing (instead of online payments).
  // Required permissions:
  // - internal-dashboard.organization.manage-uses-purchase-orders globally.
  async SetOrganizationUsesPurchaseOrders(req: arangodb_cloud_internal_billing_v1_SetOrganizationUsesPurchaseOrdersRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/uses-purchase-orders`;
    return api.put(url, req);
  }
  
  // Fetch the billing configuration of an organization identified by the given ID.
  // Required permissions:
  // - internal-dashboard.organization.get-billing-config globally
  async GetBillingConfig(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_billing_v1_BillingConfig> {
    const path = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.id || '')}/billing-config`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Update the billing address of the organization specified by the organization ID.
  // Required permissions:
  // - internal-dashboard.organization.update-billing-address globally
  async UpdateBillingAddress(req: UpdateBillingAddressRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/billing-address`;
    return api.patch(url, req);
  }
  
  // Fetch all payment methods that are configured for the organization identified
  // by the given context ID.
  // Required permissions:
  // - internal-dashboard.paymentmethod.list globally.
  async ListPaymentMethods(req: arangodb_cloud_billing_v1_ListPaymentMethodsRequest): Promise<arangodb_cloud_billing_v1_PaymentMethodList> {
    const path = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/payment-methods`;
    const url = path + api.queryString(req, [`organization_id`]);
    return api.get(url, undefined);
  }
  
  // Fetch all ArangoDB versions that are available.
  // Required permissions:
  // - internal-dashboard.version.list globally
  async ListAllVersions(req: arangodb_cloud_common_v1_ListOptions): Promise<arangodb_cloud_data_v1_VersionList> {
    const path = `/internal-api/dashboard/v1/versions`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // RunTests will execute tests based on a provided Filter.
  // Required permissions:
  // - internal-dashboard.tests.run
  async RunTests(req: arangodb_cloud_internal_integrationtests_v1_RunTestsRequest): Promise<arangodb_cloud_internal_integrationtests_v1_TestRun> {
    const url = `/internal-api/dashboard/v1/tests/run`;
    return api.post(url, req);
  }
  
  // ListTestRuns returns all known test runs.
  // Required permissions:
  // - internal-dashboard.testruns.list
  async ListTestRuns(req: arangodb_cloud_internal_integrationtests_v1_ListTestRunsRequest): Promise<arangodb_cloud_internal_integrationtests_v1_TestRunList> {
    const path = `/internal-api/dashboard/v1/testruns/list`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Delete a test run from the recorded list of test runs.
  // This will fail if the test is still running.
  // Required permissions:
  // - internal-dashboard.testruns.delete
  async DeleteTestRun(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const url = `/internal-api/dashboard/v1/testruns/${encodeURIComponent(req.id || '')}`;
    return api.delete(url, req);
  }
  
  // GetTestStatus returns the status information about the currently executing tests.
  // Required permissions:
  // - internal-dashboard.tests.get-status
  async GetTestStatus(req: arangodb_cloud_internal_integrationtests_v1_GetStatusRequest): Promise<arangodb_cloud_internal_integrationtests_v1_TestResults> {
    const path = `/internal-api/dashboard/v1/tests/${encodeURIComponent(req.id || '')}/get-status`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Abort will stop all currently running tests. To get an update about the status of the
  // tests after aborting them, call GetTestStatus.
  // Required permissions:
  // - internal-dashboard.tests.abort
  async AbortTests(req: arangodb_cloud_internal_integrationtests_v1_AbortRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/tests/${encodeURIComponent(req.id || '')}/abort`;
    return api.put(url, req);
  }
  
  // GetTestCases will return all available test cases which can be executed.
  // Required permissions:
  // - internal-dashboard.tests.get-tests
  async GetTestCases(req?: arangodb_cloud_common_v1_Empty): Promise<arangodb_cloud_internal_integrationtests_v1_TestList> {
    const path = `/internal-api/dashboard/v1/tests/get-tests`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch all events that match all of the given filters.
  // Required permissions:
  // - internal-dashboard.event.list globally.
  // Note that this uses a POST request. ListEventOptions has repeated fields,
  // which does not work in GET requests.
  async ListAllEvents(req: arangodb_cloud_resourcemanager_v1_ListEventOptions): Promise<arangodb_cloud_resourcemanager_v1_EventList> {
    const url = `/internal-api/dashboard/v1/events`;
    return api.post(url, req);
  }
  
  // Get a specific uploaded document
  // Required permissions:
  // - internal-dashboard.uploaddocument.get globally.
  async GetUploadedDocument(req: arangodb_cloud_internal_resourcemanager_v1_GetUploadedDocumentRequest): Promise<arangodb_cloud_internal_resourcemanager_v1_UploadedDocument> {
    const path = `/internal-api/dashboard/v1/uploadeddocuments/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // List all uploaded documents.
  // Required permissions:
  // - internal-dashboard.uploaddocument.list globally.
  async ListUploadedDocuments(req: arangodb_cloud_internal_resourcemanager_v1_ListUploadedDocumentsRequest): Promise<arangodb_cloud_internal_resourcemanager_v1_UploadedDocumentList> {
    const path = `/internal-api/dashboard/v1/uploadeddocuments`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Upload a document
  // Required permissions:
  // - internal-dashboard.uploaddocument.create globally.
  async UploadDocument(req: arangodb_cloud_internal_resourcemanager_v1_UploadedDocument): Promise<arangodb_cloud_internal_resourcemanager_v1_UploadedDocument> {
    const url = `/internal-api/dashboard/v1/uploadeddocuments`;
    return api.post(url, req);
  }
  
  // Fetch all support plan that match the given request.
  // Required permissions:
  // - internal-dashboard.supportplan.list globally.
  async ListSupportPlans(req: arangodb_cloud_internal_support_v1_ListAllPlansRequest): Promise<arangodb_cloud_support_v1_PlanList> {
    const path = `/internal-api/dashboard/v1/supportplans`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Submit an support request for the customer.
  // Required permissions:
  // - internal-support.supportrequests.create-request-for-customer
  async CreateSupportRequestForCustomer(req: arangodb_cloud_internal_support_v1_SupportRequestCustomer): Promise<arangodb_cloud_internal_support_v1_SupportRequestCustomer> {
    const url = `/internal-api/dashboard/v1/supportrequests`;
    return api.post(url, req);
  }
  
  // Fetch all backup policies for a specific deployment.
  // Required permissions:
  // - internal-dashboard.backuppolicy.list globally.
  async ListBackupPolicies(req: arangodb_cloud_backup_v1_ListBackupPoliciesRequest): Promise<arangodb_cloud_internal_backup_v1_BackupPolicyInfoList> {
    const path = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/backuppolicies`;
    const url = path + api.queryString(req, [`deployment_id`]);
    return api.get(url, undefined);
  }
  
  // Update the allow inconsistent flag of a backup policy
  // Required permissions:
  // - internal-dashboard.backuppolicy.update-allow-inconsistent globally
  async UpdateBackupPolicyAllowInconsistent(req: arangodb_cloud_internal_backup_v1_UpdateBackupPolicyAllowInconsistentRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/backuppolicy/${encodeURIComponent(req.id || '')}/allow-inconsistent`;
    return api.patch(url, req);
  }
  
  // Update the upload incremental flag of a backup policy
  // Required permissions:
  // - internal-dashboard.backuppolicyinfo.update-upload-incremental on the backup policy
  async UpdateBackupPolicyUploadIncremental(req: arangodb_cloud_internal_backup_v1_UpdateBackupPolicyUploadIncrementalRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/backuppolicy/${encodeURIComponent(req.id || '')}/upload-incremental`;
    return api.patch(url, req);
  }
  
  // Fetch all backups for a specific deployment.
  // Required permissions:
  // - internal-dashboard.backup.list globally.
  async ListBackups(req: arangodb_cloud_internal_backup_v1_ListBackupInfosRequest): Promise<arangodb_cloud_internal_backup_v1_BackupInfoList> {
    const path = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/backups`;
    const url = path + api.queryString(req, [`deployment_id`]);
    return api.get(url, undefined);
  }
  
  // Create a new manual backup
  // Setting the backup_policy_id field in the backup is not allowed
  // Required permissions:
  // -  internal-dashboard.backup.create on the deployment that owns the backup and is identified by the given ID in backupInfo.backup.deployment_id.
  async CreateBackup(req: CreateBackupRequest): Promise<arangodb_cloud_internal_backup_v1_BackupInfo> {
    const url = `/internal-api/dashboard/v1/backups`;
    return api.post(url, req);
  }
  
  // Update a backup
  // Required permissions:
  // -  internal-dashboard.backup.update on the backup identified by the given ID.
  async UpdateBackup(req: arangodb_cloud_backup_v1_Backup): Promise<void> {
    const url = `/internal-api/dashboard/v1/backup/${encodeURIComponent(req.id || '')}`;
    return api.patch(url, req);
  }
  
  // Download a backup identified by the given ID from remote storage to the volumes of the servers of the deployment.
  // This operation can only be executed on backups which have the same number of DB Servers in the backup and the current running cluster.
  // If this backup was already downloaded, another download will be done.
  // If the backup is still available on the cluster there is no need to explicitly download the backup before restoring.
  // This function will return immediately.
  // To track status, please invoke GetBackup and check the .status field inside the returned backup object
  // Required permissions:
  // -  internal-dashboard.backup.download on the backup identified by the given ID.
  async DownloadBackup(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/internal-api/dashboard/v1/backup/${encodeURIComponent(req.id || '')}/download`;
    const url = path + api.queryString(req, [`id`]);
    return api.post(url, undefined);
  }
  
  // Restore (or recover) a backup identified by the given ID
  // This operation can only be executed on backups where status.available is set and
  // the mayor and minor version of the backup and the current running cluster are the same.
  // This function will return immediately.
  // To track status, please invoke GetDeployment on the data API and check the
  // .status.restoring_backup and .status.restore_backup_status fields inside the returned deployment object
  // Required permissions:
  // -  internal-dashboard.backup.restore on the backup identified by the given ID.
  async RestoreBackup(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/internal-api/dashboard/v1/backup/${encodeURIComponent(req.id || '')}/restore`;
    const url = path + api.queryString(req, [`id`]);
    return api.post(url, undefined);
  }
  
  // Delete a backup identified by the given ID, after which removal of any remote storage of the backup is started.
  // Note that the backup are initially only marked for deletion.
  // Once all remote storage for the backup has been removed, the backup itself is removed.
  // Required permissions:
  // -  internal-dashboard.backup.delete on the backup identified by the given ID.
  async DeleteBackup(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/internal-api/dashboard/v1/backup/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.delete(url, undefined);
  }
  
  // Takes a backup and creates a deployment from it. For all intents and purposes this new deployment
  // will be the same as the deployment at that exact moment when the backup was taken from it. This means that
  // the new deployment will be in the same project and use the same provider as the old deployment did. Optionally
  // a different region can be provided using the region id field on the request. Furthermore, the new deployment
  // will have the same server settings ( count, mode, replication factor ) as the old deployment did at the time
  // of taking the backup. After the new deployment successfully started, the backup will be used to restore the
  // data into the new deployment. The new deployment will have a different endpoint, and the password will also
  // be reset for it. All other user settings will remain the same.
  // The old deployment will not be touched.
  // Required permissions:
  // - internal-dashboard.deployment.clone-from-backup globally
  async CloneDeploymentFromBackup(req: CloneDeploymentFromBackupRequest): Promise<arangodb_cloud_data_v1_Deployment> {
    const url = `/internal-api/dashboard/v1/backup/${encodeURIComponent(req.backup_id || '')}/clone`;
    return api.post(url, req);
  }
  
  // Resumes a paused deployment identified by the given id.
  // When ResumeDeployment is invoked on a deployment that has is_paused not set, an PreconditionFailed error is returned.
  // Required permissions:
  // - internal-dashboard.deployment.resume globally
  async ResumeDeployment(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.id || '')}/resume`;
    const url = path + api.queryString(req, [`id`]);
    return api.post(url, undefined);
  }
  
  // Fetch IAM provider limits for the organization identified by given ID.
  // If no limits are found for the identified organization, a default (empty) set of
  // limits is returned.
  // Required permissions:
  // - internal-dashboard.organization.get-iamprovider-limits globally.
  async GetOrganizationIAMProviderLimits(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_security_v1_OrganizationIAMProviderLimits> {
    const path = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.id || '')}/iamprovider-limits`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Set IAM provider limits for the organization identified by given ID.
  // Required permissions:
  // - internal-dashboard.organization.set-iamprovider-limits globally.
  async SetOrganizationIAMProviderLimits(req: arangodb_cloud_internal_security_v1_OrganizationIAMProviderLimits): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/iamprovider-limits`;
    return api.put(url, req);
  }
  
  // Update the replication settings of a deployment.
  // Required permissions:
  // - internal-dashboard.deployment.update-replication on the deployment
  async UpdateDeploymentReplication(req: arangodb_cloud_internal_data_v1_UpdateDeploymentReplicationRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/replication`;
    return api.put(url, req);
  }
  
  // Update the support plan for a deployment identified with given ID.
  // Required permissions:
  // - internal-dashboard.deployment.update-support-plan on the deployment
  async UpdateDeploymentSupportPlan(req: arangodb_cloud_internal_data_v1_UpdateDeploymentSupportPlanRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/support-plan`;
    return api.put(url, req);
  }
  
  // Update the CPU ratio between coordinator and DB server.
  // Required permissions:
  // - internal-dashboard.deployment.update-cpu-ratio on the deployment
  async UpdateDeploymentCPURatio(req: arangodb_cloud_internal_data_v1_UpdateDeploymentCPURatioRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/cpu-ratio`;
    return api.put(url, req);
  }
  
  // Update the memory ratio between coordinator and DB server.
  // Required permissions:
  // - internal-dashboard.deployment.update-memory-ratio on the deployment
  async UpdateDeploymentMemoryRatio(req: arangodb_cloud_internal_data_v1_UpdateDeploymentMemoryRatioRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/memory-ratio`;
    return api.put(url, req);
  }
  
  // Update the CPU factor (0.5-1.0) between deployment and k8s resources.
  // This is a factor between the CPU defined in the deployment (ultimatily coming from node-size) and the limit and request as provided to k8s resources.
  // Required permissions:
  // - internal-dashboard.deployment.update-cpu-factors on the deployment
  async UpdateDeploymentCPUFactors(req: arangodb_cloud_internal_data_v1_UpdateDeploymentCPUFactorsRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/cpu-factors`;
    return api.put(url, req);
  }
  
  // Update the memory factor (0.5-1.0) between deployment and k8s resources.
  // This is a factor between the memory defined in the deployment (coming from node-size) and the limit and request as provided to k8s resources.
  // Required permissions:
  // - internal-dashboard.deployment.update-memory-factors on the deployment
  async UpdateDeploymentMemoryFactors(req: arangodb_cloud_internal_data_v1_UpdateDeploymentMemoryFactorsRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/memory-factors`;
    return api.put(url, req);
  }
  
  // StartDCUpdate will start execute updates (if needed).
  // Required permissions:
  // - internal-dashboard.dcupdate.start globally
  async StartDCUpdate(req: arangodb_cloud_internal_dcupdate_v1_StartRequest): Promise<arangodb_cloud_internal_dcupdate_v1_UpdateStatus> {
    const path = `/internal-api/dashboard/v1/dcupdate/start`;
    const url = path + api.queryString(req, []);
    return api.post(url, undefined);
  }
  
  // GetDCUpdateStatus returns the status of the current update process.
  // Required permissions:
  // - internal-dashboard.dcupdate.get-status globally
  async GetDCUpdateStatus(req?: arangodb_cloud_common_v1_Empty): Promise<arangodb_cloud_internal_dcupdate_v1_UpdateStatus> {
    const path = `/internal-api/dashboard/v1/dcupdate/get-status`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // StopDCUpdate will stop the currently running update process (if any).
  // Required permissions:
  // - internal-dashboard.dcupdate.stop globally
  async StopDCUpdate(req: arangodb_cloud_internal_dcupdate_v1_StopRequest): Promise<void> {
    const path = `/internal-api/dashboard/v1/dcupdate/stop`;
    const url = path + api.queryString(req, []);
    return api.put(url, undefined);
  }
  
  // Set the custom image filter for the organization identified by given ID.
  // Required permissions:
  // - internal-dashboard.organization.set-custom-image-filter globally.
  async SetOrganizationCustomImageFilter(req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationCustomImageFilterRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/set-custom-image-filter`;
    return api.put(url, req);
  }
  
  // Fetch all organization infos that match all of the given filters.
  // Required permissions:
  // - internal-dashboard.organization.list on the support organization.
  async ListAllOrganizationInfos(req: arangodb_cloud_internal_resourcemanager_v1_ListAllOrganizationsRequest): Promise<arangodb_cloud_internal_resourcemanager_v1_OrganizationInfoList> {
    const url = `/internal-api/dashboard/v1/organization-infos`;
    return api.put(url, req);
  }
  
  // Fetch an organization info by id.
  // Required permissions:
  // - internal-dashboard.organization.get on the support organization.
  async GetOrganizationInfo(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_resourcemanager_v1_OrganizationInfo> {
    const path = `/internal-api/dashboard/v1/organization-info/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Change the log level of the deployment identified by given ID.
  // Required permissions:
  // - internal-dashboard.deployment.set-log-level on the deployment identified by the given deployment ID.
  async SetDeploymentLogLevel(req: arangodb_cloud_internal_monitoring_v1_SetDeploymentLogLevelRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/log-level`;
    return api.put(url, req);
  }
  
  // Start a debug container in the namespace of the deployment identified by given ID.
  // Required permissions:
  // - internal-dashboard.deployment.debug on the deployment identified by the given deployment ID.
  async DebugCluster(req: arangodb_cloud_internal_monitoring_v1_DebugClusterRequest): Promise<arangodb_cloud_internal_monitoring_v1_DebugClusterInfo> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/debug`;
    return api.put(url, req);
  }
  
  // Rotate a server for a deployment. Takes a deployment id and a server id and tells the operator
  // to cycle out the pod which belongs to that server.
  // Required permissions:
  // - internal-dashboard.deployment.rotate-server on the deployment identified by the given deployment ID.
  async RotateDeploymentServer(req: arangodb_cloud_data_v1_RotateDeploymentServerRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/rotate-server`;
    return api.put(url, req);
  }
  
  // Rotate a server for a deployment with force. Takes a deployment id and a server id and tells the operator
  // to cycle out the pod which belongs to that server.
  // Required permissions:
  // - internal-dashboard.deployment.rotate-server-with-force on the deployment identified by the given deployment ID.
  async RotateDeploymentServerWithForce(req: arangodb_cloud_data_v1_RotateDeploymentServerRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/rotate-server-with-force`;
    return api.put(url, req);
  }
  
  // RebalanceDeploymentShards rebalances shards for deployment across the DB servers.
  // Required permissions:
  // - internal-dashboard.deployment.rebalance-shards on the deployment identified by the given request.
  async RebalanceDeploymentShards(req: arangodb_cloud_data_v1_RebalanceDeploymentShardsRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/rebalance-shards`;
    return api.put(url, req);
  }
  
  // Get the revenue that should be accounted for in a given period.
  // Required permissions:
  // - internal-dashboard.revenue.get globally
  async GetRevenue(req: arangodb_cloud_internal_reporting_v1_GetRevenueRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_reporting_v1_Revenue>) => void): Promise<IServerStream> {
    const url = `/internal-api/dashboard/v1/streaming/revenue`;
    return api.server_stream(url, "POST", req, cb);
  }
  
  // Get the recurring revenue for in a given period.
  // Recurring revenue is counting fixed price elements only.
  // Required permissions:
  // - internal-dashboard.revenue.get globally
  async GetRecurringRevenue(req: arangodb_cloud_internal_reporting_v1_GetRevenueRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_reporting_v1_Revenue>) => void): Promise<IServerStream> {
    const url = `/internal-api/dashboard/v1/streaming/recurring-revenue`;
    return api.server_stream(url, "POST", req, cb);
  }
  
  // Get the recurring revenue by organization in a given period.
  // Recurring revenue is counting fixed price elements only.
  // One revenue item per organizations is returned.
  // Required permissions:
  // - internal-dashboard.revenue.get globally
  async GetRecurringRevenueByOrganization(req: arangodb_cloud_internal_reporting_v1_GetRecurringRevenueByOrganizationRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_reporting_v1_RevenueByOrganization>) => void): Promise<IServerStream> {
    const url = `/internal-api/dashboard/v1/streaming/recurring-revenue-by-organization`;
    return api.server_stream(url, "POST", req, cb);
  }
  
  // Get the costs of deployments running in a given period.
  // Note that only 1 cost item is returned, but this can take a while.
  // Required permissions:
  // - internal-dashboard.deployments-cost.get globally
  async GetDeploymentsCost(req: arangodb_cloud_internal_reporting_v1_GetDeploymentsCostRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_reporting_v1_DeploymentsCost>) => void): Promise<IServerStream> {
    const url = `/internal-api/dashboard/v1/streaming/deployments-cost`;
    return api.server_stream(url, "POST", req, cb);
  }
  
  // Get the number of changes in deployments in a given period.
  // Note that only 1 item is returned, but this can take a while.
  // Required permissions:
  // - internal-dashboard.deployment-changes.get globally
  async GetDeploymentChanges(req: arangodb_cloud_internal_reporting_v1_GetDeploymentChangesRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_reporting_v1_DeploymentChanges>) => void): Promise<IServerStream> {
    const url = `/internal-api/dashboard/v1/streaming/deployment-changes`;
    return api.server_stream(url, "POST", req, cb);
  }
  
  // Get the number of changes in customers in a given period.
  // Note that only 1 item is returned, but this can take a while.
  // Required permissions:
  // - internal-dashboard.customer-changes.get globally
  async GetCustomerChanges(req: arangodb_cloud_internal_reporting_v1_GetCustomerChangesRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_reporting_v1_CustomerChanges>) => void): Promise<IServerStream> {
    const url = `/internal-api/dashboard/v1/streaming/customer-changes`;
    return api.server_stream(url, "POST", req, cb);
  }
  
  // GetAPICompatibility returns the API compatibility of the current controlplane.
  // Required permissions:
  // - internal-dashboard.apicompat.get globally
  async GetAPICompatibility(req: arangodb_cloud_internal_apicompat_v1_CompatibilityRequest): Promise<arangodb_cloud_internal_apicompat_v1_APICompatibility> {
    const url = `/internal-api/dashboard/v1/api-compatibility`;
    return api.post(url, req);
  }
  
  // Fetch the cluster-health of the deployment identified by given ID.
  // Required permissions:
  // - internal-dashboard.deployment.get-cluster-health on the deployment identified by the given deployment ID.
  async GetClusterHealth(req: arangodb_cloud_internal_monitoring_v1_GetClusterHealthRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_monitoring_v1_Chunk>) => void): Promise<IServerStream> {
    const url = `/internal-api/dashboard/v1/streaming/cluster-health`;
    return api.server_stream(url, "POST", req, cb);
  }
  
  // Fetch the agency state of the deployment identified by given ID.
  // Required permissions:
  // - internal-dashboard.deployment.get-agency-state on the deployment identified by the given deployment ID.
  async GetAgencyState(req: arangodb_cloud_internal_monitoring_v1_GetAgencyStateRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_monitoring_v1_Chunk>) => void): Promise<IServerStream> {
    const url = `/internal-api/dashboard/v1/streaming/agency-state`;
    return api.server_stream(url, "POST", req, cb);
  }
  
  // Fetch the agency state of the deployment identified by given ID.
  // Required permissions:
  // - internal-dashboard.deployment.analyse on the deployment identified by the given deployment ID.
  async AnalyseCluster(req: arangodb_cloud_internal_monitoring_v1_AnalyseClusterRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_monitoring_v1_Chunk>) => void): Promise<IServerStream> {
    const url = `/internal-api/dashboard/v1/streaming/analyse-cluster`;
    return api.server_stream(url, "POST", req, cb);
  }
  
  // Fetch the agency dump of the deployment identified by given ID.
  // Required permissions:
  // - internal-dashboard.deployment.get-agency-dump on the deployment identified by the given deployment ID.
  async GetAgencyDump(req: arangodb_cloud_internal_monitoring_v1_GetAgencyDumpRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_monitoring_v1_Chunk>) => void): Promise<IServerStream> {
    const url = `/internal-api/dashboard/v1/streaming/agency-dump`;
    return api.server_stream(url, "POST", req, cb);
  }
  
  // Detect silent out-of-sync bug in the deployment identified by given ID.
  // Required permissions:
  // - internal-dashboard.deployment.analyse globally.
  async DetectSilentOutOfSync(req: arangodb_cloud_internal_monitoring_v1_DetectSilentOutOfSyncRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_monitoring_v1_Chunk>) => void): Promise<IServerStream> {
    const url = `/internal-api/dashboard/v1/streaming/silent-out-of-sync`;
    return api.server_stream(url, "POST", req, cb);
  }
  
  // GetAllServiceDescriptors returns the all service descriptors retrieved by this service to inspect.
  // Required permissions:
  // -  internal-dashboard.apicompat.get-service-descriptors globally
  async GetAllServiceDescriptors(req: arangodb_cloud_internal_apicompat_v1_AllServiceDescriptorsRequest): Promise<arangodb_cloud_internal_apicompat_v1_AllServiceDescriptors> {
    const url = `/internal-api/dashboard/v1/all-service-descriptors`;
    return api.post(url, req);
  }
  
  // Send verification email for user identified by given ID.
  // Required permissions:
  // - internal-dashboard.user.send-verification-email globally.
  async SendVerificationEmail(req: arangodb_cloud_internal_iam_v1_SendVerificationEmailRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/users/${encodeURIComponent(req.user_id || '')}/send-verification-email`;
    return api.post(url, req);
  }
  
  // Fetch all deployment jobs that match the given filter.
  // Required permissions:
  // - internal-dashboard.deploymentjob.list on the deployment that owns the deployment job and is identified by the given (non-empty) ID.
  // - internal-dashboard.deploymentjob.list globally in case no deployment identifier is given.
  async ListDeploymentJobs(req: arangodb_cloud_internal_job_v1_ListDeploymentJobsRequest): Promise<arangodb_cloud_internal_job_v1_DeploymentJobList> {
    const url = `/internal-api/dashboard/v1/jobs`;
    return api.post(url, req);
  }
  
  // Set the flag that determines if members of the organization are allowed
  // to request Kubernetes access to their deployments.
  // Required permissions:
  // - internal-dashboard.organization.set-kubernetes-access globally.
  async SetOrganizationKubernetesAccess(req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationKubernetesAccessRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/set-kubernetes-access`;
    return api.put(url, req);
  }
  
  // Set the datacluster label filter for the organization identified by given ID.
  // Required permissions:
  // - internal-dashboard.organization.set-datacluster-label-filter globally.
  async SetOrganizationDataclusterLabelFilter(req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationDataclusterLabelFilterRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/set-datacluster-label-filter`;
    return api.put(url, req);
  }
  
  // Set the flag that determines if the organization has signed a
  // Business Associate Agreement with ArangoDB.
  // Required permissions:
  // - internal-dashboard.organization.set-business-associate-agreement-signed globally.
  async SetOrganizationBusinessAssociateAgreementSigned(req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationBusinessAssociateAgreementSignedRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/set-business-associate-agreement-signed`;
    return api.put(url, req);
  }
  
  // ReDeleteDeployment will trigger a delete operation on an already deleting deployment.
  // Required permissions:
  // - internal-dashboard.deployment.redelete on the deployment identified by the given deployment ID.
  async ReDeleteDeployment(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.id || '')}/redelete`;
    return api.put(url, req);
  }
  
  // RequestKubernetesAccess is used to request credentials and configuration
  // for accessing Kubernetes resources inside ArangoGraph Insights Platform.
  // Required permissions:
  // - internal-dashboard.datacluster.connect If the request if for datacluster wide access.
  // - internal-dashboard.deployment.connect If the request if for access to a specific deployment only.
  async RequestKubernetesAccess(req: arangodb_cloud_internal_kubeaccess_v1_KubernetesAccessRequest): Promise<arangodb_cloud_internal_kubeaccess_v1_KubernetesAccessResponse> {
    const url = `/internal-api/dashboard/v1/request-kubernetes-access`;
    return api.post(url, req);
  }
  
  // Get usage statistics for example installations in a given period.
  // Note that only 1 item is returned, but this can take a while.
  // Required permissions:
  // - internal-dashboard.example-installations.get globally
  async GetExampleInstallations(req: arangodb_cloud_internal_reporting_v1_GetExampleInstallationsRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_reporting_v1_ExampleInstallations>) => void): Promise<IServerStream> {
    const url = `/internal-api/dashboard/v1/streaming/example-installations`;
    return api.server_stream(url, "POST", req, cb);
  }
  
  // Fetch all example datasets.
  // Required permissions:
  // - internal-dashboard.exampledataset.list-all globally
  async ListAllExampleDatasets(req: arangodb_cloud_example_v1_ListExampleDatasetsRequest): Promise<arangodb_cloud_example_v1_ExampleDatasetList> {
    const url = `/internal-api/dashboard/v1/all-example-datasets`;
    return api.post(url, req);
  }
  
  // Fetch all installations for a specific deployment.
  // Required permissions:
  // - internal-dashboard.exampledatasetinstallation.list on the deployment that owns the installation and is identified by the given ID.
  async ListExampleDatasetInstallations(req: arangodb_cloud_example_v1_ListExampleDatasetInstallationsRequest): Promise<arangodb_cloud_example_v1_ExampleDatasetInstallationList> {
    const url = `/internal-api/dashboard/v1/all-example-dataset-installations`;
    return api.post(url, req);
  }
  
  // Fetch all audit logs in the organization identified by the given ID.
  // Required permissions:
  // - internal-dashboard.auditlog.list on the organization identified by the given ID.
  async ListAuditLogs(req: arangodb_cloud_audit_v1_ListAuditLogsRequest): Promise<arangodb_cloud_audit_v1_AuditLogList> {
    const url = `/internal-api/dashboard/v1/auditlogs`;
    return api.post(url, req);
  }
  
  // Fetch a specific AuditLog identified by the given ID.
  // Required permissions:
  // - internal-dashboard.auditlog.get on the audit log identified by the given ID.
  async GetAuditLog(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_audit_v1_AuditLog> {
    const path = `/internal-api/dashboard/v1/auditlogs/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Delete an audit log.
  // Note that audit logs are initially only marked for deleted.
  // Once all their resources are removed the audit log itself is deleted
  // and cannot be restored.
  // Note that deleting an AuditLog will detach it from all Projects that
  // it was attached to.
  // Required permissions:
  // - internal-dashboard.auditlog.delete on the audit log.
  async DeleteAuditLog(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/internal-api/dashboard/v1/auditlogs/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.delete(url, undefined);
  }
  
  // Fetch all audit log archive infos in the audit log identified by the given ID.
  // Required permissions:
  // - internal-dashboard.auditlogarchiveinfo.list on the audit log identified by the given ID.
  async ListAuditLogArchiveInfos(req: arangodb_cloud_internal_audit_v1_ListAuditLogArchiveInfosRequest): Promise<arangodb_cloud_internal_audit_v1_AuditLogArchiveInfoList> {
    const url = `/internal-api/dashboard/v1/auditlog-infos`;
    return api.post(url, req);
  }
  
  // Fetch a specific audit log archive info identified by the given ID.
  // Required permissions:
  // - internal-dashboard.auditlogarchiveinfo.get on the audit log archive identified by the given ID.
  async GetAuditLogArchiveInfo(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_audit_v1_AuditLogArchiveInfo> {
    const path = `/internal-api/dashboard/v1/auditlogarchiveinfo/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Delete an audit log archive.
  // Note that this method will return a precondition-failed error
  // if there is a non-deleted deployment using this archive.
  // Note that audit log archives are initially only marked for deleted.
  // Once all their resources are removed the audit log archive itself is deleted
  // and cannot be restored.
  // Required permissions:
  // - internal-dashboard.auditlogarchive.delete on the audit log archive.
  async DeleteAuditLogArchive(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/internal-api/dashboard/v1/auditlogarchives/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.delete(url, undefined);
  }
  
  // Fetch all audit log archive chunks in the audit log archive identified by the given ID.
  // Required permissions:
  // - internal-dashboard.auditlogarchivechunk.list on the audit log archive identified by the given ID.
  async ListAuditLogArchiveChunks(req: arangodb_cloud_internal_audit_v1_ListAuditLogArchiveChunksRequest): Promise<arangodb_cloud_internal_audit_v1_AuditLogArchiveChunkList> {
    const url = `/internal-api/dashboard/v1/auditlog-archive-chunks`;
    return api.post(url, req);
  }
  
  // Fetch a specific AuditLog identified by the given ID.
  // Required permissions:
  // - internal-dashboard.auditlogarchivechunk.get on the audit log archive chunk identified by the given ID.
  async GetAuditLogArchiveChunk(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_audit_v1_AuditLogArchiveChunk> {
    const path = `/internal-api/dashboard/v1/auditlogs/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Fetch a specific AuditLogArchive identified by the given ID.
  // Required permissions:
  // - internal-dashboard.auditlogarchive.get on the audit log archive identified by the given ID.
  async GetAuditLogArchive(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_audit_v1_AuditLogArchive> {
    const path = `/internal-api/dashboard/v1/auditlogarchive/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Fetch all audit events that match the given filter.
  // Note that this method will return a precondition-failed error
  // if there is no destination of type "cloud" in the AuditLog.
  // Required permissions:
  // - internal-dashboard.auditlogevents.get on the audit log identified by the given ID.
  async GetAuditLogEvents(req: arangodb_cloud_audit_v1_GetAuditLogEventsRequest, cb: (obj: IStreamMessage<arangodb_cloud_audit_v1_AuditLogEventList>) => void): Promise<IServerStream> {
    const url = `/internal-api/dashboard/v1/streaming/auditlog-events`;
    return api.server_stream(url, "POST", req, cb);
  }
  
  // Fetch the AuditLog that is attached to the project identified by the given ID.
  // If no AuditLog is attached to the project, a not-found error is returned.
  // Required permissions:
  // - internal-dashboard.auditlogattachment.get on the project identified by the given ID.
  async GetAuditLogAttachedToProject(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_audit_v1_AuditLog> {
    const path = `/internal-api/dashboard/v1/projects/${encodeURIComponent(req.id || '')}/auditlog`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Fetch the feature flags for the given context.
  // Required permissions:
  // - internal-dashboard.featureflag.get globally
  async GetFeatureFlags(req: arangodb_cloud_internal_feature_v1_GetFeatureFlagsRequest): Promise<arangodb_cloud_internal_feature_v1_FeatureFlags> {
    const url = `/internal-api/dashboard/v1/featureflags/get`;
    return api.post(url, req);
  }
  
  // Update the feature flags for the given context.
  // Required permissions:
  // - internal-dashboard.featureflag.update globally
  async UpdateFeatureFlags(req: arangodb_cloud_internal_feature_v1_UpdateFeatureFlagsRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/featureflags/update`;
    return api.put(url, req);
  }
  
  // Update the factor that is used to calculate the memory & CPU resources
  // for the agency of a deployment.
  // - internal-dashboard.deployment.update-agency-resources-factor globally
  async UpdateDeploymentAgencyResourcesFactor(req: arangodb_cloud_internal_data_v1_UpdateDeploymentAgencyResourcesFactorRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/update-agency-resources-factor`;
    return api.put(url, req);
  }
  
  // Update the communication method used within the deployment.
  // - internal-dashboard.deployment.update-communication-method on the deployment
  async UpdateDeploymentCommunicationMethod(req: arangodb_cloud_internal_data_v1_UpdateDeploymentCommunicationMethodRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/update-communication-method`;
    return api.put(url, req);
  }
  
  // Fetch all load balancer types (optional filtered for the provided deployment ID).
  // Required permissions:
  // - internal-dashboard.loadbalancertype.list globally
  async ListLoadBalancerTypes(req: arangodb_cloud_internal_network_v1_ListLoadBalancerTypesRequest): Promise<arangodb_cloud_internal_network_v1_LoadBalancerTypeList> {
    const url = `/internal-api/dashboard/v1/loadbalancertypes/list`;
    return api.post(url, req);
  }
  
  // Update the loadbalancer type for the provided deployment ID.
  // This starts the process to move to the desired type without any downtime, so it can take some time before the update is ready.
  // Required permissions:
  // - internal-dashboard.loadbalancertype.update-deployment globally
  async UpdateLoadBalancerTypeForDeployment(req: arangodb_cloud_internal_network_v1_UpdateLoadBalancerTypeForDeploymentRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/loadbalancertypes/update-for-deployment`;
    return api.put(url, req);
  }
  
  // Fetch all load balancers with matching deployment or datacluster ID.
  // Required permissions:
  // - internal-dashboard.loadbalancer.list globally
  async ListLoadBalancers(req: arangodb_cloud_internal_network_v1_ListLoadBalancersRequest): Promise<arangodb_cloud_internal_network_v1_LoadBalancerList> {
    const url = `/internal-api/dashboard/v1/loadbalancers/list`;
    return api.post(url, req);
  }
  
  // Update the load balancer.
  // This will not update the status field.
  // Required permissions:
  // - internal-dashboard.loadbalancer.update globally
  async UpdateLoadBalancer(req: arangodb_cloud_internal_network_v1_LoadBalancer): Promise<void> {
    const url = `/internal-api/dashboard/v1/loadbalancers/${encodeURIComponent(req.id || '')}`;
    return api.put(url, req);
  }
  
  // Set dedicated load balancer pods memory requests and limits.
  // Required permissions:
  // - internal-dashboard.loadbalancer.update on the load balancer
  async SetLoadBalancerMemoryOverride(req: arangodb_cloud_internal_network_v1_SetLoadBalancerMemoryOverrideRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/loadbalancers/${encodeURIComponent(req.id || '')}/set-memory-override`;
    return api.put(url, req);
  }
  
  // Set shared load balancer replicas override.
  // Required permissions:
  // - internal-dashboard.loadbalancer.update on the load balancer
  async SetLoadBalancerReplicasOverride(req: arangodb_cloud_internal_network_v1_SetLoadBalancerReplicasOverrideRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/loadbalancers/${encodeURIComponent(req.id || '')}/set-replicas-override`;
    return api.put(url, req);
  }
  
  // Fetch all load balancer assignments for matching deployment and/or load balancer ID.
  // Required permissions:
  // - internal-dashboard.loadbalancerassignment.list globally
  async ListLoadBalancerAssignments(req: arangodb_cloud_internal_network_v1_ListLoadBalancerAssignmentsRequest): Promise<arangodb_cloud_internal_network_v1_LoadBalancerAssignmentList> {
    const url = `/internal-api/dashboard/v1/loadbalancerassignments/list`;
    return api.post(url, req);
  }
  
  // List all email messages that match the given request.
  // Messages are ordered by creation date (recent to old).
  // Required permissions:
  // - internal-dashboard.emailmessage.list globally.
  async ListEmailMessages(req: arangodb_cloud_internal_email_v1_ListEmailMessagesRequest): Promise<arangodb_cloud_internal_email_v1_EmailMessageList> {
    const url = `/internal-api/dashboard/v1/emailmessages/list`;
    return api.post(url, req);
  }
  
  // Set the flag that determines if the organization requires prepaid deployments to create deployments
  // Required permissions:
  // - internal-resourcemanager.organization.set-requires-prepaid-deployments globally.
  async SetOrganizationRequiresPrepaidDeployments(req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationRequiresPrepaidDeploymentsRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/set-requires-prepaid-deployments`;
    return api.put(url, req);
  }
  
  // ListAllPrepaidDeployments fetches a list of all prepaid deployments that match a given filter.
  // Required permissions:
  // - internal-dashboard.prepaiddeployment.list globally.
  async ListAllPrepaidDeployments(req: arangodb_cloud_internal_prepaid_v1_ListAllPrepaidDeploymentsRequest): Promise<arangodb_cloud_internal_prepaid_v1_PrepaidDeploymentInfoList> {
    const url = `/internal-api/dashboard/v1/prepaiddeployments/list`;
    return api.post(url, req);
  }
  
  // GetPrepaidDeploymentInfo gets PrepaidDeploymentInfo by id.
  // Required permissions:
  // - internal-dashboard.prepaiddeployment.get-info globally.
  async GetPrepaidDeploymentInfo(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_prepaid_v1_PrepaidDeploymentInfo> {
    const path = `/internal-api/dashboard/v1/prepaiddeployments/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // CreatePrepaidDeployment creates a new PrepaidDeployment for given organization.
  // Required permissions:
  // - internal-dashboard.prepaiddeployment.create globally.
  async CreatePrepaidDeployment(req: arangodb_cloud_prepaid_v1_PrepaidDeployment): Promise<arangodb_cloud_internal_prepaid_v1_PrepaidDeploymentInfo> {
    const url = `/internal-api/dashboard/v1/prepaiddeployments`;
    return api.post(url, req);
  }
  
  // UpdatePrepaidDeployment updated PrepaidDeployment definition.
  // Required permissions:
  // - internal-dashboard.prepaiddeployment.update globally.
  async UpdatePrepaidDeployment(req: arangodb_cloud_prepaid_v1_PrepaidDeployment): Promise<void> {
    const url = `/internal-api/dashboard/v1/prepaiddeployments/${encodeURIComponent(req.id || '')}`;
    return api.put(url, req);
  }
  
  // DeletePrepaidDeployment deletes a PrepaidDeployment and attached Deployment.
  // Required permissions:
  // - internal-dashboard.prepaiddeployment.delete globally.
  async DeletePrepaidDeployment(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/internal-api/dashboard/v1/prepaiddeployments/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.delete(url, undefined);
  }
  
  // AttachDeploymentToPrepaidDeployment attaches existing Deployment to PrepaidDeployment.
  // Required permissions:
  // - internal-dashboard.prepaiddeployment.attach globally.
  async AttachDeploymentToPrepaidDeployment(req: arangodb_cloud_internal_prepaid_v1_AttachDeploymentToPrepaidDeploymentRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/prepaiddeployments/${encodeURIComponent(req.prepaid_deployment_id || '')}/attach`;
    return api.put(url, req);
  }
  
  // DetachDeploymentFromPrepaidDeployment detaches Deployment from PrepaidDeployments.
  // Required permissions:
  // - internal-dashboard.prepaiddeployment.detach globally.
  async DetachDeploymentFromPrepaidDeployment(req: arangodb_cloud_internal_prepaid_v1_DetachDeploymentFromPrepaidDeploymentRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/prepaiddeployments/${encodeURIComponent(req.prepaid_deployment_id || '')}/detach`;
    return api.put(url, req);
  }
  
  // GetPrepaidDeploymentForDeployment gets PrepaidDeployment for existing Deployment.
  // Required permissions:
  // - internal-dashboard.prepaiddeployment.get-for-deployment globally.
  async GetPrepaidDeploymentForDeployment(req: arangodb_cloud_internal_prepaid_v1_GetPrepaidDeploymentForDeploymentRequest): Promise<arangodb_cloud_internal_prepaid_v1_PrepaidDeploymentInfo> {
    const path = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/prepaiddeployment`;
    const url = path + api.queryString(req, [`deployment_id`]);
    return api.get(url, undefined);
  }
  
  // Fetch all changes that match the given request.
  // Required permissions:
  // - internal-dashboard.change.list globally.
  async ListChanges(req: arangodb_cloud_internal_changelog_v1_ListChangesRequest): Promise<arangodb_cloud_internal_changelog_v1_ChangeList> {
    const url = `/internal-api/dashboard/v1/changelog/list`;
    return api.post(url, req);
  }
  
  // Get the URL of the uploaded document with the name provided as Id in the internal dashboard.
  // Required permissions:
  // - internal-dashboard.url.get
  async GetUploadedDocumentURL(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_dashboardurl_v1_URL> {
    const path = `/internal-api/dashboard/v1/uploadeddocuments/${encodeURIComponent(req.id || '')}/url`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Fetch a private endpoint service info by the deployment ID.
  // Required permissions:
  // - internal-dashboard.privateendpointservice.get-by-deployment-id globally.
  async GetPrivateEndpointServiceInfoByDeploymentID(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_network_v1_PrivateEndpointServiceInfo> {
    const path = `/internal-api/dashboard/v1/deployment/${encodeURIComponent(req.id || '')}/privateendpointservice`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Delete a private endpoint service identified by the given ID.
  // Note that the delete isn't available in the public API, because we do not want that the switch towards public endpoint can be done there.
  // Note that the private endpoint service is initially only marked for deletion.
  // Once all their resources are removed the private endpoint service itself is removed.
  // Required permissions:
  // - internal-dashboard.privateendpointservice.delete globally.
  async DeletePrivateEndpointService(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/internal-api/dashboard/v1/privateendpointservice/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.delete(url, undefined);
  }
  
  // Fetch all email addresses for the deployment identified by given ID.
  // Required permissions:
  // - internal-dashboard.deployment.get-email-addresses globally.
  async GetDeploymentEmailAddresses(req: arangodb_cloud_internal_data_v1_GetDeploymentEmailAddressesRequest): Promise<arangodb_cloud_internal_data_v1_DeploymentEmailAddresses> {
    const path = `/internal-api/dashboard/v1/deployment/${encodeURIComponent(req.deployment_id || '')}/emailadresses`;
    const url = path + api.queryString(req, [`deployment_id`]);
    return api.get(url, undefined);
  }
  
  // Update deployment's components memory reserves
  // - internal-dashboard.deployment.update-memory-reserves globally.
  async UpdateDeploymentMemoryReserves(req: arangodb_cloud_internal_data_v1_UpdateDeploymentMemoryReservesRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployment/${encodeURIComponent(req.deployment_id || '')}/memoryreserves`;
    return api.put(url, req);
  }
  
  // Update deployment topology awareness
  // - internal-dashboard.deployment.update-topology-awareness globally.
  async UpdateDeploymentTopologyAwareness(req: arangodb_cloud_internal_data_v1_UpdateDeploymentTopologyAwarenessRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployment/${encodeURIComponent(req.deployment_id || '')}/topologyawareness`;
    return api.put(url, req);
  }
  
  // Update deployment's disk size
  // Required permissions:
  // - internal-dashboard.deployment.update-disk-size on the deployment
  // - internal-dashboard.deployment.update-disk-size-ignore-maximum-size on the deployment (if ignore_maximum_size is set)
  async UpdateDeploymentDiskSize(req: arangodb_cloud_internal_data_v1_UpdateDeploymentDiskSizeRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployment/${encodeURIComponent(req.deployment_id || '')}/disk-size`;
    return api.put(url, req);
  }
  
  // Update deployment's volume infos (like type, iops and throughput)
  // - internal-dashboard.deployment.update-volume-infos on the deployment
  async UpdateDeploymentVolumeInfos(req: arangodb_cloud_internal_data_v1_UpdateDeploymentVolumeInfosRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployment/${encodeURIComponent(req.deployment_id || '')}/volume-infos`;
    return api.put(url, req);
  }
  
  // Update the maintenance mode of a deployment's operator
  // Required permissions:
  // - internal-dashboard.deployment.update-operator-maintenance-mode on the deployment
  async UpdateDeploymentOperatorMaintenanceMode(req: arangodb_cloud_internal_data_v1_UpdateDeploymentMaintenanceModeRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployment/${encodeURIComponent(req.deployment_id || '')}/operator-maintenance-mode`;
    return api.put(url, req);
  }
  
  // Update the maintenance mode of a deployment's agancy
  // Required permissions:
  // - internal-dashboard.deployment.update-agency-maintenance-mode on the deployment
  async UpdateDeploymentAgencyMaintenanceMode(req: arangodb_cloud_internal_data_v1_UpdateDeploymentMaintenanceModeRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployment/${encodeURIComponent(req.deployment_id || '')}/agency-maintenance-mode`;
    return api.put(url, req);
  }
  
  // Remove one or more finalizers from the deployment identified by the given context ID.
  // If this removes all finalizers from the deployment and the deployment is marked for deletion, it will be removed.
  // Required permissions:
  // - internal-dashboard.deployment.manage on the deployment.
  async RemoveDeploymentFinalizers(req: arangodb_cloud_internal_common_v1_FinalizerRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployment/${encodeURIComponent(req.context_id || '')}/remove-finalizers`;
    return api.put(url, req);
  }
  
  // Remove one or more finalizers from the data cluster identified by the given context ID.
  // If this removes all finalizers from the data cluster and the data cluster is marked for deletion, it will be removed.
  // Required permissions:
  // - internal-dashboard.datacluster.manage globally.
  async RemoveDataClusterFinalizers(req: arangodb_cloud_internal_common_v1_FinalizerRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/datacluster/${encodeURIComponent(req.context_id || '')}/remove-finalizers`;
    return api.put(url, req);
  }
  
  // Remove one or more finalizers from the backup identified by the given context ID.
  // If this removes all finalizers from the backup and the backup is marked for deletion, it will be removed.
  // Required permissions:
  // - internal-dashboard.backup.manage on the backup.
  async RemoveBackupFinalizers(req: arangodb_cloud_internal_common_v1_FinalizerRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/backup/${encodeURIComponent(req.context_id || '')}/remove-finalizers`;
    return api.put(url, req);
  }
  
  // Remove one or more finalizers from the backup policy identified by the given context ID.
  // If this removes all finalizers from the backup policy and the backup policy is marked for deletion, it will be removed.
  // Required permissions:
  // - internal-dashboard.backuppolicy.manage on the backup policy.
  async RemoveBackupPolicyFinalizers(req: arangodb_cloud_internal_common_v1_FinalizerRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/backuppolicy/${encodeURIComponent(req.context_id || '')}/remove-finalizers`;
    return api.put(url, req);
  }
  
  // CreateComment creates a new comment for given resource.
  // Required permissions:
  // - internal-dashboard.comment.create globally.
  async CreateComment(req: arangodb_cloud_internal_comment_v1_CreateCommentRequest): Promise<arangodb_cloud_internal_comment_v1_Comment> {
    const url = `/internal-api/dashboard/v1/comment/create`;
    return api.post(url, req);
  }
  
  // ListComments lists all comments for given resource.
  // Required permissions:
  // - internal-dashboard.comment.list globally.
  async ListComments(req: arangodb_cloud_internal_comment_v1_ListCommentsRequest): Promise<arangodb_cloud_internal_comment_v1_CommentList> {
    const url = `/internal-api/dashboard/v1/comment/list`;
    return api.post(url, req);
  }
  
  // Set custom organization invoice threshold on the organization in default currency
  // Requires permissions:
  // - internal-dashboard.organization.set-custom-invoice-threshold
  async SetOrganizationCustomInvoiceThreshold(req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationCustomInvoiceThresholdRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/set-custom-invoice-threshold`;
    return api.put(url, req);
  }
  
  // Fetch all metrics token in the deployment identified by the given deployment ID.
  // Required permissions:
  // - internal-dashboard.metricstoken.list on the deployment identified by the given deployment ID
  async ListMetricsTokens(req: arangodb_cloud_metrics_v1_ListTokensRequest): Promise<arangodb_cloud_metrics_v1_TokenList> {
    const url = `/internal-api/dashboard/v1/metricstokens`;
    return api.post(url, req);
  }
  
  // Lists disk performances that match all of the given filters.
  // Required permissions:
  // - internal-dashboard.diskperformance.list globally
  async ListAllDiskPerformances(req: arangodb_cloud_internal_data_v1_ListAllDiskPerformancesRequest): Promise<arangodb_cloud_data_v1_DiskPerformanceList> {
    const url = `/internal-api/dashboard/v1/diskperformances`;
    return api.post(url, req);
  }
  
  // Update the deployment suppress alerts settings for given deployment identifier.
  // Required permissions:
  // - internal-dashboard.deployment.update-suppress-alerts on the deployment
  async UpdateDeploymentSuppressAlerts(req: arangodb_cloud_internal_data_v1_UpdateDeploymentSuppressAlertsRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/suppress-alerts`;
    return api.put(url, req);
  }
  
  // Set the flag, that determines if organization has private data clusters for its deployment or not
  // Requires permissions:
  // - internal-dashboard.organization.set-has-private-data-clusters
  async SetOrganizationHasPrivateDataClusters(req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationHasPrivateDataClustersRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/set-has-private-data-clusters`;
    return api.put(url, req);
  }
  
  // Set technical account manager on the organization
  // Required permissions:
  // - internal-dashboard.organization.set-technical-account-manager
  async SetOrganizationTechnicalAccountManager(req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationTechnicalAccountManagerRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/set-technical-account-manager`;
    return api.put(url, req);
  }
  
  // Set automatic invoice verification on the organization
  // Required permissions:
  // - internal-dashboard.organization.set-automatic-invoice-verification
  async SetOrganizationAutomaticInvoiceVerification(req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationAutomaticInvoiceVerificationRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/set-automatic-invoice-verification`;
    return api.put(url, req);
  }
  
  // Set internal contacts on the organization
  // Required permissions:
  // - internal-dashboard.organization.set-internal-contacts on the provided organization ID.
  async SetOrganizationInternalContacts(req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationInternalContactsRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/set-internal-contacts`;
    return api.put(url, req);
  }
  
  // Fetch all InvoiceInfo resources that match the given criteria.
  // Required permissions:
  // - internal-dashboard.invoice.list globally
  async ListAllInvoiceInfos(req: arangodb_cloud_internal_billing_v1_ListAllInvoicesRequest): Promise<arangodb_cloud_internal_billing_v1_InvoiceInfoList> {
    const path = `/internal-api/dashboard/v1/invoice-infos`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Sets non_collectible flag on given InvoiceInfo
  // Required permissions:
  // - internal-dashboard.invoice.set-non-collectible globally.
  async SetInvoiceNonCollectible(req: arangodb_cloud_internal_billing_v1_SetInvoiceNonCollectibleRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/invoices/${encodeURIComponent(req.invoice_id || '')}/set-non-collectible`;
    return api.put(url, req);
  }
  
  // Get InvoiceInfo for Invoice identified by id
  // Required permissions:
  // - internal-dashboard.invoice.get globally
  async GetInvoiceInfo(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_billing_v1_InvoiceInfo> {
    const path = `/internal-api/dashboard/v1/invoice-infos/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Update the deployment shard rebalancer settings for given deployment identifier.
  // RequiredPermissions:
  // - internal-dashboard.deployment.update-use-shard-rebalancer on the deployment
  async UpdateDeploymentUseShardRebalancer(req: arangodb_cloud_internal_data_v1_UpdateDeploymentUseShardRebalancerRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/use-shard-rebalancer`;
    return api.put(url, req);
  }
  
  // Fetch the kubernetes resource of a deployment identified by given ID.
  // Required permissions:
  // - internal-dashboard.deployment.get-kubernetes-resource on the deployment identified by the given deployment ID.
  async GetKubernetesResource(req: arangodb_cloud_internal_monitoring_v1_GetKubernetesResourceRequest, cb: (obj: IStreamMessage<arangodb_cloud_internal_monitoring_v1_Chunk>) => void): Promise<IServerStream> {
    const url = `/internal-api/dashboard/v1/streaming/fetch-k8s-resource`;
    return api.server_stream(url, "POST", req, cb);
  }
  
  // Update the deployment kubernetes resources patches
  // RequiredPermissions:
  // - internal-dashboard.deployment.update-kubernetes-resource-patches on the deployment
  async UpdateKubernetesResourcesPatches(req: arangodb_cloud_internal_data_v1_UpdateKubernetesResourcesPatchesRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/update-kubernetes-resource-patches`;
    return api.put(url, req);
  }
  
  // Create a new credit.
  // Required permissions:
  // - internal-dashboard.credit.create globally
  async CreateCredit(req: arangodb_cloud_internal_billing_v1_Credit): Promise<arangodb_cloud_internal_billing_v1_Credit> {
    const url = `/internal-api/dashboard/v1/credits`;
    return api.post(url, req);
  }
  
  // Discard uncommited credit usage
  // Required permissions:
  // - internal-dashboard.credit.discard-usage globally
  async DiscardUncommitedCreditUsage(req: arangodb_cloud_internal_billing_v1_DiscardUncommitedCreditUsageRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/creditusages/${encodeURIComponent(req.credit_usage_id || '')}`;
    return api.put(url, req);
  }
  
  // Mark credit as exhausted
  // Required permissions:
  // - internal-dashboard.credit.set-exhausted globally
  async SetCreditExhausted(req: arangodb_cloud_internal_billing_v1_SetCreditExhaustedRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/credits/${encodeURIComponent(req.credit_id || '')}`;
    return api.put(url, req);
  }
  
  // List credits matching requested criteria
  // Required permissions:
  // - internal-dashboard.credit.list globally
  async ListCredit(req: arangodb_cloud_internal_billing_v1_ListCreditRequest): Promise<arangodb_cloud_internal_billing_v1_CreditList> {
    const path = `/internal-api/dashboard/v1/credits`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // List credit usages matching requested criteria
  // Required permissions:
  // - internal-dashboard.credit.list-usage globally
  async ListCreditUsage(req: arangodb_cloud_internal_billing_v1_ListCreditUsageRequest): Promise<arangodb_cloud_internal_billing_v1_CreditUsageList> {
    const path = `/internal-api/dashboard/v1/creditusages`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Mark the invoice identified by given ID as canceled.
  // Required permissions:
  // - internal-dashboard.invoice.cancel globally.
  async CancelInvoice(req: arangodb_cloud_internal_billing_v1_CancelInvoiceRequest): Promise<void> {
    const path = `/internal-api/dashboard/v1/invoices/${encodeURIComponent(req.invoice_id || '')}/cancel`;
    const url = path + api.queryString(req, [`invoice_id`]);
    return api.get(url, undefined);
  }
  
  // Trigger the (async) creation of a report.
  // The contents of the report are derived from request flags.
  // The report which will be delivered by email.
  // Required permissions:
  // - internal-dashboard.report.create globally
  async CreateReport(req: arangodb_cloud_internal_reporting_v1_CreateReportRequest): Promise<arangodb_cloud_internal_reporting_v1_CreateReportResponse> {
    const url = `/internal-api/dashboard/v1/report`;
    return api.post(url, req);
  }
  
  // Copy a backup manually from source backup to a given region identifier.
  // It is not allowed to copy backup that does not have upload flag set to true
  // Required permissions:
  // - internal-dashboard.backup.copy on the backup identified by the given ID.
  async CopyBackup(req: arangodb_cloud_backup_v1_CopyBackupRequest): Promise<arangodb_cloud_backup_v1_Backup> {
    const url = `/internal-api/dashboard/v1/backup/copy`;
    return api.post(url, req);
  }
  
  // Fetch all entities spefications known by the system.
  // Required permissions:
  // - internal-dashboard.entity.list globally
  async ListEntities(req: arangodb_cloud_common_v1_ListOptions): Promise<arangodb_cloud_internal_resourcemanager_v1_EntityDetailsList> {
    const path = `/internal-api/dashboard/v1/entities`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Delete a deployment by its id.
  // Required permissions:
  // - internal-dashboard.deployment.delete globally.
  async DeleteDeployment(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.delete(url, undefined);
  }
  
  // Get an existing DeploymentReplication using its deployment ID
  // Required permissions:
  // - internal-dashboard.deploymentreplication.get
  async GetMigrationDeploymentReplication(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_replication_v1_DeploymentReplication> {
    const path = `/internal-api/dashboard/v1/deployment/${encodeURIComponent(req.id || '')}/replication`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Update an existing DeploymentReplication spec. If does not exist, this will create a new one.
  // This call expects the complete entity with the updated fields.
  // Required permissions:
  // - internal-dashboard.deploymentreplication.update
  async UpdateMigrationDeploymentReplication(req: arangodb_cloud_replication_v1_DeploymentReplication): Promise<arangodb_cloud_replication_v1_DeploymentReplication> {
    const url = `/internal-api/dashboard/v1/deployment/${encodeURIComponent(req.deployment_id || '')}/replication`;
    return api.put(url, req);
  }
  
  // List NotebookInfos given the optional filter(s).
  // Required permissions:
  // - internal-dashboard.notebook.list globally
  async ListNotebookInfos(req: arangodb_cloud_internal_notebook_v1_ListNotebookInfosRequest): Promise<arangodb_cloud_internal_notebook_v1_NotebookInfoList> {
    const url = `/internal-api/dashboard/v1/notebooks`;
    return api.post(url, req);
  }
  
  // Set a custom image for notebook specified by the Notebook ID.
  // Required permissions:
  // - internal-dashboard.notebook.set-custom-image on the notebook
  async SetCustomNotebookImage(req: arangodb_cloud_internal_notebook_v1_SetCustomNotebookImageRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/notebooks/${encodeURIComponent(req.notebook_id || '')}/custom-image`;
    return api.put(url, req);
  }
  
  // Set a custom notebook image that should be used when creating Notebooks for this deployment.
  // Required permissions:
  // - internal-dashboard.deployment.set-custom-notebook-image
  async SetDeploymentCustomNotebookImage(req: arangodb_cloud_internal_data_v1_SetCustomNotebookImageRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/set-custom-notebook-image`;
    return api.put(url, req);
  }
  
  // Get the revenue that should be accounted for in a given period.
  // Required permissions:
  // - internal-dashboard.revenue.get globally
  async CreateInvoicesZIP(req: arangodb_cloud_internal_billing_v1_ListAllInvoicesRequest): Promise<void> {
    const path = `/internal-api/dashboard/v1/invoices-zip`;
    const url = path + api.queryString(req, []);
    return api.download(url);
  }
  
  // Set the additional command line arguments for a kube-arangodb
  // Required permissions:
  // - internal-dashboard.deployment.set-kube-arangodb-additional-command-line-arguments on the deployment
  async SetKubeArangodbAdditionalCommandLineArguments(req: arangodb_cloud_internal_data_v1_SetKubeArangodbAdditionalCommandLineArgumentsRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/kube-arangodb-additional-args`;
    return api.put(url, req);
  }
  
  // List DeploymentInfos given the optional filter(s).
  // Required permissions:
  // - internal-dashboard.deploymentprofile.list globally
  async ListDeploymentProfileInfos(req: arangodb_cloud_internal_deploymentprofile_v1_ListDeploymentProfileInfosRequest): Promise<arangodb_cloud_internal_deploymentprofile_v1_DeploymentProfileInfoList> {
    const url = `/internal-api/dashboard/v1/deploymentprofiles/list`;
    return api.post(url, req);
  }
  
  // GetDeploymentProfileInfo retrieves a deployment profile info by its ID.
  // Required permissions:
  // - internal-dashboard.deploymentprofile.get
  async GetDeploymentProfileInfo(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_deploymentprofile_v1_DeploymentProfileInfo> {
    const path = `/internal-api/dashboard/v1/deploymentprofiles/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // CreateDeploymentProfile creates a new deployment profile by specifying its configuration.
  // Required permissions:
  // - internal-dashboard.deploymentprofile.create
  async CreateDeploymentProfile(req: arangodb_cloud_internal_deploymentprofile_v1_DeploymentProfileInfo): Promise<arangodb_cloud_internal_deploymentprofile_v1_DeploymentProfileInfo> {
    const url = `/internal-api/dashboard/v1/deploymentprofiles`;
    return api.post(url, req);
  }
  
  // UpdateDeploymentProfile updates an existing deployment profile info.
  // Required permissions:
  // - internal-dashboard.deploymentprofile.update
  async UpdateDeploymentProfile(req: arangodb_cloud_internal_deploymentprofile_v1_DeploymentProfileInfo): Promise<void> {
    const url = `/internal-api/dashboard/v1/deploymentprofiles`;
    return api.patch(url, req);
  }
  
  // DeleteDeploymentProfile removes the deployment profile by specifying its ID.
  // Required permissions:
  // - internal-dashboard.deploymentprofile.delete
  async DeleteDeploymentProfile(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/internal-api/dashboard/v1/deploymentprofiles/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.delete(url, undefined);
  }
  
  // Create a new deployment update job.
  // Returns the newly created UpdateJob.
  // Required permissions:
  // - internal-dashboard.deploymentupdatejob.create
  async CreateDeploymentUpdateJob(req: arangodb_cloud_internal_deploymentupdater_v1_UpdateJob): Promise<arangodb_cloud_internal_deploymentupdater_v1_UpdateJob> {
    const url = `/internal-api/dashboard/v1/deploymentupdatejob/create`;
    return api.post(url, req);
  }
  
  // Get an existing deployment update job. Return error if not found.
  // Required permissions:
  // - internal-dashboard.deploymentupdatejob.get
  async GetDeploymentUpdateJob(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_deploymentupdater_v1_UpdateJob> {
    const path = `/internal-api/dashboard/v1/deploymentupdatejob/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Update an existing deployment update job.
  // This call expects the complete entity with the updated fields.
  // Returns the updated UpdateJob.
  // Required permissions:
  // - internal-dashboard.deploymentupdatejob.update
  async UpdateDeploymentUpdateJob(req: arangodb_cloud_internal_deploymentupdater_v1_UpdateJob): Promise<arangodb_cloud_internal_deploymentupdater_v1_UpdateJob> {
    const url = `/internal-api/dashboard/v1/deploymentupdatejob/${encodeURIComponent(req.id || '')}/update`;
    return api.put(url, req);
  }
  
  // Start an existing deployment update job.
  // Returns the updated UpdateJob.
  // Does nothing if UpdateJob already started.
  // Required permissions:
  // - internal-dashboard.deploymentupdatejob.start
  async StartDeploymentUpdateJob(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_deploymentupdater_v1_UpdateJob> {
    const path = `/internal-api/dashboard/v1/deploymentupdatejob/${encodeURIComponent(req.id || '')}/start`;
    const url = path + api.queryString(req, [`id`]);
    return api.put(url, undefined);
  }
  
  // Stop an existing deployment update job which is running.
  // Returns the updated UpdateJob.
  // Does nothing if UpdateJob already stopped.
  // Required permissions:
  // - internal-dashboard.deploymentupdatejob.stop
  async StopDeploymentUpdateJob(req: arangodb_cloud_internal_deploymentupdater_v1_UpdateJobStateChangeRequest): Promise<arangodb_cloud_internal_deploymentupdater_v1_UpdateJob> {
    const url = `/internal-api/dashboard/v1/deploymentupdatejob/${encodeURIComponent(req.id || '')}/stop`;
    return api.put(url, req);
  }
  
  // Pause an existing deployment update job. If already paused, does nothing.
  // Returns the updated UpdateJob.
  // Does nothing if UpdateJob already paused.
  // Required permissions:
  // - internal-dashboard.deploymentupdatejob.pause
  async PauseDeploymentUpdateJob(req: arangodb_cloud_internal_deploymentupdater_v1_UpdateJobStateChangeRequest): Promise<arangodb_cloud_internal_deploymentupdater_v1_UpdateJob> {
    const url = `/internal-api/dashboard/v1/deploymentupdatejob/${encodeURIComponent(req.id || '')}/pause`;
    return api.put(url, req);
  }
  
  // Resume an existing deployment update job. If already running, does nothing.
  // Returns the updated UpdateJob.
  // Does nothing if UpdateJob already running.
  // Required permissions:
  // - internal-dashboard.deploymentupdatejob.resume
  async ResumeDeploymentUpdateJob(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_deploymentupdater_v1_UpdateJob> {
    const path = `/internal-api/dashboard/v1/deploymentupdatejob/${encodeURIComponent(req.id || '')}/resume`;
    const url = path + api.queryString(req, [`id`]);
    return api.put(url, undefined);
  }
  
  // Delete an existing deployment update job. If does not exist, returns an error.
  // Required permissions:
  // - internal-dashboard.deploymentupdatejob.delete
  async DeleteDeploymentUpdateJob(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/internal-api/dashboard/v1/deploymentupdatejob/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.delete(url, undefined);
  }
  
  // List deployment update jobs.
  // Required permissions:
  // - internal-dashboard.deploymentupdatejob.list
  async ListDeploymentUpdateJobs(req: arangodb_cloud_internal_deploymentupdater_v1_ListUpdateJobsRequest): Promise<arangodb_cloud_internal_deploymentupdater_v1_UpdateJobList> {
    const url = `/internal-api/dashboard/v1/deploymentupdatejobs`;
    return api.post(url, req);
  }
  
  // List all available scheduling policies
  // Required permissions:
  // - internal-dashboard.schedulingpolicy.list
  async ListAllSchedulingPolicies(req: arangodb_cloud_internal_scheduling_v1_ListAllSchedulingPoliciesRequest): Promise<arangodb_cloud_internal_scheduling_v1_SchedulingPolicyList> {
    const url = `/internal-api/dashboard/v1/schedulingpolicies`;
    return api.post(url, req);
  }
  
  // Get a SchedulingPolicy object identified by id provided in request
  // Required permissions:
  // - internal-dashboard.schedulingpolicy.get
  async GetSchedulingPolicy(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_scheduling_v1_SchedulingPolicy> {
    const url = `/internal-api/dashboard/v1/schedulingpolicies/${encodeURIComponent(req.id || '')}`;
    return api.post(url, req);
  }
  
  // Set scheduling policies for deployment
  // Required permission:
  // - internal-dashboard.deployment.set-scheduling-policies on the deployment
  async SetDeploymentSchedulingPolicies(req: arangodb_cloud_internal_data_v1_SetDeploymentSchedulingPoliciesRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/set-scheduling-policies`;
    return api.post(url, req);
  }
  
  // List detailed information about all notebook models
  // Required permissions:
  // - internal-dashboard.notebookmodel.list
  async ListAllNotebookModelInfos(req: arangodb_cloud_internal_notebook_v1_ListAllNotebookModelsRequest): Promise<arangodb_cloud_internal_notebook_v1_NotebookModelInfoList> {
    const path = `/internal-api/dashboard/v1/notebookmodels`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Get detailed information about notebook model
  // Required permissions:
  // - internal-dashboard.notebookmodel.get
  async GetNotebookModelInfo(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_notebook_v1_NotebookModelInfo> {
    const path = `/internal-api/dashboard/v1/notebookmodels/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Set scheduling policies for given notebook identified by notebook_id
  // Required permissions:
  // - internal-dashboard.notebookmodel.set-scheduling-policies
  async SetNotebookSchedulingPolicies(req: arangodb_cloud_internal_notebook_v1_SetSchedulingPoliciesRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/notebooks/${encodeURIComponent(req.notebook_id || '')}/set-scheduling-policies`;
    return api.post(url, req);
  }
  
  // Update the private endpoint service info, including fields which cannot be modified with the public API
  // (like the AKS subscriptions ID once the servvice has made a connection)
  // This will not update the status field, for updating the status, please use UpdatePrivateEndpointServiceStatus.
  // Required permissions:
  // - internal-dashboard.privateendpointservice.update
  async UpdatePrivateEndpointServiceInfo(req: arangodb_cloud_internal_network_v1_PrivateEndpointServiceInfo): Promise<void> {
    const url = `/internal-api/dashboard/v1/privateendpointservice`;
    return api.patch(url, req);
  }
  
  // ListNodePools returns node pools associated with given data cluster
  // Required permission:
  // - internal-dashboard.nodepool.list globally
  async ListNodePools(req: arangodb_cloud_internal_data_v1_ListNodePoolsRequest): Promise<arangodb_cloud_internal_data_v1_NodePoolList> {
    const path = `/internal-api/dashboard/v1/nodepools/${encodeURIComponent(req.datacluster_id || '')}`;
    const url = path + api.queryString(req, [`datacluster_id`]);
    return api.get(url, undefined);
  }
  
  // ScaleNodePool sets requested nodes value for given node pool
  // RequiredPermissions:
  // - internal-dashboard.nodepool.scale globally
  async ScaleNodePool(req: arangodb_cloud_internal_data_v1_ScaleNodePoolRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/nodepools/${encodeURIComponent(req.datacluster_id || '')}/${encodeURIComponent(req.name || '')}`;
    return api.patch(url, req);
  }
  
  // Set whenever scheduling policies propagation from node size is enabled for given deployment
  // Required permission:
  // - internal-dashboard.deployment.set-scheduling-policies-enabled on the deployment
  async SetDeploymentSchedulingPoliciesEnabled(req: arangodb_cloud_internal_data_v1_SetDeploymentSchedulingPoliciesEnabledRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/set-scheduling-policies-enabled`;
    return api.post(url, req);
  }
  
  // Get MLServicesInfo by its deployment id.
  // Required permissions:
  // - internal-dashboard.mlservices.get on the provided deployment ID
  async GetMLServicesInfo(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_ml_v1_MLServicesInfo> {
    const path = `/internal-api/dashboard/v1/ml/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Set if ML service is enabled for given deployment.
  // Required permissions:
  // - internal-dashboard.mlservices.set-ml-service-enabled on the MLServices.
  async SetMLServiceEnabled(req: arangodb_cloud_ml_v1_MLServices): Promise<void> {
    const url = `/internal-api/dashboard/v1/ml/${encodeURIComponent(req.deployment_id || '')}/set-ml-service-enabled`;
    return api.put(url, req);
  }
  
  // Set if ML workloads can run on GPU machines.
  // Required permissions:
  // - internal-dashboard.mlservices.set-allow-gpu-workloads globally.
  async SetAllowGPUWorkloads(req: arangodb_cloud_internal_ml_v1_SetAllowGPUWorkloadsRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/ml/${encodeURIComponent(req.deployment_id || '')}/set-allow-gpu-workloads`;
    return api.put(url, req);
  }
  
  // Set the resource limits (and requests) on ML jobs.
  // Note: This will set new limits on all types of jobs (prediction, training, etc.)
  // Required permission:
  // - internal-dashboard.mlservices.set-jobs-resource-limits globally.
  async SetMLJobsResourceLimits(req: arangodb_cloud_internal_ml_v1_SetJobsResourceLimitsRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/ml/${encodeURIComponent(req.deployment_id || '')}/set-jobs-memory-limits`;
    return api.put(url, req);
  }
  
  // Set if an organization is allowed to be a delinquent payer.
  // Required permissions:
  // - internal-dashboard.organization.set-allow-delinquent-payer on the provided organization ID.
  async SetOrganizationAllowDelinquentPayer(req: arangodb_cloud_internal_resourcemanager_v1_SetAllowOrganizationDelinquentPayerRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/set-allow-delinquent-payer`;
    return api.put(url, req);
  }
  
  // Set if an organization is allowed to make custom CPU settings.
  // Required permissions:
  // - internal-dashboard.organization.set-disable-custom-cpu globally.
  async SetOrganizationDisableCustomCPU(req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationCustomSettingsRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/set-disable-custom-cpu`;
    return api.put(url, req);
  }
  
  // Set if an organization is allowed to make custom disk settings.
  // Required permissions:
  // - internal-dashboard.organization.set-disable-custom-disk globally.
  async SetOrganizationDisableCustomDisk(req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationCustomSettingsRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/set-disable-custom-disk`;
    return api.put(url, req);
  }
  
  // Set whenever public backups should be disabled/enabled.
  // Required permission:
  // - internal-dashboard.deployment.set-public-backup-disabled on the deployment
  async SetDeploymentPublicBackupsDisabled(req: arangodb_cloud_internal_data_v1_SetDeploymentPublicBackupsDisabledRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/set-public-backup-disabled`;
    return api.post(url, req);
  }
  
  // Create a new credit bundle.
  // Required permissions:
  // - internal-dashboard.creditbundle.create globally.
  async CreateCreditBundleInfo(req: arangodb_cloud_internal_credits_v1_CreditBundleInfo): Promise<arangodb_cloud_internal_credits_v1_CreditBundleInfo> {
    const url = `/internal-api/dashboard/v1/creditbundle`;
    return api.post(url, req);
  }
  
  // Update an existing credit bundle.
  // This operation is not allowed if the specified credit bundle is used.
  // Required permisisons:
  // - internal-dashboard.creditbundle.update globally.
  async UpdateCreditBundle(req: arangodb_cloud_credits_v1_CreditBundle): Promise<void> {
    const url = `/internal-api/dashboard/v1/creditbundle/${encodeURIComponent(req.id || '')}`;
    return api.put(url, req);
  }
  
  // Delete an existing credit bundle specified by the ID.
  // This operation is not allowed if the specified credit bundle is used.
  // Required permisisons:
  // - internal-dashboard.creditbundle.delete globally.
  async DeleteCreditBundle(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const path = `/internal-api/dashboard/v1/creditbundle/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.delete(url, undefined);
  }
  
  // Lock a credit bundle specified by the ID.
  // Returns an error if a bundle is already locked.
  // Required permisisons:
  // - internal-dashboard.creditbundle.set-lock globally.
  async LockCreditBundle(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const url = `/internal-api/dashboard/v1/creditbundle/${encodeURIComponent(req.id || '')}/lock`;
    return api.patch(url, req);
  }
  
  // Unlock a credit bundle specified by the ID.
  // Returns an error if a bundle is already unlocked.
  // Required permisisons:
  // - internal-dashboard.creditbundle.set-unlock globally.
  async UnlockCreditBundle(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const url = `/internal-api/dashboard/v1/creditbundle/${encodeURIComponent(req.id || '')}/unlock`;
    return api.patch(url, req);
  }
  
  // List all credit bundles based on the request.
  // Required permisions:
  // - internal-dashboard.creditbundle.list globally.
  async ListAllCreditBundleInfos(req: arangodb_cloud_internal_credits_v1_ListAllCreditBundlesRequest): Promise<arangodb_cloud_internal_credits_v1_CreditBundleInfoList> {
    const path = `/internal-api/dashboard/v1/creditbundles`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Get the amount of credit debt for an organization identified by the id.
  // Required permissions:
  // - internal-dashboard.creditdebt.get globally.
  async GetCreditDebt(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_credits_v1_CreditDebt> {
    const path = `/internal-api/dashboard/v1/creditdebt/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Set if an organization must use credit bundles.
  // Required permissions:
  // - internal-dashboard.organization.set-uses-credit-bundles globally.
  async SetOrganizationUsesCreditBundles(req: arangodb_cloud_internal_resourcemanager_v1_SetOrganizationUsesCreditBundlesRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/organizations/${encodeURIComponent(req.organization_id || '')}/set-uses-credit-bundles`;
    return api.post(url, req);
  }
  
  // Enable/disable envoy access log for deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-envoy-access-log globally.
  async UpdateDeploymentEnvoyAccessLog(req: arangodb_cloud_internal_data_v1_UpdateDeploymentEnvoyAccessLogRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/update-envoy-access-log`;
    return api.post(url, req);
  }
  
  // StartDataClusterOptimization starts optimization process of DataCluster
  // Required permissions:
  // - internal-dashboard.datacluster.start-optimization globally.
  async StartDataClusterOptimization(req: arangodb_cloud_internal_datacluster_optimize_v1_StartDataClusterOptimizationRequest): Promise<arangodb_cloud_internal_datacluster_optimize_v1_DataClusterOptimizationStatus> {
    const url = `/internal-api/dashboard/v1/dataclusters/${encodeURIComponent(req.datacluster_id || '')}/start-optimization`;
    return api.post(url, req);
  }
  
  // StopDataClusterOptimization stops optimization process of DataCluster
  // Required permissions:
  // - internal-dashboard.datacluster.stop-optimization globally.
  async StopDataClusterOptimization(req: arangodb_cloud_internal_datacluster_optimize_v1_StopDataClusterOptimizationRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/dataclusters/${encodeURIComponent(req.datacluster_id || '')}/stop-optimization`;
    return api.post(url, req);
  }
  
  // GetDataClusterOptimizationStatus gets optimization process status of DataCluster
  // Required permissions:
  // - internal-dashboard.datacluster.get-optimization-status globally.
  async GetDataClusterOptimizationStatus(req: arangodb_cloud_internal_datacluster_optimize_v1_GetDataClusterOptimizationStatusRequest): Promise<arangodb_cloud_internal_datacluster_optimize_v1_DataClusterOptimizationStatus> {
    const path = `/internal-api/dashboard/v1/dataclusters/${encodeURIComponent(req.datacluster_id || '')}/optimization-status`;
    const url = path + api.queryString(req, [`datacluster_id`]);
    return api.get(url, undefined);
  }
  
  // Fetch all core dumps identified by the given filter.
  // Required permissions:
  // - internal-dashboard.coredump.list globally.
  async ListCoreDumps(req: arangodb_cloud_internal_coredump_v1_ListCoreDumpsRequest): Promise<arangodb_cloud_internal_coredump_v1_CoreDumpList> {
    const path = `/internal-api/dashboard/v1/coredumps`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Fetch the core dump (metadata) with the given ID.
  // Required permissions:
  // - internal-dashboard.coredump.get globally.
  async GetCoreDump(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_coredump_v1_CoreDump> {
    const path = `/internal-api/dashboard/v1/coredump/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Fetch the unencrypted content of a core dump identified by given ID.
  // Required permissions:
  // - internal-dashboard.coredump.get-content globally.
  async GetCoreDumpContent(req: arangodb_cloud_common_v1_IDOptions): Promise<void> {
    const url = `/internal-api/dashboard/v1/streaming/core-dump-content`;
    return api.download(url);
  }
  
  // Update a core dump status.
  // Required permissions:
  // - internal-dashboard.coredump.update-status globally.
  async UpdateCoreDumpStatus(req: arangodb_cloud_internal_coredump_v1_CoreDump): Promise<void> {
    const url = `/internal-api/dashboard/v1/coredump/${encodeURIComponent(req.id || '')}`;
    return api.post(url, req);
  }
  
  // Set a Notebook to maintenance mode.
  // Enabling maintenance mode on a notebook will prevent its state from being reconciled.
  // Required permissions:
  // - internal-dashboard.notebook.set-maintenance-mode
  async SetNotebookMaintenanceMode(req: arangodb_cloud_internal_notebook_v1_SetNotebookMaintenanceModeRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/notebooks/${encodeURIComponent(req.notebook_id || '')}/set-maintenance-mode`;
    return api.put(url, req);
  }
  
  // Set the node update concurrency level for a data cluster
  // Required permissions:
  // - internal-dashboard.datacluster.update-node-update-concurrency-level
  async UpdateDataClusterNodeUpdateConcurrencyLevel(req: arangodb_cloud_internal_data_v1_UpdateDataClusterNodeUpdateConcurrencyLevelRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/dataclusters/${encodeURIComponent(req.datacluster_id || '')}/update-node-update-concurrency-level`;
    return api.post(url, req);
  }
  
  // Set the deployment to use jetstream for auditlogs
  // Required permissions:
  // - internal-dashboard.deployment.update-use-jetstream-for-auditlogs
  async UpdateDeploymentToUseJetStreamForAuditLogs(req: arangodb_cloud_internal_data_v1_UpdateDeploymentToUseJetStreamForAuditLogsRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/use-jetstream-for-auditlogs`;
    return api.put(url, req);
  }
  
  // Update notification for a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-notification
  async UpdateDeploymentNotification(req: arangodb_cloud_internal_data_v1_UpdateDeploymentNotificationRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/notifications`;
    return api.post(url, req);
  }
  
  // Remove notification for a deployment
  // Required permissions:
  // - internal-dashboard.deployment.update-notification
  async RemoveDeploymentNotification(req: arangodb_cloud_internal_data_v1_RemoveDeploymentNotificationRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/notifications`;
    return api.delete(url, req);
  }
  
  // Set deployment log levels
  // Required permissions:
  // - internal-dashboard.deployment.set-members-log-level
  async SetDeploymentLogLevels(req: arangodb_cloud_internal_data_v1_SetMemberLogLevelRequest): Promise<void> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/set-members-log-level`;
    return api.post(url, req);
  }
  
  // Get default log levels
  // ID arg can be omitted
  // Required permissions:
  // - internal-dashboard.deployment.get-default-log-levels
  async GetDefaultLogLevels(req: arangodb_cloud_common_v1_IDOptions): Promise<arangodb_cloud_internal_data_v1_LogLevelList> {
    const path = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.id || '')}/get-default-log-levels`;
    const url = path + api.queryString(req, [`id`]);
    return api.get(url, undefined);
  }
  
  // Get available charts
  // Required permissions:
  // - internal-dashboard.charts.list
  async ListAllCharts(req?: arangodb_cloud_common_v1_Empty): Promise<arangodb_cloud_internal_charts_v1_ChartList> {
    const path = `/internal-api/dashboard/v1/list-charts`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // Create chart installation for deployment
  // Required permissions:
  // - internal-dashboard.chartinstallations.create
  async CreateChartInstallation(req: arangodb_cloud_internal_charts_v1_ChartInstallation): Promise<arangodb_cloud_internal_charts_v1_ChartInstallation> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/create-chart-installation`;
    return api.post(url, req);
  }
  
  // Update chart installation for deployment
  // Required permissions:
  // - internal-dashboard.chartinstallations.update
  async UpdateChartInstallation(req: arangodb_cloud_internal_charts_v1_ChartInstallation): Promise<arangodb_cloud_internal_charts_v1_ChartInstallation> {
    const url = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/update-chart-installation`;
    return api.post(url, req);
  }
  
  // List chart installations for deployment
  // Required permissions:
  // - internal-dashboard.chartinstallations.get
  async ListChartInstallations(req: arangodb_cloud_internal_charts_v1_ListChartInstallationsRequest): Promise<arangodb_cloud_internal_charts_v1_ChartInstallationList> {
    const path = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/list-chart-installations`;
    const url = path + api.queryString(req, [`deployment_id`]);
    return api.get(url, undefined);
  }
  
  // Delete chart installation for deployment
  // Required permissions:
  // - internal-dashboard.chartinstallations.delete
  async DeleteChartInstallation(req: arangodb_cloud_internal_charts_v1_ChartInstallation): Promise<arangodb_cloud_common_v1_YesOrNo> {
    const path = `/internal-api/dashboard/v1/deployments/${encodeURIComponent(req.deployment_id || '')}/chart-installations/${encodeURIComponent(req.id || '')}`;
    const url = path + api.queryString(req, [`deployment_id`, `id`]);
    return api.delete(url, undefined);
  }
}
